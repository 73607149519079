import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-load-more-info',
  templateUrl: './load-more-info.component.html',
  styleUrls: ['./load-more-info.component.scss'],
})
export class LoadMoreInfoComponent {
  @Input() cardsLength: number;
  @Input() allItemsLength: number;
  @Input() isCenter = false;
}
