import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { ApiService } from '../shared/services/api.service';
import { concatMap, catchError, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class BaseResolver implements Resolve<any> {
  constructor(private readonly apiService: ApiService, private readonly trannslate: TranslateService) {}

  resolve(): Observable<any> | Promise<any> | any {
    const menuTreeIndex$ = this.apiService.getMenuTreeIndex$().pipe(
      concatMap((res) => {
        if (res) {
          const groupKeys = Object.keys(res.data);
          return forkJoin([
            of(res),
            ...groupKeys.map((groupKey) =>
              this.apiService.getMenuTreeList$(groupKey).pipe(
                catchError((err) => of(err)),
                map((response) => ({ groupKey, ...response }))
              )
            ),
          ]);
        } else {
          return null;
        }
      }),
      catchError(() => {
        return of(null);
      })
    );

    const currentLang = this.trannslate.currentLang;

    const initData$ = this.apiService.getInit$().pipe(
      map((res) => {
        return {
          footerData: res.data.languages.find((language) => language.locale === currentLang),
          languages: res.data.languages.map((lang) => ({ locale: lang.locale, label: lang.label, isPublic: lang.isPublic })),
        };
      }),
      catchError(() => {
        return of(null);
      })
    );

    return forkJoin([menuTreeIndex$, initData$]);
  }
}
