<section>
  <div class="section-head" *ngIf="componentObject?.title || componentObject?.subtitle || componentObject?.targetAudienceTags.length">
    <h2 class="head-title" id="{{ componentObject | titleToId }}" *ngIf="componentObject?.title">{{ componentObject.title }}</h2>
    <p class="links" *ngIf="componentObject?.targetAudienceTags?.length" [appBackgroundColor]="'white'">
      <ng-container *ngFor="let tag of componentObject.targetAudienceTags; let last = last">
        <a class="link">{{ tag.label }} <span *ngIf="!last"> |</span></a>
      </ng-container>
    </p>
    <p class="desc" *ngIf="componentObject?.subtitle">{{ componentObject.subtitle }}</p>
  </div>
  <div class="bigpic-carousel" [ngClass]="{ 'extra-mobile-margin': extraMobileMargin }">
    <div #bigpicSlideElement class="bigpic-slide">
      <div *ngFor="let slide of slides">
        <div class="item">
          <span
            *ngIf="slide?.image"
            [ngStyle]="{ 'background-image': 'url(' + slide.image.url + ')' } | ssrEmptyString"
            [attr.aria-label]="slide.image.image.title"
            role="img"
            class="bg"
          ></span>
          <div class="container">
            <div class="content-top">
              <h4 class="subtitle" *ngIf="slide?.subtitle">{{ slide.subtitle }}</h4>
              <p class="lead" *ngIf="slide?.lead">{{ slide.lead }}</p>
              <a
                *ngIf="slide?.subComponents[0]?.customUrl === null"
                [routerLink]="slide?.routerLink"
                [target]="slide?.subComponents[0].newTab === true ? '_blank' : '_self'"
                class="button button-secondary white"
                >{{ slide.buttonLabel }}</a
              >
              <a
                *ngIf="slide?.subComponents[0]?.customUrl !== null"
                [href]="slide?.subComponents[0].customUrl"
                [target]="slide?.subComponents[0].newTab === true ? '_blank' : '_self'"
                class="button button-secondary white"
                >{{ slide.buttonLabel }}</a
              >
            </div>

            <div class="content-bottom">
              <div class="author" *ngIf="slide?.title">{{ slide.title }} <i class="icon icon-photo"></i></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
