import { Injectable } from '@angular/core';
import { TrendencyReqService } from 'trendency/http';
import {
  Forecast,
  ForecastData,
  ForecastReduced,
  ForecastMapped,
  MappedWeatherData,
  WeatherIconData,
  ForecastBySettlement,
  WeatherBySettlement,
  MergedForecastAndWeather,
  BundledCurrentWeatherData,
  WeatherMeta,
} from '../shared.definitions';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { getHours, getTime, getDate } from 'date-fns';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { WeatherPageService } from 'src/app/weather-page/weather-page.service';
import { DateLabelService } from './date-label.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class WeatherService {
  private readonly weatherType = {
    1: { iconUrl: 'assets/images/weather/icon_sunny_64x64(1).svg', iconMsg: 'derült' },
    2: { iconUrl: 'assets/images/weather/icon_cloudy_sunny_64x64(1).svg', iconMsg: 'kissé felhős' },
    3: { iconUrl: 'assets/images/weather/icon_cloudy_sunny_64x64(1).svg', iconMsg: 'közepesen felhős' },
    4: { iconUrl: 'assets/images/weather/2_icon_cloudy_64x64.svg', iconMsg: 'erősen felhős' },
    5: { iconUrl: 'assets/images/weather/2_icon_cloudy_64x64.svg', iconMsg: 'borult' },
    6: { iconUrl: 'assets/images/weather/icon_cloudy_sunny_64x64(1).svg', iconMsg: 'fátyolfelhős' },
    7: { iconUrl: 'assets/images/weather/icon_cloudy_sunny_64x64(1).svg', iconMsg: 'köd' },
    8: { iconUrl: 'assets/images/weather/icon_cloudy_sunny_64x64(1).svg', iconMsg: 'zúzmarás köd' },
    9: { iconUrl: 'assets/images/weather/icon_sunny_64x64(1).svg', iconMsg: 'derült, párás' },
    10: { iconUrl: 'assets/images/weather/icon_cloudy_sunny_64x64(1).svg', iconMsg: 'közepesen felhős, párás' },
    11: { iconUrl: 'assets/images/weather/2_icon_cloudy_64x64.svg', iconMsg: 'borult, párás' },
    12: { iconUrl: 'assets/images/weather/icon_cloudy_sunny_64x64(1).svg', iconMsg: 'erősen fátyolfelhős' },
    101: { iconUrl: 'assets/images/weather/2_icon_rainy_64x64(1).svg', iconMsg: 'szitálás' },
    102: { iconUrl: 'assets/images/weather/2_icon_rainy_64x64(1).svg', iconMsg: 'eső' },
    202: { iconUrl: 'assets/images/weather/2_icon_thunderstorm_64x64(1).svg', iconMsg: 'kiadós eső' },
    103: { iconUrl: 'assets/images/weather/2_icon_rainy_64x64(1).svg', iconMsg: 'zápor' },
    203: { iconUrl: 'assets/images/weather/2_icon_thunderstorm_64x64(1).svg', iconMsg: 'erős zápor' },
    104: { iconUrl: 'assets/images/weather/2_icon_rainy_windy_64x64.svg', iconMsg: 'zivatar esővel' },
    304: { iconUrl: 'assets/images/weather/2_icon_rainy_windy_64x64.svg', iconMsg: 'zivatar záporral' },
    105: { iconUrl: 'assets/images/weather/2_icon_rainy_64x64(1).svg', iconMsg: 'ónos szitálás' },
    106: { iconUrl: 'assets/images/weather/2_icon_rainy_64x64(1).svg', iconMsg: 'ónos eső' },
    107: { iconUrl: 'assets/images/weather/2_icon_snowy_64x64.svg', iconMsg: 'hószállingózás' },
    108: { iconUrl: 'assets/images/weather/2_icon_snowy_64x64.svg(1)', iconMsg: 'havazás' },
    208: { iconUrl: 'assets/images/weather/2_icon_snowy_64x64.svg(1)', iconMsg: 'erős havazás' },
    109: { iconUrl: 'assets/images/weather/icon_ice.svg', iconMsg: 'hózápor' },
    209: { iconUrl: 'assets/images/weather/icon_ice.svg', iconMsg: 'erős hózápor' },
    110: { iconUrl: 'assets/images/weather/icon_rain_and_snow.svg', iconMsg: 'havas eső' },
    310: { iconUrl: 'assets/images/weather/icon_rain_and_snow.svg', iconMsg: 'havas eső záporral' },
    112: { iconUrl: 'assets/images/weather/icon_rain_and_snow.svg', iconMsg: 'hózivatar' },
    // Todo: refact new icon with id 500 cannot found
    // 500: { iconUrl: 'assets/images/weather/icon_rain_and_snow.svg', iconMsg: 'hózivatar' }
  };

  private readonly weatherIconPositions = [
    { top: 22, left: 4, settlement: 'Komárom', region: 'Budapest' },
    { top: 18, left: 41, settlement: 'Esztergom', region: 'Budapest' },
    { top: 17, left: 68, settlement: 'Szentendre', region: 'Budapest' },
    { top: 6, left: 83, settlement: 'Vác', region: 'Budapest' },
    { top: 20, left: 81, settlement: 'Gödöllő', region: 'Budapest' },
    { top: 34, left: 69.4, settlement: 'Dunaharaszti', region: 'Budapest' },
    { top: 43, left: 83, settlement: 'Vecsés', region: 'Budapest' },
    { top: 67, left: 20, settlement: 'Székesfehérvár', region: 'Budapest' },
    { top: 65, left: 45, settlement: 'Velence', region: 'Budapest' },
    { top: 70, left: 65, settlement: 'Ráckeve', region: 'Budapest' },
    { top: 31.5, left: 12, settlement: 'Szombathely', region: 'National' },
    { top: 22, left: 24, settlement: 'Győr', region: 'National' },
    { top: 46, left: 30, settlement: 'Veszprém', region: 'National' },
    { top: 70, left: 28, settlement: 'Pécs', region: 'National' },
    { top: 38, left: 39.5, settlement: 'Budapest', region: 'National' },
    { top: 17, left: 52, settlement: 'Miskolc', region: 'National' },
    { top: 10, left: 65, settlement: 'Nyíregyháza', region: 'National' },
    { top: 35, left: 63, settlement: 'Debrecen', region: 'National' },
    { top: 63, left: 52, settlement: 'Szeged', region: 'National' },
    { top: 50, left: 60, settlement: 'Békéscsaba', region: 'National' },
  ];

  settlements = this.weatherPageService.settlements;

  private readonly currentSettlementSubject$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private readonly currentSettlementWeatherSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private readonly weatherPageService: WeatherPageService,
    private readonly reqService: TrendencyReqService,
    private readonly translate: TranslateService,
    private readonly dateLabelService: DateLabelService,
    private readonly localStorageService: LocalStorageService
  ) {}

  public get lang(): string {
    return this.translate.currentLang;
  }

  public celsiusToFahrenheit(celsius: number): number {
    return Math.round(celsius * 1.8 + 32);
  }

  getDayName(day: string): string {
    return this.dateLabelService.fetchTranslatedDayNameFromMoment(day);
  }

  getMonthName(day: string): string {
    return this.dateLabelService.fetchTranslatedMonthNameFromMoment(day);
  }

  reducingForecastData(forecastData: ForecastData[]): ForecastReduced {
    return forecastData.reduce((a, b) => ((a[b.name] = b.value), a), {});
  }

  getWeatherIconPositions(settlement: string): WeatherIconData {
    const positions = this.weatherIconPositions.find((w) => w.settlement === settlement);
    return {
      top: positions ? positions.top : 0,
      left: positions ? positions.left : 0,
      region: positions ? positions.region : '',
    };
  }

  filterByHour(date: string, hour: number): boolean {
    return getHours(new Date(date)) === hour;
  }

  sortByDate(forecast1: Forecast, forecast2: Forecast): number {
    return getTime(new Date(forecast1.time)) - getTime(new Date(forecast2.time));
  }

  mappingForecastData(forecastData: Forecast[]): ForecastMapped[] {
    return forecastData
      .filter((day) => this.filterByHour(day.time, 0))
      .sort(this.sortByDate)
      .map((day) => {
        const forecastReduced: ForecastReduced = this.reducingForecastData(day.data);
        const weatherType = forecastReduced.weather ? this.weatherType[forecastReduced.weather] : undefined;

        const forecastMapped: ForecastMapped = {
          time: day.time,
          day: this.getDayName(day.time),
          iconUrl: weatherType?.iconUrl ?? '',
          iconMsg: weatherType?.iconMsg ?? '',
          rainIconUrl: 'assets/images/weather/water_drops.svg',
          rainQuantity: Math.round(forecastReduced.Prec6h),
          minCelsius: Math.round(forecastReduced.TMin2),
          maxCelsius: Math.round(forecastReduced.TMax2),
          minFahrenheit: Math.round(this.celsiusToFahrenheit(forecastReduced.TMin2)),
          maxFahrenheit: Math.round(this.celsiusToFahrenheit(forecastReduced.TMax2)),
        };
        return forecastMapped;
      });
  }

  bundleWeatherData(weatherData: [ForecastBySettlement[], WeatherBySettlement[]]): MappedWeatherData[] | null {
    const concat: MergedForecastAndWeather[] = [...weatherData[0], ...weatherData[1]];
    const bundle = [];
    let pointer = 0;

    for (let i = 1; i < concat.length; i++) {
      if (pointer === concat.length - 1) {
        return null;
      }
      if (concat[pointer].settlement === concat[i].settlement) {
        const forecast = this.mappingForecastData(concat[pointer].forecast || concat[i].forecast);
        const measurment = (concat[pointer].measurements || concat[i].measurements).t;
        // -999 = N/A
        const celsius = measurment === -999 ? null : Math.round(measurment);
        const fahrenheit = this.celsiusToFahrenheit(celsius);
        const settlement = concat[pointer].settlement;
        const today = forecast[0];

        bundle.push({
          settlement,
          forecast,
          current: {
            celsius,
            fahrenheit,
            iconUrl: today?.iconUrl,
            iconMsg: today?.iconMsg,
            ...this.getWeatherIconPositions(settlement),
          },
        });

        pointer++;
        i = pointer;
      }
    }

    return bundle as MappedWeatherData[];
  }

  getMeta(weatherDataBundle: MappedWeatherData[]): WeatherMeta {
    const forecast = weatherDataBundle[0].forecast;
    const today = forecast[0];
    const lastDay = forecast[forecast.length - 1];

    return {
      startMonth: this.getMonthName(today.time),
      startDay: getDate(new Date(today.time)),
      endMonth: this.getMonthName(lastDay.time),
      endDay: getDate(new Date(lastDay.time)),
    };
  }

  getNationalData(weatherDataBundle: MappedWeatherData[]): BundledCurrentWeatherData {
    const forecast = weatherDataBundle[0].forecast;
    const today = forecast[0];
    const celsius = Math.round(
      weatherDataBundle.map((w) => w.current.celsius).reduce((a, b) => a + b) /
        weatherDataBundle.filter((w) => w.current.celsius !== null).length
    );
    const fahrenheit = this.celsiusToFahrenheit(celsius);
    const weatherIcons = weatherDataBundle.map((b) => b.current).filter((b) => b.region === 'National');

    return {
      weatherIcons,
      current: {
        celsius,
        fahrenheit,
        iconUrl: today?.iconUrl,
        iconMsg: today?.iconMsg,
      },
    };
  }

  getSelectedData(weatherDataBundle: MappedWeatherData[], selectedSettlement: string): BundledCurrentWeatherData {
    const selectedSettlementData = weatherDataBundle.find((day) => day.settlement === selectedSettlement) || weatherDataBundle[0];
    const weatherIcons = weatherDataBundle.map((b) => b.current).filter((b) => b.region === selectedSettlement);

    return {
      weatherIcons,
      forecast: selectedSettlementData?.forecast,
      current: {
        celsius: selectedSettlementData?.current?.celsius,
        fahrenheit: selectedSettlementData?.current?.fahrenheit,
        iconUrl: selectedSettlementData?.current?.iconUrl,
        iconMsg: selectedSettlementData?.current?.iconMsg,
      },
    };
  }

  getWeatherDataBySettlements$(): Observable<any> {
    const currentSettlement = this.currentSettlementSubject$.getValue();
    const savedData = this.localStorageService.getItem('weatherData');
    const savedTime = this.localStorageService.getItem('weatherDataTime');

    if (savedData && savedTime && new Date().getTime() - Number(savedTime) < 3600000) {
      const weatherDataBundle = JSON.parse(savedData);
      return of({
        meta: this.getMeta(weatherDataBundle),
        selected: this.getSelectedData(weatherDataBundle, currentSettlement),
        national: this.getNationalData(weatherDataBundle),
      });
    } else {
      let params = new HttpParams();
      this.settlements.indexOf(currentSettlement) === -1 ? this.settlements.push(currentSettlement) : null;
      this.settlements.forEach((settlement: string) => {
        params = params.append(`settlements[]`, settlement);
      });

      const forecast$: Observable<ForecastBySettlement[] | any> = this.reqService.get(`/${this.lang}/forecast`, { params });
      const weather$: Observable<ForecastBySettlement[] | any> = this.reqService.get(`/${this.lang}/weather`, { params });

      return forkJoin([forecast$, weather$]).pipe(
        map((weatherData: [ForecastBySettlement[], WeatherBySettlement[]]) => {
          const weatherDataBundle: any = this.bundleWeatherData(weatherData);

          this.localStorageService.setItem('weatherData', JSON.stringify(weatherDataBundle));
          this.localStorageService.setItem('weatherDataTime', String(new Date().getTime()));
          return {
            meta: this.getMeta(weatherDataBundle),
            selected: this.getSelectedData(weatherDataBundle, currentSettlement),
            national: this.getNationalData(weatherDataBundle),
          };
        })
      );
    }
  }

  getWeatherDataByCurrentSettlement$(): void {
    const savedData = this.localStorageService.getItem('weatherData');
    const savedTime = this.localStorageService.getItem('weatherDataTime');

    if (savedData && savedTime && new Date().getTime() - Number(savedTime) < 3600000) {
      const currentSettlement = this.currentSettlementSubject$.getValue();
      const weatherDataBundle = JSON.parse(savedData);

      this.currentSettlementWeatherSubject$.next(this.getSelectedData(weatherDataBundle, currentSettlement).current);
    } else {
      this.getWeatherDataBySettlements$().subscribe(() => this.getWeatherDataByCurrentSettlement$());
    }
  }

  getCurrentLocationAndWeather(): void {
    this.currentSettlementSubject$.next('Budapest');
    this.getWeatherDataByCurrentSettlement$();
  }

  getCurrentSettlementAsObservable$(): Observable<string> {
    return this.currentSettlementSubject$.asObservable();
  }

  getCurrentSettlementWeatherAsObservable$(): Observable<any> {
    return this.currentSettlementWeatherSubject$.asObservable();
  }
}
