import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { TrendencySeoService } from 'trendency/utils';

@Component({
  selector: 'app-share-bar',
  templateUrl: './share-bar.component.html',
  styleUrls: ['./share-bar.component.scss'],
})
export class ShareBarComponent extends BaseComponent implements OnInit {
  public location: string;
  public title: string;

  constructor(private readonly seo: TrendencySeoService) {
    super();
  }

  ngOnInit(): void {
    this.location = this.seo.currentUrl;
    this.title = this.seo.getTitle();
  }

  public facebookShare(): void {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.location}`, 'pop', 'width=600, height=400, scrollbars=no');
  }

  public twitterShare(): void {
    window.open(`https://twitter.com/intent/tweet?url=${this.location}`, 'pop', 'width=600, height=400, scrollbars=no');
  }
}
