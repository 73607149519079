<app-filter-itinerary [filters]="activeFilters" (filterChange)="onFilterChange($event)"></app-filter-itinerary>
<div class="section-head" *ngIf="!anyFilterActive()">
  <h2 class="head-title">{{ 'label.trip-collection-title' | translate }}</h2>
  <p class="desc keep-resp">{{ 'label.trip-collection-subtitle' | translate }}</p>
</div>
<div class="active-filter-list">
  <div class="wrapper" *ngIf="anyFilterActive()">
    <div class="filter-type">
      <ng-container *ngFor="let filterOption of activeFilters.destinations">
        <div class="filter-tag red" *ngIf="filterOption.checked">
          <button class="filter-off" (click)="removeFilterElement(filterOption)">x</button>
          {{ filterOption.text }}
        </div>
      </ng-container>
      <ng-container *ngFor="let filterOption of activeFilters.tripLengths">
        <div class="filter-tag green" *ngIf="filterOption.checked">
          <button class="filter-off" (click)="removeFilterElement(filterOption)">x</button>
          <ng-container *ngIf="filterOption.text === '1'"> {{ filterOption.text }} {{ 'label.trip-length-day' | translate }} </ng-container>
          <ng-container *ngIf="filterOption.text !== '1'">
            {{ filterOption.text }} {{ 'label.trip-length-days' | translate }}
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngFor="let filterOption of activeFilters.targetAudiences">
        <div class="filter-tag blue-light" *ngIf="filterOption.checked">
          <button class="filter-off" (click)="removeFilterElement(filterOption)">x</button>
          {{ filterOption.text }}
        </div>
      </ng-container>
      <button *ngIf="anyFilterActive()" class="filter-clear filter-tag clear" (click)="clearFilters()">
        <i class="icon icon-close-red"></i>
        {{ 'label.clear-all-filters' | translate }}
      </button>
    </div>
  </div>
</div>
<div class="cards" *ngIf="firstLoaded">
  <div class="wrapper">
    <h3 *ngIf="trips?.length === 0" class="no-results">{{ 'label.filter-no-results' | translate }}</h3>
    <span *ngIf="showAlertMessage && trips?.length !== 0" class="cdk-visually-hidden" role="alert">{{
      ('label.filter-results' | translate) + ' ' + trips.length
    }}</span>
    <span *ngIf="showAlertMessage && trips?.length === 0" class="cdk-visually-hidden" role="alert">{{
      'label.filter-no-results' | translate
    }}</span>
    <div class="row" *ngIf="trips?.length !== 0" #tcFilterResults>
      <a [routerLink]="['/', 'trips', trip.slug] | localize" class="col-6 col-lg-4 card card--trip" *ngFor="let trip of trips">
        <div class="card__inner">
          <span
            class="card__bg"
            [ngStyle]="{ 'background-image': 'url(' + trip?.thumbnail?.url + ')' | ssrEmptyString }"
            role="img"
            [attr.aria-label]="trip?.thumbnail?.image?.title"
            corporateImageLazyLoad
          ></span>
          <div class="card__content" *ngIf="trip?.title || trip?.lead">
            <div class="card__header">
              <div class="poser">
                <div class="card__subtitle" [innerHTML]="trip.tripInfoHeaderHowLongItTakes"></div>
                <h4 class="card__title" *ngIf="trip?.title">{{ trip.title }}</h4>
              </div>
            </div>
            <div class="card__text" *ngIf="trip?.lead" [innerHTML]="trip.lead"></div>
          </div>
        </div>
      </a>
    </div>
    <div class="bottom-button-box" *ngIf="trips.length">
      <button
        class="button load-button button-secondary"
        [appBackgroundColor]="'white'"
        [ngClass]="{ hidden: maxTripCount <= tripsPerPage * limitMultiplier }"
        (click)="loadMore()"
      >
        {{ 'label.load-more' | translate }}
      </button>
    </div>
  </div>
</div>
