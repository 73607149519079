import { Component, OnInit, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouteMapService } from '../shared/services/route-map.service';
import { IContentLang } from '../shared/shared.definitions';
import { HeaderComponent } from '../header/header.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-base',
  templateUrl: 'base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnInit, OnDestroy {
  // @ViewChild('popupLogin', {static: true}) popupLogin: PopupComponent;
  // @ViewChild('popupDelete', {static: true}) popupDelete: PopupComponent;
  @ViewChild('header', { static: true }) header: HeaderComponent;

  toTopShown: boolean;
  menu: any;
  footerMenu: any;
  footerData: any;
  languages: IContentLang[];

  private readonly destroy$ = new Subject();

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(): void {
    const top = window.pageYOffset - 0;
    this.toggleHeadFix(top);
  }

  constructor(private readonly activatedRoute: ActivatedRoute, private readonly routeMapService: RouteMapService) {}

  ngOnInit(): void {
    this.activatedRoute.data.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res?.data && res?.data[1]) {
        this.footerData = res.data[1].footerData;
        this.languages = res.data[1].languages;
      }
      if (res?.data && res?.data[0]) {
        this.menu = {};
        this.footerMenu = {};
        const menu = res.data[0];
        Object.keys(menu[0].data).forEach((key) => {
          const tree: any[] = menu[0].data[key].map((menuItem) => {
            menuItem.children = menuItem.children.map((child) => this.addRouterLinkToMenuItems(child));
            return this.addRouterLinkToMenuItems(menuItem);
          });
          if (key.includes('header')) {
            const recommendations = menu.find((item) => item.groupKey === key);
            recommendations.data = recommendations.data.map((recommendationItem) => {
              return {
                ...this.addRouterLinkToMenuItems(recommendationItem),
                templateUrl: `url(${recommendationItem.thumbnail.url})`,
              };
            });
            const separatedTree = { left: [], right: [] };
            const separatorIndex = tree.findIndex((item) => item.relatedType === 'ColumnSeparator');
            if (separatorIndex > -1) {
              separatedTree.left = tree.slice(0, separatorIndex);
              separatedTree.right = tree.slice(separatorIndex + 1);
            } else {
              separatedTree.left = tree;
            }
            const recommendationsArrayHalf = Math.ceil(recommendations.data.length / 2);
            this.menu[key] = {
              tree: separatedTree,
              recommendationsLeft: recommendations && recommendations.data.slice(0, recommendationsArrayHalf),
              recommendationsRight: recommendations && recommendations.data.slice(recommendationsArrayHalf, recommendations.length),
            };
          }
          if (key.includes('footer')) {
            this.footerMenu[key] = {
              tree,
            };
          }
        });
        this.footerData.footerMenu = this.footerMenu;
      }
    });
  }

  // ngAfterViewInit(): void {
  // this.sharedService.loginPopup$
  //   .pipe(takeUntil(this.destroy$))
  //   .subscribe(val => {
  //     if (val === true) {
  //       this.popupLogin.Open();
  //     }
  //   });

  // this.sharedService.deleteElementPopup$
  //   .pipe(takeUntil(this.destroy$))
  //   .subscribe(val => {
  //     if (val === true) {
  //       this.popupDelete.Open();
  //     }
  //   });
  // }

  toggleHeadFix(top: number): void {
    const topTriggerPos = window.innerHeight * 1.5;

    this.toTopShown = top > topTriggerPos;
  }

  scrollToTop(): void {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  addRouterLinkToMenuItems(menuItem: any): any {
    const subComponent = {
      contentType: { key: menuItem.relatedType },
      contentPage: { slug: menuItem.related && menuItem.related.slug },
    };
    return {
      ...menuItem,
      routerLink: menuItem.relatedType === 'CustomUrl' ? null : this.routeMapService.getRoute(subComponent),
      customUrl: menuItem.customUrl,
      targetBlank: menuItem.targetBlank,
    };
  }

  navContentChange(): void {
    /* open header menu from mobile footer bridge call */
    this.header.toggleNavContantOutCall();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
