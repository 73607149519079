import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements OnInit {
  @Input() opened = false;
  @Input() showDailyOnce = false;

  ngOnInit(): void {
    if (this.showDailyOnce) {
      const lastShown = localStorage.getItem('szepPopupLastShown');
      const now = new Date().getTime();

      if (lastShown) {
        const timeDiff = now - parseInt(lastShown, 10);

        if (timeDiff > 86400000) {
          this.Open();
        }
      } else {
        this.Open();
      }
    }
  }

  Open(): void {
    this.opened = true;

    if (this.showDailyOnce) {
      localStorage.setItem('szepPopupLastShown', new Date().getTime().toString());
    }
  }

  Close(): void {
    this.opened = false;
  }
}
