<div class="custom-form">
  <div class="container">
    <div class="row">
      <div class="col-md-10">
        <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
          <h2 class="custom-form__title">Jelentkezési űrlap</h2>
          <p class="custom-form__subtitle">* A csillaggal jelölt mezők kitöltése kötelező</p>

          <h4>Vendéglátóhely neve</h4>

          <mat-form-field trendencyFormControlErrorContainer>
            <mat-label>Vendéglátóhely neve</mat-label>
            <input
              matInput
              type="text"
              formControlName="partnerName"
              autocomplete="name"
              maxlength="255"
              [errors]="{ required: 'VALIDATION.required', maxlength: 'Maximum 250 karakter!' }"
            />
          </mat-form-field>

          <h4>Vendéglátóhely címe</h4>

          <mat-form-field trendencyFormControlErrorContainer>
            <mat-label>Település</mat-label>
            <input
              matInput
              type="text"
              formControlName="city"
              autocomplete="address-level1"
              maxlength="255"
              [errors]="{ required: 'VALIDATION.required' }"
            />
          </mat-form-field>

          <div class="row">
            <div class="col-md-6">
              <mat-form-field trendencyFormControlErrorContainer>
                <mat-label>Utca, házszám</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="address"
                  autocomplete="street-address"
                  maxlength="255"
                  [errors]="{ required: 'VALIDATION.required' }"
                />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field trendencyFormControlErrorContainer>
                <mat-label>Irányítószám/helyrajzi szám</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="zipcode"
                  autocomplete="postal-code"
                  maxlength="255"
                  [errors]="{ required: 'VALIDATION.required', pattern: 'VALIDATION.patter.only-num-and-letters' }"
                />
              </mat-form-field>
            </div>
          </div>

          <h4>Kapcsolattartó adatai</h4>

          <mat-form-field trendencyFormControlErrorContainer>
            <mat-label>Név</mat-label>
            <input
              matInput
              type="text"
              formControlName="contactName"
              autocomplete="name"
              maxlength="255"
              [errors]="{ required: 'VALIDATION.required' }"
            />
          </mat-form-field>
          <div class="col-md-6 emailFix">
            <mat-form-field trendencyFormControlErrorContainer>
              <mat-label>Email</mat-label>
              <input
                matInput
                type="email"
                formControlName="contactEmail"
                maxlength="255"
                [errors]="{ email: 'VALIDATION.email', required: 'VALIDATION.required' }"
              />
            </mat-form-field>
          </div>
          <div class="row plusContainer">
            <div class="col-4 col-md-2">
              <mat-form-field trendencyFormControlErrorContainer>
                <mat-label>Előhívó szám</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="contactCountryCode"
                  minlength="2"
                  maxlength="3"
                  autocomplete="tel-country-code"
                  [errors]="{
                    required: 'VALIDATION.required',
                    minlength: 'Minimum 2 karakter!',
                    maxlength: 'Maximum 3 karakter!'
                  }"
                />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field trendencyFormControlErrorContainer>
                <mat-label>Telefonszám</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="contactPhoneNumber"
                  autocomplete="tel"
                  maxlength="255"
                  [errors]="{ required: 'VALIDATION.required' }"
                />
              </mat-form-field>
            </div>
          </div>

          <h4>Weboldala (vagy a weboldalon belül a Szent Márton-napi menü, ajánlat linkje)</h4>

          <mat-form-field trendencyFormControlErrorContainer>
            <mat-label>Link</mat-label>
            <input
              matInput
              type="url"
              formControlName="url"
              autocomplete="url"
              maxlength="255"
              [errors]="{ required: 'VALIDATION.required', pattern: 'VALIDATION.pattern.url' }"
            />
          </mat-form-field>

          <div class="custom-form__text" *ngIf="text" [innerHTML]="text"></div>

          <div class="custom-form__form-group">
            <div class="custom-form__checkbox" trendencyFormControlErrorContainer>
              <input
                id="accept-campaign-rules"
                type="checkbox"
                tabindex="0"
                formControlName="policyAccept"
                [errors]="{ required: 'VALIDATION.required' }"
              />
              <label for="accept-campaign-rules">
                <span class="checkbox-text-highlighted">Nyilatkozat</span>
                <span class="checkbox-text" *ngIf="policyDocText" [innerHTML]="policyDocText"></span>
                <a [href]="policyDoc" target="_blank">Nyilatkozat</a>
              </label>
            </div>
          </div>

          <div class="custom-form__form-group">
            <div class="custom-form__checkbox" trendencyFormControlErrorContainer>
              <input
                id="accept-gdpr"
                type="checkbox"
                tabindex="0"
                formControlName="dataManagementAccept"
                [errors]="{ required: 'VALIDATION.required' }"
              />
              <label for="accept-gdpr">
                <span class="checkbox-text-highlighted">Adatkezelési tájékoztató</span>
                <span class="checkbox-text" *ngIf="dataManagementDocText" [innerHTML]="dataManagementDocText"></span>
                <a [href]="dataManagementDoc" target="_blank">Adatkezelési tájékoztató</a>
              </label>
            </div>
          </div>

          <button class="button button-primary" [appBackgroundColor]="'red'">Küldés</button>
        </form>
      </div>
    </div>
  </div>

  <app-thank-you [isShow]="formSent"></app-thank-you>
</div>
