<section
  class="move-around"
  [ngStyle]="{ 'background-image': 'url(' + image.url + ')' } | ssrEmptyString"
  [attr.aria-label]="image?.image?.title"
  corporateImageLazyLoad
>
  <div class="top" *ngIf="componentObject?.title || componentObject?.subtitle">
    <h2 class="title" *ngIf="componentObject?.title" id="{{ componentObject | titleToId }}">{{ componentObject.title }}</h2>
    <p class="subtitle" *ngIf="componentObject?.subtitle">{{ componentObject.subtitle }}</p>
  </div>

  <div class="move-around-wrapper">
    <ng-container *ngFor="let card of componentObject.subComponents">
      <div class="card-wrapper">
        <app-link-card [data]="card"></app-link-card>
      </div>
    </ng-container>
  </div>

  <div #moveAroundObjElement config="[carouselOptions]" class="mobile-carousel">
    <ng-container *ngFor="let card of componentObject.subComponents">
      <div>
        <div class="mobile-slide">
          <app-link-card [data]="card"></app-link-card>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="home-location" *ngIf="componentObject?.mapIconSubtitle || mapIconKey">
    <div class="wrapper">
      <div class="map-titles" *ngIf="componentObject?.mapIconSubtitle">
        <span>{{ componentObject.mapIconSubtitle }}</span>
      </div>
      <i [ngClass]="'icon ' + mapIconKey" *ngIf="mapIconKey"></i>
    </div>
  </div>

  <div [ngClass]="'specicon ' + thematicIconKey" class="specicon" *ngIf="thematicIconKey"></div>
</section>
