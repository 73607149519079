<div class="card card--experience">
  <div class="card__image" [ngStyle]="{ 'background-image': card.thumbnail ? 'url(' + card.thumbnail.url + ')' : 'none' } | ssrEmptyString">
    <div
      class="card__profile"
      *ngIf="card.relation.influencer"
      [ngStyle]="
        {
          'background-image': card.relation.influencer.picture ? 'url(' + card.relation.influencer.picture.url + ')' : 'none'
        } | ssrEmptyString
      "
    ></div>
  </div>
  <a [routerLink]="['/' | localize, 'experiences' | localize, card.slug]" class="card__content">
    <div class="card__uploader" [appBackgroundColor]="'white'">{{ card.relation.influencer ? card.relation.influencer.name : '' }}</div>
    <h4 class="card__title" [appBackgroundColor]="'white'">{{ card.title }}</h4>
  </a>
  <div class="card__bottom" [appBackgroundColor]="'white'">
    <button class="card__button">Részletek <i class="icon icon-arrow-right-more-red"></i></button>
  </div>
</div>
