import { Component, HostListener, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { BaseComponent } from '../base.component';
import { Observable, Subject } from 'rxjs';
import { ExperienceCollectionService } from '../../../experience-collection/experience-collection.service';
import { TrendencyUtilsService } from 'trendency/utils';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

declare let $: any;

@Component({
  selector: 'app-find-an-experience',
  templateUrl: './find-an-experience.component.html',
  styleUrls: ['./find-an-experience.component.scss'],
  providers: [ExperienceCollectionService],
})
export class FindAnExperienceComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('influencerRow', { static: true }) influencerRow: ElementRef;
  private mutationObserver: MutationObserver;

  constructor(
    private readonly experienceCollectionService: ExperienceCollectionService,
    private readonly utilsService: TrendencyUtilsService,
    private readonly translateService: TranslateService
  ) {
    super();
    if (this.utilsService.isBrowser()) {
      this.onResize();
    }
  }

  title;
  subtitle;
  filterType = 'box';
  checkedUser: boolean;
  checkedInfluencer: boolean;
  mapView = true;
  pageWidth: number;
  showCount: number;
  loadMaxExperience = 8;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.pageWidth = window.innerWidth;
    this.showCount = Math.floor((this.pageWidth + 66) / 142) - 2;
  }

  ngOnInit(): void {
    if (this.componentObject && this.componentObject.title) {
      this.title = this.componentObject.title;
    }
    if (this.componentObject && this.componentObject.subtitle) {
      this.subtitle = this.componentObject.subtitle;
    }

    this.experienceCollectionService.loadMaxExperience = this.loadMaxExperience;
    this.experienceCollectionService
      .getExperiences$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.experienceCollectionService.transformExperiencesData(res);
      });
    this.detectInluencerRows();
  }

  detectInluencerRows(): void {
    if (this.utilsService.isBrowser()) {
      const config = { attributes: true, childList: true, subtree: true };
      this.mutationObserver = new MutationObserver((mutation: MutationRecord[]) => {
        if (mutation.length) {
          this.initSlick();
        }
      });
      this.mutationObserver.observe(this.influencerRow.nativeElement, config);
    }
  }

  get randomExperiences$(): Observable<any> {
    return this.experienceCollectionService.randomExperiences$;
  }

  get markers$(): Observable<any> {
    return this.experienceCollectionService.markers$;
  }

  get filters$(): Observable<any> {
    return this.experienceCollectionService.filters$;
  }

  get loaderCounter$(): Observable<number> {
    return this.experienceCollectionService.loaderCounter$;
  }

  get experienceCounter$(): Observable<number> {
    return this.experienceCollectionService.experienceCounter$;
  }

  get filtersOpened$(): Observable<boolean> {
    return this.experienceCollectionService.filtersOpened$;
  }

  get selectedFilterTitle$(): Observable<string> {
    return this.experienceCollectionService.selectedFilterTitle$;
  }

  get influencers$(): Observable<any> {
    return this.experienceCollectionService.influencers$;
  }

  toggleFilters(): void {
    this.experienceCollectionService.toggleFilters();
  }

  selectFilter(filter: string): void {
    this.experienceCollectionService.selectFilterElem(filter);
  }

  initSlick(): void {
    const nextLabel = this.translateService.instant('label.next');
    const prevLabel = this.translateService.instant('label.prev');
    $(this.influencerRow.nativeElement)
      .not('.slick-initialized')
      .slick({
        initialSlide: 0,
        dots: false,
        autoplay: false,
        lazyLoad: 'ondemand',
        centerMode: false,
        slidesToShow: 9,
        slidesToScroll: 6, // not working with centermode true!
        speed: 200,
        autoplaySpeed: 3000,
        variableWidth: false,
        infinite: true,
        nextArrow: `<button class="slick-next slick-arrow" aria-label="${nextLabel}" type="button">${nextLabel}</button>`,
        prevArrow: `<button class="slick-prev slick-arrow" aria-label="${prevLabel}" type="button">${prevLabel}</button>`,
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 8,
              slidesToScroll: 6,
              infinite: true,
            },
          },
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 7,
              slidesToScroll: 6,
              infinite: true,
            },
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 3,
              infinite: true,
            },
          },
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 2,
              infinite: true,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 2,
              infinite: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 360,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
  }

  toggleView(): void {
    this.mapView = !this.mapView;
    this.filterType = this.filterType === 'box' ? 'radio' : 'box';
  }

  loadMore(): void {
    this.experienceCollectionService.loadMore();
  }

  toggleUser(): void {
    this.checkedUser = !this.checkedUser;
  }

  toggleInluencer(): void {
    this.checkedInfluencer = !this.checkedInfluencer;
  }

  ngOnDestroy(): void {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
