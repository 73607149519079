import { Injectable } from '@angular/core';
import { ITekaImageObject, ITekaThumbnailImage } from '../shared.definitions';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TekaService {
  constructor(private readonly translateService: TranslateService) {}

  private getContentId(data: any): string | undefined {
    return (Object.values(data.tekaFiles as any).find((object: any) => object.payload.contentType === 'ImageGallery') as any)?.payload
      .contentId;
  }

  private getHeaderImage(data: any, contentId: string): any {
    return data.tekaFiles[contentId].payload.images.filter((image) => {
      return image.files.some((file) => file.netaFileTypes[0] === 'HeaderImage');
    })[0];
  }

  private getFirstOrderImage(data: any, contentId: string): any {
    return data.tekaFiles[contentId]?.payload.images.find((image) => image.orderNumber === 1);
  }

  getImage(data, quality: string): ITekaImageObject {
    if (!data?.tekaFiles) {
      return undefined;
    }

    const contentId = this.getContentId(data);
    const headerImage = this.getHeaderImage(data, contentId);
    const image = this.getFirstOrderImage(data, contentId);

    if (!headerImage && !image?.files?.length) {
      return undefined;
    }

    return {
      ...(headerImage ? headerImage.files.find((img) => img.quality === quality) : image.files.find((img) => img.quality === quality)),
      image: {
        title: this.getTekaImageDescription(data),
      },
    };
  }

  getThumbnailImage(data): ITekaThumbnailImage {
    const indexImageUrl = data?.tekaData?.payload.find((item) => item.contentType === 3).indexImageUrl;

    if (!indexImageUrl) {
      return null;
    }

    return {
      url: indexImageUrl,
      image: {
        title: this.getTekaImageDescription(data),
      },
    };
  }

  getIconImageByCategory(item): string {
    const category = item?.entity?.category && item?.entity?.category[0];
    if (!category) {
      return null;
    }

    const catEntries = Object.entries(category);
    if (!catEntries?.length) {
      return null;
    }

    return `/assets/images/filter-icons/icon_${catEntries[0][0]}.svg`;
  }

  getTekaImageDescription(data: any): string {
    return data?.tekaData?.payload?.title ? data.tekaData.payload.title[this.translateService.currentLang] : '';
  }
}
