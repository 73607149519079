import { Component } from '@angular/core';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-practical-info',
  templateUrl: './practical-info.component.html',
  styleUrls: ['./practical-info.component.scss'],
})
export class PracticalInfoComponent extends BaseComponent {
  constructor() {
    super();
  }
}
