import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TrendencyUtilsService } from 'trendency/utils';
import { BaseComponent } from '../base.component';
import { TranslateService } from '@ngx-translate/core';
import { RouteMapService } from '../../services/route-map.service';

declare let $: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('floatingInfoWindow') floatingInfoWindow: ElementRef;
  @ViewChild('carousel', { static: true }) carousel: ElementRef;

  lastSelectedId: string;
  cityData: any;
  destinations: any[];
  infoWindow: Element;
  lang: string;

  constructor(
    private readonly utilsService: TrendencyUtilsService,
    private readonly translateService: TranslateService,
    private readonly routeMap: RouteMapService
  ) {
    super();
  }

  ngOnInit(): void {
    this.lang = this.translateService.currentLang;
    this.initDestinations();
  }

  ngAfterViewInit(): void {
    this.infoWindow = this.floatingInfoWindow.nativeElement.children[0];
    if (this.utilsService.isBrowser()) {
      document.addEventListener('click', this.handleOutsideClick, true);
      this.initSlick();
    }
  }

  handleOutsideClick(event: any): void {
    const container = document.getElementsByClassName('info-window opened')[0];
    const notCityMarker = event.target.getAttribute('class') !== 'hover citymarker';
    if (container && notCityMarker) {
      if (container !== event.target && !container.contains(event.target)) {
        container.classList.remove('opened');
      }
    }
  }

  initDestinations(): void {
    this.destinations = [];

    if (this.componentObject?.subComponents) {
      this.destinations = this.componentObject.subComponents.map((destination) => {
        const sc = destination?.subComponents && destination?.subComponents[0];
        return {
          ...destination,
          className: destination?.countryDestination?.slug,
          routerLink: this.routeMap.getRoute(sc),
          customUrl: sc?.customUrl,
          newTab: sc?.newTab,
        };
      });
    }

    if (this.componentObject.destinationBudapest) {
      this.destinations.push({ ...this.componentObject.destinationBudapest, className: 'budapest' });
    }
    if (this.componentObject.destinationBalaton) {
      this.destinations.push({ ...this.componentObject.destinationBalaton, className: 'balaton' });
    }
    if (this.componentObject.destinationDebrecen) {
      this.destinations.push({ ...this.componentObject.destinationDebrecen, className: 'debrecen' });
    }
    if (this.componentObject.destinationTokaj) {
      this.destinations.push({ ...this.componentObject.destinationTokaj, className: 'tokaj' });
    }
    if (this.componentObject.destinationPecs) {
      this.destinations.push({ ...this.componentObject.destinationPecs, className: 'pecs' });
    }
    if (this.componentObject.destinationSopron) {
      this.destinations.push({ ...this.componentObject.destinationSopron, className: 'sopron' });
    }
    if (this.componentObject.destinationEger) {
      this.destinations.push({ ...this.componentObject.destinationEger, className: 'eger' });
    }
    if (this.componentObject.destinationGyor) {
      this.destinations.push({ ...this.componentObject.destinationGyor, className: 'gyor' });
    }
    if (this.componentObject.destinationSzeged) {
      this.destinations.push({ ...this.componentObject.destinationSzeged, className: 'szeged' });
    }
    if (this.componentObject.destinationGyula) {
      this.destinations.push({ ...this.componentObject.destinationGyula, className: 'gyula' });
    }
  }

  openWindow(destination: any): void {
    if (!this.infoWindow.className.includes('opened')) {
      this.cityData = destination;
      this.infoWindow.classList.add('opened');
    } else if (this.infoWindow.className.includes('opened') && this.cityData !== destination) {
      this.cityData = destination;
    } else {
      this.infoWindow.classList.remove('opened');
    }
  }

  closeWindow(): void {
    if (this.infoWindow.className.includes('opened')) {
      this.infoWindow.classList.remove('opened');
    }
  }

  initSlick(): void {
    const nextLabel = this.translateService.instant('label.next');
    const prevLabel = this.translateService.instant('label.prev');
    $(this.carousel.nativeElement)
      .not('.slick-initialized')
      .slick({
        lazyLoad: 'ondemand',
        slidesToShow: 1,
        infinite: true,
        pauseOnHover: true,
        pauseOnDotsHover: true,
        nextArrow: `<button class="slick-next slick-arrow" aria-label="${nextLabel}" type="button">${nextLabel}</button>`,
        prevArrow: `<button class="slick-prev slick-arrow" aria-label="${prevLabel}" type="button">${prevLabel}</button>`,
      });
  }
}
