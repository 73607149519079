<section class="text-content" *ngIf="text">
  <div class="wrapper">
    <div class="left">
      <div class="content">
        <div class="content-text" [innerHTML]="text"></div>
      </div>
    </div>
    <div class="right"></div>
  </div>
</section>
