import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, timeout, tap } from 'rxjs/operators';
import { IHttpOptions } from 'trendency/http';
import { environment } from '../../../src/environments/environment';
import { TrendencyUtilsService } from 'trendency/utils';
import { makeStateKey, TransferState, StateKey } from '@angular/platform-browser';
import { IEnvironmentApiUrl } from 'src/environments/environment.definitions';

@Injectable()
export class TrendencyReqService {

    private apiUrl: string;
    private timeout = 10 * 1000; // mennyi idő után timeout-oljon, ha a lekérésre nem jön válasz (ms)

    constructor(
      private http: HttpClient,
      private utilsService: TrendencyUtilsService,
      private transferState: TransferState,
    ) {
      this.apiUrl = this.getApiUrl();
    }

    get(subUrl: string, options?: IHttpOptions): Observable<any> {
      const url = this.resolveUrl(subUrl);
      if (!environment.production) {
        this.utilsService.log(`GET: ${url}`);
      }
      const TRANSFERSTATE_KEY = makeStateKey<any>(`GET_${url}`);
      const cachedResponse = this.getCachedResponse(TRANSFERSTATE_KEY);

      return cachedResponse
        ? of(cachedResponse)
        : this.http.get(url, options).pipe(
            timeout(this.timeout),
            catchError(err => {
              console.error(`GET: ${url}: `, err);
              return throwError(err);
            }),
            tap(response => {
              if (!this.utilsService.isBrowser()) {
                this.transferState.set(TRANSFERSTATE_KEY, response);
              }
            })
        );
    }

    post(subUrl: string, data: any, options?: IHttpOptions): Observable<any> {
      const url = this.resolveUrl(subUrl);
      if (!environment.production) {
        this.utilsService.log(`POST: ${url}`);
      }
      const TRANSFERSTATE_KEY = makeStateKey<any>(`POST_${url}`);
      const cachedResponse = this.getCachedResponse(TRANSFERSTATE_KEY);

      return cachedResponse
        ? of(cachedResponse)
        : this.http.post(url, data, options).pipe(
          timeout(this.timeout),
          catchError(err => {
            console.error(`POST: ${url}: `, err);
            return throwError(err);
          }),
          tap(response => {
            if (!this.utilsService.isBrowser()) {
              this.transferState.set(TRANSFERSTATE_KEY, response);
            }
          })
        );
    }

    put(subUrl: string, data: any, options?: IHttpOptions): Observable<any> {
      const url = this.resolveUrl(subUrl);
      if (!environment.production) {
        this.utilsService.log(`PUT: ${url}`);
      }
      const TRANSFERSTATE_KEY = makeStateKey<any>(`PUT_${url}`);
      const cachedResponse = this.getCachedResponse(TRANSFERSTATE_KEY);

      return cachedResponse
        ? of(cachedResponse)
        : this.http.put(url, data, options).pipe(
          timeout(this.timeout),
          catchError(err => {
            console.error(`PUT: ${url}: `, err);
            return throwError(err);
          }),
          tap(response => {
            if (!this.utilsService.isBrowser()) {
              this.transferState.set(TRANSFERSTATE_KEY, response);
            }
          })
        );
    }

    delete(subUrl: string, options?: IHttpOptions): Observable<any> {
      const url = this.resolveUrl(subUrl);
      if (!environment.production) {
        this.utilsService.log(`DELETE: ${url}`);
      }
      const TRANSFERSTATE_KEY = makeStateKey<any>(`DELETE_${url}`);
      const cachedResponse = this.getCachedResponse(TRANSFERSTATE_KEY);

      return cachedResponse
      ? of(cachedResponse)
      : this.http.delete(url, options).pipe(
        timeout(this.timeout),
        catchError(err => {
          console.error(`DELETE: ${url}: `, err);
          return throwError(err);
        }),
        tap(response => {
          if (!this.utilsService.isBrowser()) {
            this.transferState.set(TRANSFERSTATE_KEY, response);
          }
        })
      );
    }

  // Ha az apiUrl string-ként van megadva, akkor használja azt, ha pedig külön van választva, akkor böngésző oldalon
  // használja a clientApiUrl-t, Node.js oldalon pedig a serverApiUrl-t
  private getApiUrl(): string {
    if (typeof environment.apiUrl === 'string') {
      return environment.apiUrl;
    } else {
      if (this.utilsService.isBrowser()) {
        return (<IEnvironmentApiUrl>environment.apiUrl).clientApiUrl;
      } else {
        return (<IEnvironmentApiUrl>environment.apiUrl).serverApiUrl;
      }
    }

  }

  private resolveUrl(url: string): string {
    const absoluteUrlPattetn = /^https?:\/\//i;

    // Ha abszolút url-t hív, nem kell semmit módosítani
    if (absoluteUrlPattetn.test(url)) {
      return url;
    }

    // Ha böngésző oldali a hívás és abszolút apiUrl van megadva, meg kell vizsgálni hogy az apiUrl protokollja
    // megegyezik-e a weboldal protokolljával és ha nem, akkor ehhez igazítani az apiUrl-t
    // let apiUrl = this.apiUrl;
    // if (this.utilsService.isBrowser() && absoluteUrlPattetn.test(this.apiUrl)) {
    //   apiUrl = this.matchClientApiUrlWithWebsiteProtocol(absoluteUrlPattetn);
    // }

    if (url.indexOf('/') === 0) {
      const subUrl =  url.slice(1, url.length);
      return `${this.apiUrl}/${subUrl}`;
    }
    return `${this.apiUrl}/${url}`;
  }

  // Kiszedi a TransferState-ben lévő response-t, ha létezik, majd törli a TransferState-ből
  // Erre azért van szükség, hogy SSR-ben, egy oldalbetöltésnél lefutott lekérés (pl. resolverben),
  // ne fusson le mégegyszer a böngészőben, hanem a szervertől kapott adatokat használja
  private getCachedResponse(stateKey: StateKey<any>): any {

    if (this.transferState.hasKey(stateKey)) {
      const cachedResponse = this.transferState.get<any>(stateKey, null);
      this.transferState.remove(stateKey);
      return cachedResponse;
    }
    return null;
  }

  // Ha a böngésző oldali API url protokollja nem egyezik meg a weboldal protokolljával, akkor használja a
  // weboldal protokollját
  // private matchClientApiUrlWithWebsiteProtocol(absoluteUrlPattern: RegExp): string {
  //   const websiteProtocol = `${document.location.protocol}//`;
  //   const clientApiUrlProtocol = this.apiUrl.match(absoluteUrlPattern) && this.apiUrl.match(absoluteUrlPattern)[0];

  //   const clientApiUrl = websiteProtocol !== clientApiUrlProtocol
  //     ? this.apiUrl.replace(clientApiUrlProtocol, websiteProtocol)
  //     : this.apiUrl;

  //   return clientApiUrl;
  // }

}
