<section class="page-head" *ngIf="imageUrl || youtubeUrl" [ngClass]="{ active: componentObject?.thematicIcon }">
  <span
    *ngIf="imageUrl && !youtubeUrl"
    [ngStyle]="{ backgroundImage: 'url(' + imageUrl + ')' | ssrEmptyString }"
    [attr.aria-label]="imageAlt"
    aria-hidden="true"
    corporateImageLazyLoad
    role="img"
    class="page-head__image"
  ></span>

  <div *ngIf="componentObject?.gradient" class="custom-gradient" aria-hidden="true"></div>

  <app-video-player *ngIf="youtubeUrl" [url]="youtubeUrl"></app-video-player>

  <div class="page-head__wrapper">
    <h1 class="page-head__heading" *ngIf="componentObject?.title || influencer || eventDateFrom">
      <span *ngIf="componentObject?.title" class="title">{{ componentObject.title }}</span>

      <ng-container *ngIf="!youtubeUrl">
        <span id="icon-arrow" class="icon icon-arrow-down-white" aria-hidden="true"></span>

        <span
          *ngIf="influencer"
          [ngStyle]="{ backgroundImage: 'url(' + influencer?.picture?.url + ')' | ssrEmptyString }"
          [attr.aria-label]="influencer?.picture?.image?.title"
          aria-hidden="true"
          role="img"
          corporateImageLazyLoad
          class="influencer"
        ></span>
        <span class="influencer-header" *ngIf="influencer">{{ influencer.name }} {{ 'label.favourite-places' | translate }}</span>

        <span *ngIf="eventDateFrom" class="date-header">{{ dateLabel(eventDateFrom, eventDateTill) }}</span>
      </ng-container>
    </h1>

    <div
      *ngIf="componentObject?.thematicIcon?.key"
      [ngClass]="'specicon ' + componentObject.thematicIcon?.key"
      class="page-head__spec-icon"
    ></div>

    <div class="page-head__content">
      <app-destination-icon
        *ngIf="componentObject?.destinationIcon?.key"
        [key]="componentObject.destinationIcon.key"
      ></app-destination-icon>

      <div class="page-head__map-wrapper">
        <div
          class="page-head__map-text"
          *ngIf="componentObject?.mapIconSubtitle0 || componentObject?.mapIconSubtitle1 || componentObject?.mapIconSubtitle2"
        >
          <span>{{ componentObject.mapIconSubtitle0 }}</span>
          <span>{{ componentObject.mapIconSubtitle1 }}</span>
          <span>{{ componentObject.mapIconSubtitle2 }}</span>
        </div>
        <i class="page-head__map-icon" [ngClass]="'icon ' + componentObject.mapIcon?.key" *ngIf="componentObject.mapIcon?.key"></i>
      </div>
    </div>
  </div>
</section>
