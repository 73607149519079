import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { ApiService } from '../../services/api.service';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormService } from '../../services/form.service';
import { catchError, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-summer-campaign-form',
  templateUrl: './summer-campaign-form.component.html',
  styleUrls: ['../campaign-form/campaign-form.component.scss'],
})
export class SummerCampaignFormComponent extends BaseComponent implements OnInit {
  dataManagementDoc: string;
  dataManagementDocText: string;
  policyDoc: string;
  policyDocText: string;
  text: string;
  formTryToSent = false;
  formSent = false;
  formFailed = false;
  form: FormGroup;
  pageUuid: string;
  taxNumberMask: (string | RegExp)[];

  constructor(private readonly apiService: ApiService, private readonly route: ActivatedRoute, private readonly formService: FormService) {
    super();
    this.taxNumberMask = this.formService.taxNumberMask;
  }

  ngOnInit(): void {
    this.pageUuid = this.route.snapshot.data.data.uuid;

    if (this.componentObject) {
      this.text = this.componentObject.text;
      this.dataManagementDocText = this.componentObject.dataManagementDocText;
      this.policyDocText = this.componentObject.policyDocText;

      if (this.componentObject.dataManagementDoc) {
        this.apiService.getFile$(this.componentObject.dataManagementDoc.uuid).subscribe((res) => (this.dataManagementDoc = res.path));
      }

      if (this.componentObject.policyDoc) {
        this.apiService.getFile$(this.componentObject.policyDoc.uuid).subscribe((res) => (this.policyDoc = res.path));
      }
    }

    this.initFormGroup();
  }

  onFormSubmit(): void {
    this.formTryToSent = true;
    if (this.form.valid) {
      this.apiService
        .getSummerCampaignForm$(this.pageUuid)
        .pipe(
          concatMap((res: any) => {
            res.data = res.data.map((field) => {
              field.value = this.form.value[field.key];
              if (field.key === 'contactPhone') {
                field.value = this.form.value['contactCountryCode'] + this.form.value[field.key];
              }
              if (['isKpp', 'typeAccommodation', 'typeCateringUnit', 'typeAttraction', 'typeBathingPlace'].includes(field.key)) {
                if (field.value === '') {
                  field.value = false;
                }
              }
              return field;
            });
            return this.apiService.postSummerCampaignForm$(this.pageUuid, res);
          }),
          catchError((error: any) => {
            console.log(error);
            this.formSent = false;
            this.formFailed = true;
            return of(null);
          })
        )
        .subscribe(() => {
          this.formTryToSent = false;
          this.formSent = true;
          this.formFailed = false;
        });
    }
  }

  initFormGroup(): void {
    const TAX_REGEXP = /^\d{8}-\d-\d{2}$/;
    const URL_REGEXP = '(https?://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    const ZIP_REGEX = /^([a-zA-Z0-9 .:\\/_-]+)$/;

    this.form = new FormGroup(
      {
        accomodationName: new FormControl('', [Validators.required, Validators.maxLength(250)]),
        city: new FormControl('', [Validators.required]),
        address: new FormControl('', [Validators.required]),
        zipcode: new FormControl('', [Validators.required, Validators.pattern(ZIP_REGEX)]),
        postalCity: new FormControl(''),
        postalAddress: new FormControl(''),
        postalZipcode: new FormControl('', [Validators.pattern(ZIP_REGEX)]),
        companyTaxId: new FormControl('', [Validators.required, Validators.pattern(TAX_REGEXP)]),
        companyId: new FormControl('', [Validators.required]),
        ntakId: new FormControl('', [Validators.required, Validators.maxLength(50)]),
        isKpp: new FormControl('', []),
        representativeName: new FormControl('', [Validators.required]),
        representativeTitle: new FormControl('', [Validators.required]),
        typeAccommodation: new FormControl('', []),
        typeCateringUnit: new FormControl('', []),
        typeAttraction: new FormControl('', []),
        typeBathingPlace: new FormControl('', []),
        contactPersonName: new FormControl('', [Validators.required]),
        contactCountryCode: new FormControl('+', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]),
        contactPhone: new FormControl('', [Validators.required]),
        contactEmail: new FormControl('', [Validators.email, Validators.required]),
        accomodationWebPageHu: new FormControl('', [Validators.pattern(URL_REGEXP)]),
        accomodationWebPageEn: new FormControl('', [Validators.pattern(URL_REGEXP)]),
        rulesAccept: new FormControl('', [Validators.requiredTrue]),
        policyAccept: new FormControl('', [Validators.requiredTrue]),
      },
      {
        validators: this.webPageRequired,
      }
    );
  }

  webPageRequired(control: AbstractControl): ValidationErrors | null {
    const wpHu = control.get('accomodationWebPageHu').value;
    const wpEn = control.get('accomodationWebPageEn').value;
    if (wpHu === '' && wpEn === '') {
      return { eachRequired: true };
    } else {
      return null;
    }
  }

  updateCountryCode(): void {
    if (this.form.controls['contactCountryCode'].value.charAt(0) !== '+') {
      this.form.controls['contactCountryCode'].patchValue('+' + this.form.controls['contactCountryCode'].value);
    }
  }
}
