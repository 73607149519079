import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-look-around-link',
  templateUrl: './look-around-link.component.html',
  styleUrls: ['./look-around-link.component.scss'],
})
export class LookAroundLinkComponent extends BaseComponent implements OnInit {
  title: string;
  lead: string;
  buttonLabel: string;
  buttonUrl: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.title = this.componentObject.title;
    this.lead = this.componentObject.lead;
    this.buttonLabel = this.componentObject.buttonLabel;
    this.buttonUrl = this.componentObject.buttonUrl;
  }
}
