import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { TrendencyStorageService } from '../../../trendency/utils/services/storage.service';
import { environment } from '../../environments/environment';
import { TrendencyUtilsService } from '../../../trendency/utils/services/utils.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnChanges {
  @Output() openHeaderNav = new EventEmitter();
  @Input() footerData: any;

  menuWhatToDo: any;
  menuWhereToGo: any;
  menuHungaryFor: any;
  menuPlanYourTrip: any;

  currentYear = new Date().getFullYear();

  isProduction: boolean;

  showPopup = false;
  paths = [
    '/',
    '/minden-csepp-viz-uj-elmenyt-rejt',
    '/de',
    '/pl',
    '/cs',
    '/he',
    '/cs/vyrazte-letos-v-lete-k-vode',
    '/de/mach-diesen-sommer-einen-sprung-ins-kuhle-nass',
    '/make-a-splash-this-summer',
    '/pl/oddaj-sie-letniemu-szalenstwu',
    '/cs/vyrazte-letos-v-lete-k-vode',
    '/he/%D7%98%D7%91%D7%9C%D7%95-%D7%A2%D7%A6%D7%9E%D7%9B%D7%9D-%D7%91%D7%9E%D7%A2%D7%99%D7%99%D7%A0%D7%95%D7%AA-%D7%94%D7%97' +
      '%D7%9E%D7%99%D7%9D-%D7%95%D7%AA%D7%A0%D7%95-%D7%9C%D7%9E%D7%99%D7%9D-%D7%9C%D7%94%D7%A9%D7%99%D7%91-%D7%9C%D7%9B%D7%9D-%D7' +
      '%90%D7%AA-%D7%A0%D7%A2%D7%95%D7%A8%D7%99%D7%9B%D7%9D',
  ];

  constructor(private readonly utilsService: TrendencyUtilsService, private readonly storageService: TrendencyStorageService) {}

  ngOnInit(): void {
    this.isProduction = environment.production;
    if (this.utilsService.isBrowser() && this.paths.includes(window.location.pathname)) {
      setTimeout(() => {
        this.showPopup = !this.storageService.getLocalStorageData('csodas-popup-closed');
        this.storageService.setLocalStorageData('csodas-popup-closed', 'true');
      }, 3000);
    }
  }

  ngOnChanges(): void {
    if (this.footerData?.footerMenu) {
      this.menuWhatToDo = this.footerData.footerMenu.footer_what_to_do;
      this.menuWhereToGo = this.footerData.footerMenu.footer_where_to_go;
      this.menuHungaryFor = this.footerData.footerMenu.footer_hungary_for;
      this.menuPlanYourTrip = this.footerData.footerMenu.footer_plan_your_trip;
    }
    if (this.footerData?.footerContactLead) {
      this.footerData = {
        ...this.footerData,
        footerContactLead: this.footerData.footerContactLead.replace(/<a [^>]*href="mailto:[^"]+"[^>]*>.*?<\/a>/g, ''),
      };
    }
  }

  openHeaderNavContent(navId: number): void {
    this.openHeaderNav.emit(navId);
  }

  closePopup(): void {
    this.showPopup = false;
  }

  clickStop(event: Event): void {
    event.stopPropagation();
  }
}
