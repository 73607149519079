import { Directive, ViewContainerRef } from '@angular/core';

// tslint:disable-next-line: directive-selector
@Directive({ selector: '[trendencyFormControlErrorContainer]' })
export class TrendencyFormControlErrorContainerDirective {

  constructor(
    public viewContainer: ViewContainerRef
  ) { }
}
