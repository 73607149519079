<div class="page-head" *ngIf="imageUrl">
  <div [ngStyle]="{ backgroundImage: 'url(' + imageUrl + ')' }" class="page-head__image"></div>
  <div class="custom-gradient"></div>
  <div class="wrapper">
    <div class="heading">
      <h1 class="title">{{ title }}</h1>
      <span id="icon-arrow" class="icon icon-arrow-down-white"></span>
    </div>
    <div [ngClass]="'specicon ' + thematicIconKey" class="specicon"></div>
  </div>
</div>
