import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pin-checkbox',
  templateUrl: './pin-checkbox.component.html',
  styleUrls: ['./pin-checkbox.component.scss'],
})
export class PinCheckboxComponent {
  @Input() label: string;
  @Input() pinColor: string;
  @Input() checked: boolean;
  @Input() type: string;
  @Output() check: EventEmitter<any> = new EventEmitter();

  onClick(): void {
    this.check.emit();
  }
}
