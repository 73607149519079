import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-load-more-button',
  templateUrl: './load-more-button.component.html',
  styleUrls: ['./load-more-button.component.scss'],
})
export class LoadMoreButtonComponent {
  @Input() isLoaderExist: boolean;
  @Output() pageCounterEventEmitter = new EventEmitter();

  setPageCounter(): void {
    this.pageCounterEventEmitter.emit();
  }
}
