import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TrendencyUtilsService } from '../../utils/services/utils.service';

@Injectable()
export class TrendencyProgressBarService implements OnDestroy {

    private progressSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private pendingRequests: number;
    private incrementTimeout: any;

    public progress: Observable<number> = this.progressSubject.asObservable().pipe(debounceTime(0));

    constructor(
      private utilsService: TrendencyUtilsService,
    ) {
      this.pendingRequests = 0;
    }

    ngOnDestroy(): void {
      this.progressSubject.complete();
    }

    public start() {
      ++this.pendingRequests;
      const progressValue = this.progressSubject.value;
      if (progressValue === 0 || this.pendingRequests === 1) {
        this.setProgress(this.pendingRequests === 1 && progressValue > 0 ? progressValue : 2);
      }
    }

    public complete() {
      const progressValue = this.progressSubject.value;
      if (this.pendingRequests === 0 && progressValue === 0) {
        return;
      }

      if (this.pendingRequests > 0) {
        --this.pendingRequests;
      }

      if (this.pendingRequests === 0 || (this.pendingRequests === 0 && progressValue > 0)) {
        if (progressValue !== 100) {
          this.setProgress(100);
        }

        setTimeout(() => this.setProgress(0), 500);
      }
    }

    private setProgress(value: number) {
      if (!this.utilsService.isBrowser()) {
        this.pendingRequests = 0;
        return;
      }

      this.progressSubject.next(value);

      if (this.pendingRequests === 0) {
        return;
      }

      clearTimeout(this.incrementTimeout);
      const progressValue = this.progressSubject.value;
      if (progressValue > 0 && progressValue < 100) {
        this.incrementTimeout = setTimeout(() => this.increment(), 250);
      }
    }

    private increment() {
      let random = 0;
      const progressValue = this.progressSubject.value;
      if (progressValue >= 0 && progressValue < 25) {
        // Start out between 3 - 6% increments
        random = (Math.random() * (5 - 3 + 1) + 3);
      } else if (progressValue >= 25 && progressValue < 65) {
        // increment between 0 - 3%
        random = (Math.random() * 3);
      } else if (progressValue >= 65 && progressValue < 90) {
        // increment between 0 - 2%
        random = (Math.random() * 2);
      } else if (progressValue >= 90 && progressValue < 99) {
        // finally, increment it .5 %
        random = 0.5;
      } else {
        // after 99%, don't increment:
        random = 0;
      }

    this.setProgress(progressValue + random);
    }
}
