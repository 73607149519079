<div class="attraction-map-container">
  <div class="modal"></div>
  <div class="content">
    <button class="button-close-map" (click)="toggleMap()">
      <i class="icon icon-close-red"></i>
    </button>

    <div class="left">
      <div class="map-search"></div>
      <div class="filters-column">
        <div *ngIf="searchControl" class="search-box">
          <button *ngIf="filterService.hasActiveFilters()" class="clear-all-filter" (click)="clearAllFilters()">
            {{ 'label.clear-filter' | translate }}
            <i class="icon icon-close-red"></i>
          </button>
          <div class="search-inner-box">
            <i class="icon icon-search-red"></i>
            <label class="cdk-visually-hidden" for="ac-search">{{ 'label.filter-search-placeholder' | translate }}</label>
            <input
              class="text-input"
              type="search"
              placeholder="{{ 'label.filter-search-placeholder' | translate }}"
              (keydown.enter)="onSearch()"
              [formControl]="searchControl"
              id="ac-search"
            />
            <button class="icon icon-arrow-right-red submit-search-btn" (click)="onSearch()"></button>
          </div>
        </div>
        <app-collection-filters></app-collection-filters>
      </div>
    </div>
    <div class="right" [ngClass]="{ 'mid-opened': isListOpen }">
      <app-full-width-map
        [markers]="markers$ | async"
        [fullHeight]="true"
        [centerLatLng]="centerTo"
        [useInCollection]="true"
      ></app-full-width-map>
    </div>
  </div>
</div>
