<ng-container *ngIf="filterService.filters$ | async as activeFilters">
  <ng-container *ngIf="activeFilters?.suburb?.length">
    <div class="filter-box-section">
      <div class="filter-box-title">TÉRSÉG</div>
      <!-- <button class="clear-all-filter" *ngIf="filterActive(activeFilters.suburb)" (click)="onClearFilter('suburb')">
        <i class="icon icon-close-red"></i>
        {{ 'label.clear-filter' | translate }}
      </button> -->
      <ng-container *ngFor="let filterOption of activeFilters.suburb">
        <div class="option" (click)="onOptionClick(filterOption)">
          <div class="checker" [ngClass]="{ checked: filterOption.checked }">
            <div class="check"></div>
          </div>
          <span class="text">{{ filterOption.text }}</span>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <hr />

  <ng-container *ngIf="activeFilters?.city?.length">
    <div class="filter-box-section">
      <div
        class="filter-box-title dropdown"
        [ngClass]="{ 'arrow-up': isCityFilterOpen, 'arrow-down': !isCityFilterOpen }"
        (click)="isCityFilterOpen = !isCityFilterOpen"
      >
        TELEPÜLÉS
      </div>
      <!-- <button class="clear-all-filter" *ngIf="filterActive(activeFilters.city)" (click)="onClearFilter('city')">
        <i class="icon icon-close-red"></i>
        {{ 'label.clear-filter' | translate }}
      </button> -->
      <div class="options scrollable" [ngClass]="{ opened: isCityFilterOpen }">
        <ng-container *ngFor="let filterOption of activeFilters.city">
          <div class="option" (click)="onOptionClick(filterOption)">
            <div class="checker" [ngClass]="{ checked: filterOption.checked }">
              <div class="check"></div>
            </div>
            <span class="text">{{ filterOption.text }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <hr />

  <ng-container *ngIf="activeFilters?.acceptsCardType?.length">
    <div class="filter-box-section">
      <div class="filter-box-title">SZÉP-KÁRTYA</div>
      <!-- <button class="clear-all-filter" *ngIf="filterActive(activeFilters.acceptsCardType)" (click)="onClearFilter('acceptsCardType')">
        <i class="icon icon-close-red"></i>
        {{ 'label.clear-filter' | translate }}
      </button> -->
      <ng-container *ngFor="let filterOption of activeFilters.acceptsCardType">
        <div class="option" (click)="onOptionClick(filterOption)">
          <div class="checker" [ngClass]="{ checked: filterOption.checked }">
            <div class="check"></div>
          </div>
          <span class="text">{{ filterOption.text }}</span>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <hr />

  <ng-container *ngIf="activeFilters?.accomodationType?.length">
    <div class="filter-box-section">
      <div
        class="filter-box-title dropdown"
        [ngClass]="{ 'arrow-up': isAccomodationTypeFilterOpen, 'arrow-down': !isAccomodationTypeFilterOpen }"
        (click)="isAccomodationTypeFilterOpen = !isAccomodationTypeFilterOpen"
      >
        SZÁLLÁSHELY TÍPUSA
      </div>
      <!-- <button class="clear-all-filter" *ngIf="filterActive(activeFilters.accomodationType)" (click)="onClearFilter('accomodationType')">
        <i class="icon icon-close-red"></i>
        {{ 'label.clear-filter' | translate }}
      </button> -->
      <div class="options" [ngClass]="{ opened: isAccomodationTypeFilterOpen }">
        <ng-container *ngFor="let filterOption of activeFilters.accomodationType">
          <div class="option" (click)="onOptionClick(filterOption)">
            <div class="checker" [ngClass]="{ checked: filterOption.checked }">
              <div class="check"></div>
            </div>
            <span class="text">{{ filterOption.text }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <hr />

  <ng-container *ngIf="activeFilters?.accomodationClass?.length && !isOnlyNonClassifiedSelected(activeFilters)">
    <div class="filter-box-section">
      <div
        class="filter-box-title accomodation-class dropdown"
        [ngClass]="{ 'arrow-up': isAccomodationClassFilterOpen, 'arrow-down': !isAccomodationClassFilterOpen }"
        (click)="isAccomodationClassFilterOpen = !isAccomodationClassFilterOpen"
      >
        SZÁLLÁSHELY MINŐSÍTÉSE
      </div>
      <!-- <button class="clear-all-filter" *ngIf="filterActive(activeFilters.accomodationClass)" (click)="onClearFilter('accomodationClass')">
        <i class="icon icon-close-red"></i>
        {{ 'label.clear-filter' | translate }}
      </button> -->
      <div class="options" [ngClass]="{ opened: isAccomodationClassFilterOpen }">
        <ng-container *ngFor="let filterOption of activeFilters.accomodationClass; let i = index">
          <div
            class="option"
            *ngIf="
              (!isHotelSelected(activeFilters) && !isAccomodationTypeUnchecked(activeFilters) && (i + 1) % 2 !== 0) ||
              isHotelSelected(activeFilters) ||
              isAccomodationTypeUnchecked(activeFilters)
            "
            (click)="onOptionClick(filterOption)"
          >
            <div class="checker" [ngClass]="{ checked: filterOption.checked }">
              <div class="check"></div>
            </div>
            <span class="text accomodation-class">{{ accomodationClassText(filterOption.text) }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
