import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class DomainInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(
      environment.domainReplacements.reduce((req, domainReplacement) => {
        const [searchValue, replaceValue] = domainReplacement.replaces;
        return req.url.match(domainReplacement.includes) ? req.clone({ url: req.url.replace(searchValue, replaceValue) }) : req;
      }, req.clone())
    );
  }
}
