import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  private readonly isMobileMenuOpenSource$ = new BehaviorSubject<boolean>(false);
  isMobileMenuOpen$ = this.isMobileMenuOpenSource$.asObservable();

  private readonly sz2020LogoSource$ = new BehaviorSubject(false);
  sz2020Logo$ = this.sz2020LogoSource$.asObservable();

  isMobileMenuOpen(): boolean {
    return this.isMobileMenuOpenSource$.value;
  }

  toggleMobileMenu(val?: boolean): void {
    typeof val !== 'undefined' && val !== null
      ? this.isMobileMenuOpenSource$.next(val)
      : this.isMobileMenuOpenSource$.next(!this.isMobileMenuOpenSource$.value);
  }

  isSz2020LogoActive(): boolean {
    return this.sz2020LogoSource$.value;
  }

  setSz2020Logo(val: boolean): void {
    this.sz2020LogoSource$.next(val);
  }
}
