<section class="trip-info">
  <div class="wrapper">
    <div class="row">
      <div class="column col-12 col-lg-4">
        <h5 class="trip-info-label">
          {{ 'label.trip-how-long' | translate }}
        </h5>
        <div class="trip-days">{{ componentObject.howLongItTakes }}</div>
      </div>
      <div class="column col-12 col-lg-4">
        <h5 class="trip-info-label">
          {{ 'label.trip-vehicles' | translate }}
        </h5>
        <div class="trip-vehicles">
          <div class="vehicle" *ngFor="let icon of componentObject.functionalIcons">
            <i class="icon {{ 'red-' + icon.key }}"></i> {{ getIconLabel(icon.key) | translate }}
          </div>
        </div>
      </div>
      <div class="column col-12 col-lg-4">
        <h5 class="trip-info-label">
          {{ 'label.trip-tags' | translate }}
        </h5>
        <div class="filter-list">
          <div class="filter-tag purple" *ngFor="let tag of componentObject.thematicTags">{{ tag.label }}</div>
          <div class="filter-tag red" *ngFor="let tag of destinationTags">{{ tag.label }}</div>
          <div class="filter-tag blue-light" *ngFor="let tag of targetAudienceTags">{{ tag.label }}</div>
        </div>
      </div>
    </div>
  </div>
</section>
