import { Component, AfterViewInit, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BaseComponent } from '../base.component';
import { RouteMapService } from '../../services/route-map.service';
import { TrendencyUtilsService } from 'trendency/utils';
declare let $: any;

@Component({
  selector: 'app-trip-cards',
  templateUrl: './trip-cards.component.html',
  styleUrls: ['./trip-cards.component.scss'],
})
export class TripCardsComponent extends BaseComponent implements AfterViewInit, OnInit {
  @ViewChild('tripCaruselElement', { static: true }) tripCaruselElement: ElementRef;

  cards: any[];

  constructor(private readonly routeMap: RouteMapService, private readonly utilsService: TrendencyUtilsService) {
    super();
  }

  ngOnInit(): void {
    this.cards = this.componentObject.subComponents.map((card) => {
      const sc = card.subComponents && card.subComponents[0];
      return {
        ...card,
        customUrl: sc.customUrl,
        routerLink: sc.contentType ? this.routeMap.getRoute(sc) : null,
        newTab: sc.newTab,
      };
    });
  }

  ngAfterViewInit(): void {
    if (this.utilsService.isBrowser()) {
      this.initSlick();
    }
  }

  initSlick(): void {
    $(this.tripCaruselElement.nativeElement).slick({
      dots: false,
      autoplay: false,
      lazyLoad: 'ondemand',
      centerMode: false,
      slidesToShow: 4,
      speed: 200,
      autoplaySpeed: 3000,
      variableWidth: true,
      arrows: false,
      infinite: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            dots: false,
            slidesToShow: 1,
            infinite: true,
          },
        },
      ],
    });
  }
}
