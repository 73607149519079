import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApiService } from '../shared/services/api.service';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { TekaService } from '../shared/services/teka.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { RouteMapService } from '../shared/services/route-map.service';
import { ComponentMapService } from '../shared/services/component-map.service';
import { ITekaThumbnailImage } from '../shared/shared.definitions';

@Injectable({
  providedIn: 'root',
})
export class SearchService implements OnDestroy {
  private readonly searchSource$ = new BehaviorSubject<string>('');
  search$ = this.searchSource$.asObservable();

  private readonly allItemSource$ = new BehaviorSubject<number>(0);
  allItem$ = this.allItemSource$.asObservable();

  private readonly listSource$ = new BehaviorSubject<any[]>([]);
  list$ = this.listSource$.asObservable();

  private readonly listLengthSource$ = new BehaviorSubject<number>(0);
  listLength$ = this.listLengthSource$.asObservable();

  private readonly counterSource$ = new BehaviorSubject<number>(1);
  counter$ = this.counterSource$.asObservable();

  private readonly isLoaderExistSource$ = new BehaviorSubject<boolean>(false);
  isLoaderExist$ = this.isLoaderExistSource$.asObservable();

  private readonly slugSource$ = new BehaviorSubject<string>('');
  slug$ = this.slugSource$.asObservable();

  private isFetchSearchCalled = false;
  private limit = 10;

  private readonly destroy$ = new Subject<boolean>();

  constructor(
    private readonly apiService: ApiService,
    private readonly tekaService: TekaService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly localize: LocalizeRouterService,
    private readonly routeMap: RouteMapService,
    private readonly componentMapService: ComponentMapService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  fetchSearch(): void {
    if (!this.isFetchSearchCalled) {
      this.isFetchSearchCalled = true;
      this.fetchUrlParams();

      this.slug$
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          switchMap((term: string) => {
            const translatedRoute = <string[]>this.localize.translateRoute(['/', 'search']);
            this.router.navigate(translatedRoute, { queryParams: { search: this.searchSource$.getValue() } });
            return this.apiService.getSearch$(term);
          }),
          takeUntil(this.destroy$)
        )
        .subscribe((data) => {
          this.handleData(data);
        });
    }
  }

  handleSearch(): void {
    let slug = ``;
    const searchValue = this.searchSource$.getValue();
    const counterValue = this.counterSource$.getValue();

    if (searchValue) {
      slug += `${searchValue}`;
    }

    if (counterValue > 1) {
      slug += !slug.includes('?') ? '?' : '&';
      slug += `page=${counterValue}`;
    }

    this.setUrlParams();
    this.slugSource$.next(slug);
  }

  handleData(res): void {
    let card = [];
    if (res?.info) {
      if (res?.info?.limit) {
        this.limit = res.info.limit;
      }
      this.setAllItem(res?.info?.sum);
      this.isLoaderExistSource$.next(this.counterSource$.getValue() * this.limit <= this.allItemSource$.getValue());
    }

    if (res?.data?.length) {
      card = res.data.map((item) => {
        return {
          // isActive: this.isActiveItem(item),
          name: item?.entity?.label ?? '',
          description: this.getDescription(item),
          // tag: '',
          // color: '',
          // favorite: '',
          location: item?.entity?.address?.settlement_name ?? '',
          // date: this.dateLabelService.createLabel(),
          slug: this.getTranslatedSlug(item),
          image: this.getImage(item),
        };
      });

      if (this.counterSource$.getValue() === 1) {
        this.listSource$.next([...card]);
      }

      if (this.counterSource$.getValue() > 1) {
        this.listSource$.next([...this.listSource$.getValue(), ...card]);
      }

      this.listLengthSource$.next(this.listSource$.getValue().length);
    } else {
      this.listSource$.next([]);
    }
  }

  onLoadMore(): void {
    this.counterSource$.next(this.counterSource$.getValue() + 1);
    this.handleSearch();
  }

  onSearch(value: string): void {
    this.counterSource$.next(1);
    this.searchSource$.next(value);
    this.handleSearch();
  }

  fetchUrlParams(): void {
    const params: Params = this.activatedRoute.snapshot.queryParams;

    if (params.search) {
      this.onSearch(params.search);
    }
  }

  setUrlParams(): void {
    const params: Params = {};
    const search: string = this.searchSource$.value;

    if (search) {
      params.search = search;
    }

    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params });
  }

  setAllItem(value: number): void {
    this.allItemSource$.next(value);
  }

  getTranslatedSlug(item: any): string[] {
    const netaId = item?.entity['mtu-neta:neta_id'];
    const pageId = item?.entity['mtu-content:id'];

    if (!netaId && !pageId) {
      return undefined;
    }

    if (netaId) {
      return <string[]>this.localize.translateRoute(['/', 'search', netaId]);
    }

    if (pageId) {
      return this.routeMap.getRoute({
        contentPage: {
          slug: item.entity['mtu-content:page_slug'][0]['mtu-content:slug_value'],
        },
        contentType: {
          key: item?.entity['mtu-content:type'],
        },
      });
    }

    return null;
  }

  getDescription(item: any): string {
    if (item?.entity?.short_description) {
      return item?.entity?.short_description;
    }

    if (item?.entity?.page) {
      return this.componentMapService.getComponentAsObjectByType(item?.entity?.page?.data?.meta, 'Meta.Lead')?.text;
    }

    return undefined;
  }

  getImage(item: any): ITekaThumbnailImage {
    const tekaImg = this.tekaService.getThumbnailImage(item);

    if (tekaImg) {
      return tekaImg;
    }

    if (item?.entity?.page) {
      const metaImg = this.componentMapService.getComponentAsObjectByType(item?.entity?.page?.data?.meta, 'Meta.Thumbnail');
      return {
        url: metaImg?.image?.url,
        image: {
          title: metaImg?.image?.image?.title,
        },
      };
    }

    return undefined;
  }

  isActiveItem(item: any): boolean {
    const isNetaAndActive = item?.entity['mtu-neta:is_active'];
    const isPageAndActive = item?.entity['mtu-content:page_status']?.['mtu-content:is_active'];

    if (isNetaAndActive) {
      return isNetaAndActive;
    }

    if (isPageAndActive) {
      return isPageAndActive;
    }

    return undefined;
  }
}
