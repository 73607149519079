<div class="custom-form">
  <div class="container">
    <div class="row">
      <div class="col-md-10">
        <form [formGroup]="campaignForm" (ngSubmit)="onCampaignFormSubmit()">
          <h2 class="custom-form__title">Jelentkezési lap</h2>
          <p class="custom-form__subtitle">* A csillaggal jelölt mezők kitöltése kötelező</p>
          <h4>Partner adatai</h4>

          <mat-form-field trendencyFormControlErrorContainer>
            <mat-label>Partner neve *</mat-label>
            <input matInput type="text" formControlName="partnerName" autocomplete="name" [errors]="{ required: 'VALIDATION.required' }" />
          </mat-form-field>

          <h4>Székhely</h4>

          <mat-form-field trendencyFormControlErrorContainer>
            <mat-label>Település *</mat-label>
            <input
              matInput
              type="text"
              formControlName="headAddressCity"
              autocomplete="address-level1"
              [errors]="{ required: 'VALIDATION.required' }"
            />
          </mat-form-field>

          <div class="row">
            <div class="col-md-6">
              <mat-form-field trendencyFormControlErrorContainer>
                <mat-label>Utca, házszám *</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="headAddressStreet"
                  autocomplete="street-address"
                  [errors]="{ required: 'VALIDATION.required' }"
                />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field trendencyFormControlErrorContainer>
                <mat-label>Irányítószám/helyrajzi szám *</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="headAddressZipcode"
                  autocomplete="postal-code"
                  [errors]="{ required: 'VALIDATION.required', pattern: 'VALIDATION.patter.only-num-and-letters' }"
                />
              </mat-form-field>
            </div>
          </div>

          <h4>Levelezési cím <span>amennyiben eltér a székhelytől</span></h4>

          <mat-form-field>
            <mat-label>Település</mat-label>
            <input matInput type="text" formControlName="mailingAddressCity" autocomplete="address-level1" />
          </mat-form-field>

          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Utca, házszám</mat-label>
                <input matInput type="text" formControlName="mailingAddressStreet" autocomplete="street-address" />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field trendencyFormControlErrorContainer>
                <mat-label>Irányítószám/helyrajzi szám</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="mailingAddressZipcode"
                  autocomplete="postal-code"
                  [errors]="{ pattern: 'VALIDATION.patter.only-num-and-letters' }"
                />
              </mat-form-field>
            </div>
          </div>

          <h4>Cég adatai</h4>

          <div class="row">
            <div class="col-md-6">
              <mat-form-field trendencyFormControlErrorContainer>
                <mat-label>Adószám *</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="taxNumber"
                  [errors]="{ required: 'VALIDATION.required', pattern: 'VALIDATION.patter.tax' }"
                  [textMask]="{ mask: taxNumberMask, placeholderChar: '_', guide: true, showMask: false }"
                />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Nyilvántartási szám / cégjegyzék szám</mat-label>
                <input matInput type="text" formControlName="companyRegistrationNumber" />
              </mat-form-field>
            </div>
          </div>

          <h4>Képviselő adatai</h4>

          <mat-form-field trendencyFormControlErrorContainer>
            <mat-label>Név *</mat-label>
            <input
              matInput
              type="text"
              formControlName="representativeName"
              autocomplete="name"
              [errors]="{ required: 'VALIDATION.required' }"
            />
          </mat-form-field>

          <mat-form-field trendencyFormControlErrorContainer>
            <mat-label>Titulus *</mat-label>
            <input
              matInput
              type="text"
              formControlName="representativeTitle"
              autocomplete="honorific-prefix"
              [errors]="{ required: 'VALIDATION.required' }"
            />
          </mat-form-field>

          <ng-container *ngIf="serviceTypes">
            <h4>Szolgáltatás típusa</h4>

            <ng-container *ngFor="let category of serviceCategories">
              <h5>{{ category.categoryTitle }}</h5>

              <div class="custom-form__form-group">
                <ng-container *ngFor="let type of serviceTypes">
                  <div class="custom-form__checkbox" *ngIf="category.categoryUuid === type.categoryUuid">
                    <input [id]="type.title" type="checkbox" [formControlName]="type.title" />
                    <label [for]="type.title">{{ type.title }}</label>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>

          <h4>Kapcsolattartó adatai</h4>

          <mat-form-field trendencyFormControlErrorContainer>
            <mat-label>Név *</mat-label>
            <input matInput type="text" formControlName="contactName" autocomplete="name" [errors]="{ required: 'VALIDATION.required' }" />
          </mat-form-field>

          <div class="row">
            <div class="col-4 col-md-2">
              <mat-form-field trendencyFormControlErrorContainer>
                <mat-label>Előhívó szám *</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="contactCountryCode"
                  minlength="3"
                  maxlength="4"
                  autocomplete="tel-country-code"
                  (keyup)="updateCountryCode()"
                  [errors]="{
                    required: 'VALIDATION.required',
                    minlength: 'Minimum 2 karakter!',
                    maxlength: 'Maximum 4 karakter!'
                  }"
                />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field trendencyFormControlErrorContainer>
                <mat-label>Telefonszám *</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="contactPhoneNumber"
                  autocomplete="tel"
                  [errors]="{ required: 'VALIDATION.required' }"
                />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field trendencyFormControlErrorContainer>
                <mat-label>Email *</mat-label>
                <input
                  matInput
                  type="email"
                  formControlName="contactEmail"
                  [errors]="{ email: 'VALIDATION.email', required: 'VALIDATION.required' }"
                />
              </mat-form-field>
            </div>
          </div>

          <h4>Kampány hivatalos honlapján szereplő név</h4>

          <mat-form-field trendencyFormControlErrorContainer>
            <mat-label>Név *</mat-label>
            <input matInput type="text" formControlName="providerName" autocomplete="name" [errors]="{ required: 'VALIDATION.required' }" />
          </mat-form-field>

          <h4>Az ajánlat linkje</h4>

          <mat-form-field trendencyFormControlErrorContainer>
            <mat-label>Link *</mat-label>
            <input
              matInput
              type="url"
              formControlName="url"
              autocomplete="url"
              [errors]="{ required: 'VALIDATION.required', pattern: 'VALIDATION.pattern.url' }"
            />
          </mat-form-field>

          <div class="custom-form__text" *ngIf="text" [innerHTML]="text"></div>

          <div class="custom-form__form-group">
            <div class="custom-form__checkbox" trendencyFormControlErrorContainer>
              <input
                id="accept-campaign-rules"
                type="checkbox"
                tabindex="0"
                formControlName="policyAccept"
                [errors]="{ required: 'VALIDATION.required' }"
              />
              <label for="accept-campaign-rules">
                <span class="checkbox-text-highlighted">Kampány szabályzat</span>
                <span class="checkbox-text" *ngIf="policyDocText" [innerHTML]="policyDocText"></span>
                <a [href]="policyDoc" target="_blank">Kampány szabályzat</a>
              </label>
            </div>
          </div>

          <div class="custom-form__form-group">
            <div class="custom-form__checkbox" trendencyFormControlErrorContainer>
              <input
                id="accept-gdpr"
                type="checkbox"
                tabindex="0"
                formControlName="dataManagementAccept"
                [errors]="{ required: 'VALIDATION.required' }"
              />
              <label for="accept-gdpr">
                <span class="checkbox-text-highlighted">Adatkezelési tájékoztató</span>
                <span class="checkbox-text" *ngIf="dataManagementDocText" [innerHTML]="dataManagementDocText"></span>
                <a [href]="dataManagementDoc" target="_blank">Adatkezelési tájékoztató</a>
              </label>
            </div>
          </div>

          <button class="button button-primary" [appBackgroundColor]="'red'">Küldés</button>
        </form>
      </div>
    </div>
  </div>

  <app-thank-you [isShow]="formSent"></app-thank-you>
</div>
