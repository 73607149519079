<section class="title-text-content" *ngIf="componentObject?.title || componentObject?.text">
  <div class="wrapper">
    <div class="left">
      <div class="content">
        <h2 id="{{ componentObject | titleToId }}" *ngIf="componentObject?.title" tabindex="0">{{ componentObject.title }}</h2>
        <div class="content-text" [innerHTML]="componentObject.text" *ngIf="componentObject?.text" tabindex="0"></div>
        <!-- read more missing -->
      </div>
    </div>
  </div>
</section>
