import { Component } from '@angular/core';

@Component({
  selector: 'app-weather-info-static',
  templateUrl: './weather-info-static.component.html',
  styleUrls: ['./weather-info-static.component.scss'],
})
export class WeatherInfoStaticComponent {
  public onDiagramScroll(evt: Event): void {
    const targetEl = evt.target as HTMLElement;
    const overflowWidth = targetEl.scrollWidth - targetEl.offsetWidth;
    const scrollLeft = Math.round(targetEl.scrollLeft);
    if (scrollLeft === overflowWidth) {
      targetEl.classList.remove('rightBlur');
    } else {
      targetEl.classList.add('rightBlur');
    }
  }
}
