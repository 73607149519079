<app-filter-collection [filters]="activeFilters" (filterChange)="onFilterChange($event)"></app-filter-collection>
<!-- TODO: Add breadcrumbs -->
<div class="active-filter-list">
  <div class="wrapper" *ngIf="anyFilterActive()">
    <div class="filter-type">
      <ng-container *ngFor="let filterOption of activeFilters.destinations">
        <div class="filter-tag red" *ngIf="filterOption.checked">
          <button class="filter-off" (click)="removeFilterElement(filterOption)">x</button>
          {{ filterOption.text }}
        </div>
      </ng-container>
      <ng-container *ngFor="let filterOption of activeFilters.thematics">
        <div class="filter-tag purple" *ngIf="filterOption.checked">
          <button class="filter-off" (click)="removeFilterElement(filterOption)">x</button>
          {{ filterOption.text }}
        </div>
      </ng-container>
      <button *ngIf="anyFilterActive()" class="filter-clear filter-tag clear" (click)="clearFilters()">
        <i class="icon icon-close-red"></i>
        {{ 'label.clear-all-filters' | translate }}
      </button>
    </div>
  </div>
</div>
<div class="cards" *ngIf="firstLoaded">
  <div class="wrapper">
    <h2 *ngIf="articles.length === 0" class="no-results">{{ 'label.filter-no-results' | translate }}</h2>
    <span *ngIf="showAlertMessage && articles?.length !== 0" class="cdk-visually-hidden" role="alert">{{
      ('label.filter-results' | translate) + ' ' + articles.length
    }}</span>
    <span *ngIf="showAlertMessage && articles?.length === 0" class="cdk-visually-hidden" role="alert">{{
      'label.filter-no-results' | translate
    }}</span>
    <div class="row" *ngIf="articles.length !== 0" #acFilterResults>
      <div class="col-6 col-lg-4 card" *ngFor="let article of articles">
        <app-card-w-desc [isFullWidth]="true" [data]="article"></app-card-w-desc>
      </div>
    </div>
    <div class="bottom-button-box" *ngIf="articles.length !== 0">
      <button
        class="button load-button button-secondary"
        [appBackgroundColor]="'white'"
        [ngClass]="{ hidden: maxArticleCount <= articlesPerPage * limitMultiplier }"
        (click)="loadMore()"
      >
        {{ 'label.load-more' | translate }}
      </button>
    </div>
  </div>
</div>
