<section class="activites" id="{{ componentObject | titleToId }}">
  <div class="section-head margin-b">
    <h2>{{ componentObject.title }}</h2>
    <p class="desc">{{ componentObject.subtitle }}</p>
  </div>
</section>

<section class="activity-list">
  <div
    class="act-elem"
    *ngFor="let sub of componentObject.subComponents; let odd = odd"
    [appBackgroundColor]="odd ? 'light-orange' : 'light-blue-2'"
  >
    <h4 class="type">{{ sub.title }}</h4>
    <a class="name">{{ sub.lead }}</a>
    <a *ngIf="sub.buttonUrl && sub.buttonLabel" [href]="sub.buttonUrl" target="_blank" class="region">{{ sub.buttonLabel }}</a>
    <div *ngIf="sub.thematicIcon" [ngClass]="'specicon ' + sub.thematicIcon.key" class="specicon"></div>
  </div>
</section>
