<section class="simple-map">
  <app-experience-headline [title]="title" [subtitle]="subtitle"></app-experience-headline>

  <app-experience-filter
    [filters]="filters$ | async"
    [filtersOpened]="filtersOpened$ | async"
    [selectedFilterTitle]="selectedFilterTitle$ | async"
    [mapView]="mapView"
    [filterType]="filterType"
    (toggleFiltersEvent)="toggleFilters()"
    (selectFilterEvent)="selectFilter($event)"
    (toggleViewEvent)="toggleView()"
  ></app-experience-filter>

  <div class="map-container" [ngClass]="{ hidden: !mapView }">
    <app-full-width-map [markers]="markers$ | async"></app-full-width-map>
  </div>
  <div class="list-container" [ngClass]="{ hidden: mapView }">
    <div class="row element-list">
      <div class="col-12 col-md-3 element" *ngFor="let element of randomExperiences$ | async">
        <app-experience-card [card]="element"></app-experience-card>
      </div>
    </div>
  </div>

  <div class="mobile-cards">
    <a
      [routerLink]="['/' | localize, 'experiences' | localize, element.slug]"
      class="simple-map-card"
      *ngFor="let element of randomExperiences$ | async"
    >
      <div
        class="image"
        [ngStyle]="{ 'background-image': 'url(' + element?.thumbnail?.url + ')' | ssrEmptyString }"
        [attr.aria-label]="element?.thumbnail?.image?.title"
      >
        <span
          class="profile-picture"
          *ngIf="element.relation.influencer && element.relation.influencer.picture"
          [ngStyle]="{ 'background-image': 'url(' + element.relation.influencer.picture.url + ')' | ssrEmptyString }"
          [attr.aria-label]="element.relation.influencer.picture?.image?.title"
          role="img"
        ></span>
      </div>
      <div class="content">
        <div class="tag" *ngIf="element.relation.influencer && element.relation.influencer.name">
          {{ element.relation.influencer.name }}
        </div>
        <h5 class="title">{{ element.title }}</h5>
        <i class="icon icon-small icon-arrow-right-more-red icon-after"></i>
      </div>
    </a>
  </div>

  <div class="simple-map-bottom more-button desktop">
    <button class="button button-secondary" [appBackgroundColor]="'white'" [routerLink]="['/', 'experiences'] | localize">
      {{ 'label.all-recommended-place' | translate }}
    </button>
  </div>
  <div class="simple-map-bottom more-button mobile">
    <app-experience-load-more
      [experienceCounter]="experienceCounter$ | async"
      [loadMaxExperience]="loadMaxExperience"
      [loaderCounter]="loaderCounter$ | async"
      (loadMoreClickEvent)="loadMore()"
      [buttonText]="'label.all-recommended-place' | translate"
    ></app-experience-load-more>
  </div>

  <div class="influencer-container">
    <h3 class="influencer-row-title">{{ 'label.they-all-shared-their-favorite-experiences' | translate }}</h3>
    <div #influencerRow class="influencer-row">
      <ng-container *ngFor="let influencer of influencers$ | async">
        <a [routerLink]="['/' | localize, 'experiences' | localize, 'influencer', influencer.id]" class="influencer">
          <span
            class="influencer-pic"
            [ngStyle]="{ 'background-image': 'url(' + influencer.picture.url + ')' | ssrEmptyString }"
            [attr.aria-label]="influencer?.picture?.image?.title"
            role="img"
          ></span>
          <div class="influencer-name">
            {{ influencer.name }}
          </div>
        </a>
      </ng-container>
    </div>
  </div>
  <!--  <div class="simple-map-bottom more-button desktop">-->
  <!--    <button class="button button-primary">Saját élmény feltöltése</button>-->
  <!--  </div>-->
</section>
