import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DateLabelService } from '../../services/date-label.service';
import { SharedService } from '../../services/shared.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-page-head',
  templateUrl: './page-head.component.html',
  styleUrls: ['./page-head.component.scss'],
})
export class PageHeadComponent extends BaseComponent implements OnInit {
  eventDateFrom: any;
  eventDateTill: any;
  imageUrl: string;
  imageAlt: string;
  influencer: any;
  youtubeUrl: string;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly dateLabelService: DateLabelService,
    private readonly sharedService: SharedService
  ) {
    super();
  }

  ngOnInit(): void {
    this.influencer = this.extraData?.relation?.influencer;
    this.imageUrl =
      this.sharedService.isMobile() && this.componentObject?.mobileThumbnailImage?.url
        ? this.componentObject?.mobileThumbnailImage?.url
        : this.componentObject?.image?.url;
    this.imageAlt =
      this.sharedService.isMobile() && this.componentObject?.mobileThumbnailImage?.image?.title
        ? this.componentObject?.mobileThumbnailImage?.image?.title
        : this.componentObject?.image?.image?.title;
    this.youtubeUrl = !this.sharedService.isMobile() && this.sharedService.getClipFromSourceOrLink(this.componentObject?.youtubeLink);

    const data = this.activatedRoute.snapshot.data.data;
    const type = data.type;
    if (type === 'Event') {
      const eventStatus = data.data.meta.find((item) => item.type === 'Meta.Status.Event');
      if (eventStatus) {
        for (const field of eventStatus.fields) {
          if (field.key === 'eventDateFrom') {
            this.eventDateFrom = field.value !== null ? field.value : null;
          }
          if (field.key === 'eventDateTill') {
            this.eventDateTill = field.value !== null ? field.value : null;
          }
        }
      }
    }
  }

  dateLabel(date1: string, date2: string): string {
    return this.dateLabelService.createLabel(date1, date2);
  }
}
