/// <reference types="google.maps" />

import { Component, Input, ElementRef, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { TrendencyUtilsService } from 'trendency/utils';
import { takeUntil } from 'rxjs/operators';
import { TrendencyGoogleMapService } from 'trendency/utils/services/google-map.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-map-button-widget',
  templateUrl: './map-button-widget.component.html',
  styleUrls: ['./map-button-widget.component.scss'],
})
export class MapButtonWidgetComponent implements OnDestroy, AfterViewInit {
  @ViewChild('gmap') gmapElement: ElementRef;
  @Input() buttonLabel: string;
  @Input() buttonUrl: string;
  @Input() lat: string;
  @Input() lng: string;
  @Input() mapUIDisabled = true;

  public map: google.maps.Map;
  public options: google.maps.MapOptions;
  latLng: google.maps.LatLngLiteral;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly utilsService: TrendencyUtilsService, private readonly gMapService: TrendencyGoogleMapService) {}

  ngAfterViewInit(): void {
    if (this.utilsService.isBrowser()) {
      this.gMapService.initGoogle();
      this.gMapService.googleAPIisReady.pipe(takeUntil(this.destroy$)).subscribe((val) => {
        if (val) {
          this.initMap();
        }
      });
    }
  }

  private initMap(): void {
    if (this.hasMapData()) {
      const latitude = parseFloat(this.lat);
      const longitude = parseFloat(this.lng);
      this.latLng = { lat: +this.lat, lng: +this.lng };
      this.options = {
        disableDefaultUI: true,
        zoom: 10,
        center: {
          lat: latitude,
          lng: longitude,
        },
      };

      if (this.gmapElement && this.gmapElement.nativeElement) {
        this.map = new google.maps.Map(this.gmapElement.nativeElement, this.options);
      }
    }
  }

  hasMapData(): string {
    return this.lng && this.lat;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
