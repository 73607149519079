import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'titleToId',
})
export class TitleToIdPipe implements PipeTransform {
  characterMap: { [key: string]: string } = {
    // Czech
    č: 'c',
    ď: 'd',
    ě: 'e',
    ň: 'n',
    ř: 'r',
    š: 's',
    ť: 't',
    ú: 'u',
    ž: 'z',

    // German
    ä: 'ae',
    ö: 'oe',
    ü: 'ue',
    ẞ: 's',
    ß: 's',

    // Spanish
    á: 'a',
    é: 'e',
    í: 'i',
    ñ: 'n',
    ó: 'o',

    // French
    à: 'a',
    â: 'a',
    ç: 'c',
    è: 'e',
    ê: 'e',
    ë: 'e',
    î: 'i',
    ï: 'i',
    ô: 'o',
    œ: 'oe',
    ù: 'u',
    û: 'u',
    ÿ: 'y',

    // Croatian
    ć: 'c',
    đ: 'd',

    // Italian
    ì: 'i',
    ò: 'o',

    // Hungarian
    ő: 'o',

    // Turkish
    ğ: 'g',
    ı: 'i',
    ş: 's',

    // Romanian
    ă: 'a',
    ș: 's',
    ț: 't',

    // Slovak
    ĺ: 'l',
    ľ: 'l',
    ŕ: 'r',
    ý: 'y',

    // Polish
    ł: 'l',
  };
  private readonly langCodeList: string[] = ['he', 'ko', 'ru', 'cn'];

  constructor(private readonly translate: TranslateService) {}

  transform(value: Record<string, string>): string | undefined {
    if (value) {
      const valueObject = Object.assign({}, value);
      if (this.langCodeList.includes(this.translate.currentLang)) {
        if (valueObject['componentUuid']) {
          return valueObject['uuid'];
        }
        if (valueObject['uuid']) {
          return valueObject['uuid'];
        }
      }
      if (valueObject['fieldValue']) {
        return this.normalizeString(valueObject['fieldValue']);
      }
      if (valueObject['title']) {
        return this.normalizeString(valueObject['title']);
      }
      if (valueObject['titleText']) {
        return this.normalizeString(valueObject['titleText']);
      }
    }
    return undefined;
  }

  private normalizeString(inputString: string): string {
    return inputString
      .replace(/\s/g, '-')
      .toLowerCase()
      .replace(/[áäčďéèíĺľňóôöŕšťúüýžćđàìòùőçğışăâîșțěřẞñêëïœûÿ]/g, (match) => this.characterMap[match])
      .replace(/[^a-zA-Z0-9]/g, '-');
  }
}
