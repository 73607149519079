import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { fromEvent, Observable, Subject } from 'rxjs';
import { SearchService } from '../../search.service';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
})
export class SearchFormComponent implements OnInit, OnDestroy {
  @Input() style = '';
  @Input() isForNavigation: boolean;
  isSearchDropDownOpened = false;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  private readonly destroy$ = new Subject();

  constructor(
    private readonly searchService: SearchService,
    private readonly router: Router,
    private readonly localize: LocalizeRouterService
  ) {}

  ngOnInit(): void {
    fromEvent(this.searchInput.nativeElement, 'input')
      .pipe(
        // debounceTime(500),
        // distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe((e: any) => {
        this.searchService.setAllItem(null);
        this.searchService.onSearch(e.target.value);
        // if (this.isForNavigation) {
        //   this.goTo(e.target.value);
        // }
      });
  }

  get search$(): Observable<string> {
    return this.searchService.search$;
  }

  onClickCloseDropdown(): void {
    this.isSearchDropDownOpened = false;
  }

  goTo(value: string): void {
    const translatedRoute = <string[]>this.localize.translateRoute(['/', 'search']);
    this.router.navigate(translatedRoute, { queryParams: { search: value } });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
