import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable()
export class TrendencyFormService {

  constructor() { }

  // Reguláris kifejezés email formátum ellenőrzésére
  public static EMAIL_REGEXP: RegExp = new RegExp([
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@',
    '((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+',
    '[a-zA-Z]{2,}))$',
  ].join(''));

  // A FormGroup minden FormControl-ját szerkesztettnek jelöli és futtat rajtuk egy validációt
  public markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).keys(formGroup.controls)
      .map(key => formGroup.controls[key])
      .forEach((control) => {
        control.markAsTouched();
        control.markAsDirty();
        control.updateValueAndValidity();

        if (control.controls) {
          this.markFormGroupTouched(control);
        }
      });
  }
}
