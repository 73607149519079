import { NgModule } from '@angular/core';
import { TrendencyUtilsService } from './services/utils.service';
import { TrendencySeoService } from './services/seo.service';
import { TrendencyStorageService } from './services/storage.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  imports: [
    MatSnackBarModule
  ],
  exports: [],
  declarations: [],
  providers: [
    TrendencyUtilsService,
    TrendencySeoService,
    TrendencyStorageService,
  ],
})
export class TrendencyUtilsModule { }
