<div class="custom-form">
  <div class="container">
    <div class="row">
      <div class="col-md-10">
        <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
          <h2 class="custom-form__title">Jelentkezési űrlap</h2>
          <p class="custom-form__subtitle">* A csillaggal jelölt mezők kitöltése kötelező</p>

          <h4>Partner adatai</h4>

          <mat-form-field trendencyFormControlErrorContainer>
            <mat-label>Partner neve</mat-label>
            <input
              matInput
              type="text"
              formControlName="accomodationName"
              autocomplete="name"
              [errors]="{ required: 'VALIDATION.required', maxlength: 'Maximum 250 karakter!' }"
            />
          </mat-form-field>

          <h4>Székhely</h4>

          <mat-form-field trendencyFormControlErrorContainer>
            <mat-label>Település</mat-label>
            <input
              matInput
              type="text"
              formControlName="city"
              autocomplete="address-level1"
              [errors]="{ required: 'VALIDATION.required' }"
            />
          </mat-form-field>

          <div class="row">
            <div class="col-md-6">
              <mat-form-field trendencyFormControlErrorContainer>
                <mat-label>Utca, házszám</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="address"
                  autocomplete="street-address"
                  [errors]="{ required: 'VALIDATION.required' }"
                />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field trendencyFormControlErrorContainer>
                <mat-label>Irányítószám/helyrajzi szám</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="zipcode"
                  autocomplete="postal-code"
                  [errors]="{ required: 'VALIDATION.required', pattern: 'VALIDATION.patter.only-num-and-letters' }"
                />
              </mat-form-field>
            </div>
          </div>

          <h4>Levelezési cím <span>amennyiben eltér a székhelytől</span></h4>

          <mat-form-field>
            <mat-label>Település</mat-label>
            <input matInput type="text" formControlName="postalCity" autocomplete="address-level1" />
          </mat-form-field>

          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Utca, házszám</mat-label>
                <input matInput type="text" formControlName="postalAddress" autocomplete="street-address" />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field trendencyFormControlErrorContainer>
                <mat-label>Irányítószám/helyrajzi szám</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="postalZipcode"
                  autocomplete="postal-code"
                  [errors]="{ pattern: 'VALIDATION.patter.only-num-and-letters' }"
                />
              </mat-form-field>
            </div>
          </div>

          <h4>Cég adatai</h4>

          <div class="row">
            <div class="col-md-6">
              <mat-form-field trendencyFormControlErrorContainer>
                <mat-label>Adószám</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="companyTaxId"
                  [errors]="{ required: 'VALIDATION.required', pattern: 'VALIDATION.patter.tax' }"
                  [textMask]="{ mask: taxNumberMask, placeholderChar: '_', guide: true, showMask: false }"
                />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field trendencyFormControlErrorContainer>
                <mat-label>Nyilvántartási szám / cégjegyzék szám</mat-label>
                <input matInput type="text" formControlName="companyId" [errors]="{ required: 'VALIDATION.required' }" />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <mat-form-field trendencyFormControlErrorContainer>
                <mat-label>NTAK azonosító</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="ntakId"
                  [errors]="{ required: 'VALIDATION.required', maxlength: 'Maximum 50 karakter!' }"
                />
              </mat-form-field>
            </div>
            <div class="col-md-6 vertical-link">
              <a href="https://info.ntak.hu" target="_blank">NTAK információ</a>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="custom-form__checkbox" trendencyFormControlErrorContainer>
                <input id="isKpp" type="checkbox" tabindex="0" formControlName="isKpp" />
                <label for="isKpp">
                  <span class="checkbox-text">MTÜ Kiemelt Partner Program résztvevő</span>
                </label>
              </div>
            </div>
            <div class="col-md-6">
              <!-- <a href="#" target="_blank">Kiemelt Partner Program információ</a> -->
            </div>
          </div>

          <h4 [ngStyle]="{ marginTop: '35px' }">Képviselő adatai</h4>

          <mat-form-field trendencyFormControlErrorContainer>
            <mat-label>Név</mat-label>
            <input
              matInput
              type="text"
              formControlName="representativeName"
              autocomplete="name"
              [errors]="{ required: 'VALIDATION.required' }"
            />
          </mat-form-field>

          <mat-form-field trendencyFormControlErrorContainer>
            <mat-label>Titulus</mat-label>
            <input
              matInput
              type="text"
              formControlName="representativeTitle"
              autocomplete="honorific-prefix"
              [errors]="{ required: 'VALIDATION.required' }"
            />
          </mat-form-field>

          <h4>Szolgáltatás típusa</h4>

          <div class="custom-form__form-group">
            <div class="custom-form__checkbox" trendencyFormControlErrorContainer>
              <input id="typeAccommodation" type="checkbox" tabindex="0" formControlName="typeAccommodation" />
              <label for="typeAccommodation">
                <span class="checkbox-text">szálláshely</span>
              </label>
            </div>
            <div class="custom-form__checkbox" trendencyFormControlErrorContainer>
              <input id="typeCateringUnit" type="checkbox" tabindex="0" formControlName="typeCateringUnit" />
              <label for="typeCateringUnit">
                <span class="checkbox-text">vendéglátóegység</span>
              </label>
            </div>
            <div class="custom-form__checkbox" trendencyFormControlErrorContainer>
              <input id="typeAttraction" type="checkbox" tabindex="0" formControlName="typeAttraction" />
              <label for="typeAttraction">
                <span class="checkbox-text">attrakció</span>
              </label>
            </div>
            <div class="custom-form__checkbox" trendencyFormControlErrorContainer>
              <input id="typeBathingPlace" type="checkbox" tabindex="0" formControlName="typeBathingPlace" />
              <label for="typeBathingPlace">
                <span class="checkbox-text">fürdőhely</span>
              </label>
            </div>
          </div>

          <h4>Kapcsolattartó adatai</h4>

          <mat-form-field trendencyFormControlErrorContainer>
            <mat-label>Név</mat-label>
            <input
              matInput
              type="text"
              formControlName="contactPersonName"
              autocomplete="name"
              [errors]="{ required: 'VALIDATION.required' }"
            />
          </mat-form-field>

          <div class="row">
            <div class="col-4 col-md-2">
              <mat-form-field trendencyFormControlErrorContainer>
                <mat-label>Előhívó szám</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="contactCountryCode"
                  minlength="3"
                  maxlength="4"
                  autocomplete="tel-country-code"
                  (keyup)="updateCountryCode()"
                  [errors]="{
                    required: 'VALIDATION.required',
                    minlength: 'Minimum 2 karakter!',
                    maxlength: 'Maximum 4 karakter!'
                  }"
                />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field trendencyFormControlErrorContainer>
                <mat-label>Telefonszám</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="contactPhone"
                  autocomplete="tel"
                  [errors]="{ required: 'VALIDATION.required' }"
                />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field trendencyFormControlErrorContainer>
                <mat-label>Email</mat-label>
                <input
                  matInput
                  type="email"
                  formControlName="contactEmail"
                  [errors]="{ email: 'VALIDATION.email', required: 'VALIDATION.required' }"
                />
              </mat-form-field>
            </div>
          </div>

          <h4>A magyar nyelvű ajánlati oldal linkje</h4>

          <mat-form-field trendencyFormControlErrorContainer>
            <mat-label>Link</mat-label>
            <input
              matInput
              type="url"
              formControlName="accomodationWebPageHu"
              autocomplete="url"
              [errors]="{ pattern: 'VALIDATION.pattern.url' }"
            />
          </mat-form-field>

          <h4>Az angol nyelvű ajánlati oldal linkje</h4>

          <mat-form-field trendencyFormControlErrorContainer>
            <mat-label>Link</mat-label>
            <input
              matInput
              type="url"
              formControlName="accomodationWebPageEn"
              autocomplete="url"
              [errors]="{ pattern: 'VALIDATION.pattern.url' }"
            />
          </mat-form-field>
          <small class="error" *ngIf="formTryToSent && form.errors.eachRequired">{{ 'VALIDATION.eachRequired' | translate }}</small>

          <div class="custom-form__text" *ngIf="text" [innerHTML]="text"></div>

          <div class="custom-form__form-group">
            <div class="custom-form__checkbox" trendencyFormControlErrorContainer>
              <input
                id="accept-campaign-rules"
                type="checkbox"
                tabindex="0"
                formControlName="rulesAccept"
                [errors]="{ required: 'VALIDATION.required' }"
              />
              <label for="accept-campaign-rules">
                <span class="checkbox-text-highlighted">Kampány szabályzat</span>
                <span class="checkbox-text" *ngIf="dataManagementDocText" [innerHTML]="dataManagementDocText"></span>
                <a [href]="dataManagementDoc" target="_blank">Kampány szabályzat</a>
              </label>
            </div>
          </div>

          <div class="custom-form__form-group">
            <div class="custom-form__checkbox" trendencyFormControlErrorContainer>
              <input
                id="accept-campaign-policy"
                type="checkbox"
                tabindex="0"
                formControlName="policyAccept"
                [errors]="{ required: 'VALIDATION.required' }"
              />
              <label for="accept-campaign-policy">
                <span class="checkbox-text-highlighted">Adatkezelési tájékoztató</span>
                <span class="checkbox-text" *ngIf="policyDocText" [innerHTML]="policyDocText"></span>
                <a [href]="policyDoc" target="_blank">Adatkezelési tájékoztató</a>
              </label>
            </div>
          </div>

          <!--
          <div class="custom-form__form-group">
            <div class="custom-form__checkbox" trendencyFormControlErrorContainer>
              <input
                id="accept-gdpr"
                type="checkbox"
                tabindex="0"
                formControlName="dataManagementAccept"
                [errors]="{ required: 'VALIDATION.required' }"
              />
              <label for="accept-gdpr">
                <span class="checkbox-text-highlighted">Adatkezelési tájékoztató</span>
                <span class="checkbox-text" *ngIf="dataManagementDocText" [innerHTML]="dataManagementDocText"></span>
                <a [href]="dataManagementDoc" target="_blank">Adatkezelési tájékoztató</a>
              </label>
            </div>
          </div>
          -->

          <button class="button button-primary" [appBackgroundColor]="'red'">Küldés</button>
        </form>
      </div>
    </div>
  </div>

  <div class="server-error" *ngIf="formFailed">Szerver oldali hiba. Kérjük próbalja újra később.</div>

  <app-thank-you [isShow]="formSent"></app-thank-you>
</div>
