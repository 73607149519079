<section>
  <div #galleryElement class="gallery-carousel">
    <div *ngFor="let item of items">
      <div class="item">
        <span
          *ngIf="item?.image?.url"
          [ngStyle]="{ 'background-image': 'url(' + item.image.url + ')' } | ssrEmptyString"
          [attr.aria-label]="item?.image?.image?.title"
          role="img"
          class="bg"
        ></span>
        <div class="content-wrapper">
          <div class="left-content"></div>
          <div class="right-content">{{ item.title }}<i *ngIf="item.title && item.title !== ''" class="icon icon-photo"></i></div>
        </div>
      </div>
    </div>
  </div>
</section>
