import { Component } from '@angular/core';
import { DialogConfig } from '../../custom-marker-injector';

@Component({
  selector: 'app-tourinform-custom-marker',
  templateUrl: './attraction-custom-marker.component.html',
  styleUrls: ['./attraction-custom-marker.component.scss'],
})
export class AttractionCustomMarkerComponent {
  constructor(public config: DialogConfig) {}
}
