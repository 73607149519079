import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { RouteMapService } from '../../services/route-map.service';

@Component({
  selector: 'app-plan-cards',
  templateUrl: './plan-cards.component.html',
  styleUrls: ['./plan-cards.component.scss'],
})
export class PlanCardsComponent extends BaseComponent implements OnInit {
  title: string;
  subtitle: string;
  cards: any[];

  constructor(private readonly routeMap: RouteMapService) {
    super();
  }

  ngOnInit(): void {
    this.title = this.componentObject.title;
    this.subtitle = this.componentObject.subtitle;
    this.cards = this.componentObject.subComponents.map((card) => {
      const sc = card.subComponents && card.subComponents[0];
      return {
        ...card,
        customUrl: sc.customUrl,
        routerLink: this.routeMap.getRoute(sc),
        newTab: sc.newTab,
      };
    });
  }
}
