import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-campaign-title',
  templateUrl: './campaign-title.component.html',
  styleUrls: ['./campaign-title.component.scss'],
})
export class CampaignTitleComponent extends BaseComponent implements OnInit {
  title: string;
  subtitle: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.componentObject) {
      this.title = this.componentObject.titleText;
      this.subtitle = this.componentObject.subTitleText;
    }
  }
}
