import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { BaseComponent } from '../base.component';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CollectionMapService } from '../../services/collection-map.service';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { CollectionFilterService } from '../../services/collection-filter.service';

const MAX_NUM_OF_COLLECTION = 999;

@Component({
  selector: 'app-application-collection-collection',
  templateUrl: './application-collection.component.html',
  styleUrls: ['./application-collection.component.scss'],
})
export class ApplicationCollectionComponent extends BaseComponent implements OnInit, OnDestroy {
  subtitle = 'listing page subtitle';
  applications: any[];
  applicationLength: number;
  applicationMaxLength: number;
  applicationsPerPage = 12;
  limitMultiplier = 1;
  type: string;
  uuid: string;
  searchControl: FormControl;
  title: string;
  isMobile = false;
  showFilters = false;

  private readonly mapFilterHandler$ = this.collectioMapService.isMapOpen$.pipe(
    tap((isMapOpen: boolean) => {
      if (isMapOpen) {
        this.filterMapRequest();
      } else {
        this.filterRequest(false);
      }
    })
  );
  private mapFilterHandlerSub: Subscription | null = null;
  private searchControlValueSub: Subscription | null = null;

  constructor(
    private readonly apiService: ApiService,
    private readonly translateService: TranslateService,
    readonly filterService: CollectionFilterService,
    private readonly collectioMapService: CollectionMapService
  ) {
    super();
  }

  ngOnInit(): void {
    const pageTypes = [
      { key: 'contentPageOnlyCampaign', type: 'campaign' },
      { key: 'contentPageOnlyRestaurant', type: 'restaurant' },
      { key: 'contentPageOnlySummerCampaign', type: 'summer-accomodation' },
      { key: 'contentPageOnlyEverySeasonCampaign', type: 'every-season' },
    ];

    for (const pageType of pageTypes) {
      const content = this.componentObject?.[pageType.key];
      if (content?.uuid) {
        this.type = pageType.type;
        this.uuid = content.uuid;
        this.title = content.title;
        this.defaultRequest();
        break;
      }
    }

    this.checkScreenSize();

    if (this.isMobile) {
      window.addEventListener('scroll', this.preventScrollClose, true);
    }

    this.filterService.filters$.pipe(debounceTime(300)).subscribe(() => {
      if (this.collectioMapService.isMapOpenValue) {
        this.filterMapRequest();
      } else {
        this.filterRequest(false);
      }
    });

    this.searchControlValueSub = this.searchControl.valueChanges.subscribe((value) => {
      if (value === '') {
        this.onSearch();
      }
    });

    this.mapFilterHandlerSub = this.mapFilterHandler$.subscribe();
  }

  get isMapOpen$(): Observable<boolean> {
    return this.collectioMapService.isMapOpen$;
  }

  checkScreenSize(): void {
    this.isMobile = window.innerWidth <= 768;
    this.showFilters = !this.isMobile;
  }

  preventScrollClose(event: Event): void {
    if (this.showFilters) {
      event.stopPropagation();
    }
  }

  onTouchStart(event: TouchEvent): void {
    if (this.isMobile && this.showFilters) {
      event.stopPropagation();
    }
  }

  defaultRequest(): void {
    const filters = {
      search: '',
      destinations: [],
      settlements: [],
      serviceTypes: [],
      summerServices: [],
      acceptsCardType: [],
      accomodationType: [],
      accomodationClass: [],
      suburb: [],
      city: [],
    };

    if (this.type === 'summer-accomodation') {
      filters.summerServices = [
        { id: 'typeAccommodation', text: this.translateService.instant(`CAMPAIGN.type.accommodation`), checked: false },
        { id: 'typeCateringUnit', text: this.translateService.instant(`CAMPAIGN.type.cateringunit`), checked: false },
        { id: 'typeAttraction', text: this.translateService.instant(`CAMPAIGN.type.attraction`), checked: false },
        { id: 'typeBathingPlace', text: this.translateService.instant(`CAMPAIGN.type.bathingplace`), checked: false },
      ];
    }

    this.searchControl = new FormControl(filters.search);

    this.applications = [];

    this.apiService.getApplications$(this.type, this.uuid, 0, this.applicationsPerPage * this.limitMultiplier).subscribe(
      (res) => {
        if (res?.meta?.filterOptions?.destinationTag_filter) {
          Object.keys(res.meta.filterOptions?.destinationTag_filter).forEach((key) => {
            filters.destinations.push({
              id: +key,
              text: this.translateService.instant(`CAMPAIGN.destination.${res.meta.filterOptions.destinationTag_filter[key]}`),
              checked: false,
            });
          });
        }
        if (res?.meta?.filterOptions?.serviceTypes_filter) {
          Object.keys(res.meta.filterOptions?.serviceTypes_filter).forEach((key) => {
            filters.serviceTypes.push({
              id: +key,
              text: res.meta.filterOptions.serviceTypes_filter[key],
              checked: false,
            });
          });
        }

        if (res?.meta?.filterOptions?.acceptsCardType) {
          Object.keys(res.meta.filterOptions?.acceptsCardType).forEach((key) => {
            filters.acceptsCardType.push({
              id: +key,
              text: res.meta.filterOptions.acceptsCardType[key],
              checked: false,
            });
          });
        }

        if (res?.meta?.filterOptions?.accomodationType) {
          Object.keys(res.meta.filterOptions?.accomodationType).forEach((key) => {
            filters.accomodationType.push({
              id: +key,
              text: res.meta.filterOptions.accomodationType[key],
              checked: false,
            });
          });
        }

        if (res?.meta?.filterOptions?.accomodationClass) {
          Object.keys(res.meta.filterOptions?.accomodationClass).forEach((key) => {
            filters.accomodationClass.push({
              id: +key,
              text: res.meta.filterOptions.accomodationClass[key],
              checked: false,
            });
          });
        }

        if (res?.meta?.filterOptions?.suburb) {
          Object.keys(res.meta.filterOptions?.suburb).forEach((key) => {
            filters.suburb.push({
              id: +key,
              text: res.meta.filterOptions.suburb[key],
              checked: false,
            });
          });
        }

        if (res?.meta?.filterOptions?.city) {
          Object.keys(res.meta.filterOptions?.city).forEach((key) => {
            filters.city.push({
              id: key,
              text: res.meta.filterOptions.city[key],
              checked: false,
            });
          });
        }

        if (this.type === 'summer-accomodation') {
          filters.destinations.push({
            id: 0,
            text: this.translateService.instant('CAMPAIGN.destination.other'),
            checked: false,
          });
        }

        this.filterService.updateFilters(filters);

        // this.activeFilters = filters;
        this.applications = res.data;
        this.applicationMaxLength = res.meta.limit.allRowCount;
        this.applicationLength = this.applications.length;
      },
      () => {
        // TODO: add error handling?
      }
    );
  }

  public onSearch(): void {
    this.filterService.updateFilter('search', this.searchControl.value);
  }

  clearAllFilters(): void {
    this.searchControl.setValue('');
    this.filterService.resetFilters();
  }

  filterRequest(isAddition: boolean): void {
    const activeFilters = this.filterService.getFilters();

    this.apiService
      .getApplications$(
        this.type,
        this.uuid,
        this.applicationsPerPage * (this.limitMultiplier - 1),
        this.applicationsPerPage * this.limitMultiplier,
        activeFilters.search,
        activeFilters.destinations,
        activeFilters.serviceTypes,
        activeFilters.summerServices,
        activeFilters.acceptsCardType,
        activeFilters.accomodationType,
        activeFilters.accomodationClass,
        activeFilters.suburb,
        activeFilters.city
      )
      .subscribe(
        (res) => {
          if (isAddition) {
            this.applications = [...this.applications, ...res.data];
          } else {
            this.applications = res.data;
          }
          this.applicationMaxLength = res.meta.limit.allRowCount;
          this.applicationLength = this.applications.length;
        },
        () => {
          // TODO: Add error handling?
        }
      );
  }

  filterMapRequest(): void {
    const activeFilters = this.filterService.getFilters();

    this.apiService
      .getApplications$(
        this.type,
        this.uuid,
        0,
        MAX_NUM_OF_COLLECTION,
        activeFilters.search,
        activeFilters.destinations,
        activeFilters.serviceTypes,
        activeFilters.summerServices,
        activeFilters.acceptsCardType,
        activeFilters.accomodationType,
        activeFilters.accomodationClass,
        activeFilters.suburb,
        activeFilters.city
      )
      .subscribe(
        (res) => {
          this.applications = res.data;
          this.applicationMaxLength = res.meta.limit.allRowCount;
          this.applicationLength = this.applications.length;
        },
        () => {
          // TODO: Add error handling?
        }
      );
  }

  loadMore(): void {
    this.limitMultiplier += 1;
    this.filterRequest(true);
  }

  resetLimitMultiplier(): void {
    this.limitMultiplier = 1;
  }

  toggleMap(): void {
    this.collectioMapService.toggleMap();
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnDestroy(): void {
    if (this.mapFilterHandlerSub) {
      this.mapFilterHandlerSub.unsubscribe();
    }
    if (this.searchControlValueSub) {
      this.searchControlValueSub.unsubscribe();
    }

    if (this.isMobile) {
      window.removeEventListener('scroll', this.preventScrollClose, true);
    }
  }
}
