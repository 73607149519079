import { Component, AfterViewInit, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { BaseComponent } from '../base.component';
import { RouteMapService } from '../../services/route-map.service';
import { TrendencyUtilsService } from 'trendency/utils';
import { SharedService } from '../../services/shared.service';
import { TranslateService } from '@ngx-translate/core';

declare let $: any;

@Component({
  selector: 'app-bigpic-carousel',
  templateUrl: './bigpic-carousel.component.html',
  styleUrls: ['./bigpic-carousel.component.scss'],
})
export class BigpicCarouselComponent extends BaseComponent implements AfterViewInit, OnInit {
  @ViewChild('bigpicSlideElement', { static: true }) bigpicSlideElement: ElementRef;

  @Input() headEnabled = true;
  @Input() extraMobileMargin = false;
  slides: any[];

  constructor(
    private readonly routeMap: RouteMapService,
    private readonly utilsService: TrendencyUtilsService,
    private readonly sharedService: SharedService,
    private readonly translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.slides = this.componentObject.subComponents.map((slide) => {
      const sc = slide.subComponents && slide.subComponents[0];
      return {
        ...slide,
        image: this.getImage(slide),
        routerLink: this.routeMap.getRoute(sc),
      };
    });
  }

  ngAfterViewInit(): void {
    if (this.utilsService.isBrowser()) {
      this.initSlick();
    }
  }

  initSlick(): void {
    const nextLabel = this.translateService.instant('label.next');
    const prevLabel = this.translateService.instant('label.prev');
    $(this.bigpicSlideElement.nativeElement)
      .not('.slick-initialized')
      .slick({
        dots: true,
        autoplay: true,
        lazyLoad: 'ondemand',
        centerMode: true,
        slidesToShow: 1,
        speed: 200,
        autoplaySpeed: 3000,
        variableWidth: true,
        infinite: true,
        pauseOnHover: true,
        pauseOnDotsHover: true,
        nextArrow: `<button class="slick-next slick-arrow" aria-label="${nextLabel}" type="button">${nextLabel}</button>`,
        prevArrow: `<button class="slick-prev slick-arrow" aria-label="${prevLabel}" type="button">${prevLabel}</button>`,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              infinite: true,
            },
          },
        ],
      });
  }

  private getImage(slide): any {
    const mobileImage = slide.mobileThumbnailImage;
    return this.sharedService.isMobile() && mobileImage ? mobileImage : slide.image;
  }
}
