<section id="{{ componentObject | titleToId }}">
  <div class="section-head">
    <h2>{{ hashtag }}</h2>
    <p class="desc">{{ title }}</p>
  </div>

  <div class="post-list" #crowdRiffScriptContainer></div>

  <div class="bottom-button-container" *ngIf="buttonLink">
    <a [href]="buttonLink" target="_blank">
      <button class="button button-primary" [appBackgroundColor]="'red'">@magyarország</button>
    </a>
  </div>
</section>
