import { Component, OnInit, OnDestroy, } from '@angular/core';
import { Subject } from 'rxjs';
import { TrendencyFullscreenLoaderService } from '../../services/fullscreen-loader.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'corporate-fullscreen-loader',
  templateUrl: './fullscreen-loader.component.html',
  styleUrls: ['./fullscreen-loader.component.scss']
})

export class TrendFullscreenLoaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public showLoader: boolean;

  constructor(
    private readonly fullscreenLoaderService: TrendencyFullscreenLoaderService,
  ) {
  }

  ngOnInit(): void {
    this.fullscreenLoaderService.showLoader
      .pipe(takeUntil(this.destroy$))
      .subscribe(show => {
        this.showLoader = show;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public show(): void {
    this.fullscreenLoaderService.show();
  }

  public hide(): void {
    this.fullscreenLoaderService.hide();
  }
}
