<div [ngClass]="['top-search', style]">
  <div class="wrapper">
    <h1 class="page-title" *ngIf="style !== 'footer'">{{ 'SEARCH.search-title' | translate }}</h1>
    <h1 class="page-title" *ngIf="style && style === 'footer'">Didn't find what you were looking for?</h1>

    <form class="input-wrapper" (mouseleave)="onClickCloseDropdown()">
      <i class="icon icon-search-black"></i>
      <input
        #searchInput
        type="text"
        [value]="search$ | async"
        [placeholder]="'SEARCH.keyword' | translate"
        [attr.aria-label]="'SEARCH.keyword' | translate"
      />
      <button
        [routerLink]="['/', 'search' | localize]"
        [queryParams]="{ search: search$ | async }"
        class="button button-primary"
        [appBackgroundColor]="'red'"
      >
        {{ 'SEARCH.search' | translate }}
      </button>
      <div class="dropdown-box" [ngClass]="{ opened: isSearchDropDownOpened }">
        <div class="col-lg-6">
          <div class="column-title">{{ 'SEARCH.suggested-keywords' | translate }}:</div>
          <div class="list cities">
            <button><i class="icon icon-pin-black"></i> <b>Vis</b>egrad</button>
          </div>
          <div class="list">
            <button>Castle of <b>Vis</b>egrad</button>
            <button><b>Vis</b>iting centre of Gyula</button>
            <button><b>Vis</b>iting centre of Tokaj</button>
            <button>Tourinform <b>Vis</b>egrad</button>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="column-title">{{ 'SEARCH.suggested-articles' | translate }}:</div>

          <button class="button-with-image">
            <div
              class="image"
              [ngStyle]="{ 'background-image': 'url(\'assets/images/placeholders/7.webp\')' } | ssrEmptyString"
              corporateImageLazyLoad
            ></div>
            <div class="content">
              <div class="tag">Castle & Palaces</div>
              <div class="title">Castle of Visegrad</div>
            </div>
          </button>
          <button class="button-with-image">
            <div
              class="image"
              [ngStyle]="{ 'background-image': 'url(\'assets/images/placeholders/6.webp\')' } | ssrEmptyString"
              corporateImageLazyLoad
            ></div>
            <div class="content">
              <div class="tag">Getting around</div>
              <div class="title">Tourinform Visegrad</div>
            </div>
          </button>
          <button class="button-with-image">
            <div
              class="image"
              [ngStyle]="{ 'background-image': 'url(\'assets/images/placeholders/3.webp\')' } | ssrEmptyString"
              corporateImageLazyLoad
            ></div>
            <div class="content">
              <div class="tag">Event</div>
              <div class="title">Castle of Easter egg hunting in the castle</div>
            </div>
          </button>
          <button class="button-with-image">
            <div
              class="image"
              [ngStyle]="{ 'background-image': 'url(\'assets/images/placeholders/2.webp\')' } | ssrEmptyString"
              corporateImageLazyLoad
            ></div>
            <div class="content">
              <div class="tag">Event</div>
              <div class="title">Castle on the hill - Historical games for children</div>
            </div>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
