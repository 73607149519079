<section #anchorBlock class="anchor-block" [ngClass]="{ sticky: isSticky }" [ngStyle]="{ height: componentOffsetHeight + 'px' }">
  <div
    #anchorBlockContent
    class="anchor-block-content {{ rowColor }}"
    [ngStyle]="{ top: headerOffsetHeight + 'px' }"
    [appBackgroundColor]="rowColor"
  >
    <button class="topic-button selectlike-button white type-b" (click)="toggleList()" [ngClass]="{ active: listOpen }">
      <i class="icon icon-calendar-white hidden-desktop"></i>
      {{ 'label.title-topics' | translate }}
    </button>
    <div class="wrapper" [ngClass]="{ open: listOpen }" [ngStyle]="{ 'max-width': headerOffsetWidth + 'px' }">
      <div class="title-row">
        <ng-container *ngFor="let title of componentObject.anchors">
          <a class="link" (click)="scrollTo(title)">
            <span class="title-text">{{ title.fieldValue }}</span>
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</section>
