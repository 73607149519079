<div
  class="opening"
  *ngIf="
    this.openingFormatted.hours.length ||
    (this.opening?.opening_state && openingState?.to && openingState?.from) ||
    this.opening?.temporary_closed ||
    this.opening?.planned_reopening ||
    this.opening?.inactive_reason !== null
  "
>
  <div class="opening__title">{{ 'label.open-hours' | translate }}:</div>

  <!-- opening -->
  <div class="opening_days" *ngIf="this.openingFormatted.hours?.length && !this.openingFormatted.nonstop">
    <div class="opening__day" *ngFor="let day of openingFormatted.hours">
      <span *ngIf="day?.day_of_week">{{ 'label.' + day.day_of_week | translate }}: </span>
      <span *ngIf="!day?.open && !day?.closed">{{ 'label.closed' | translate }}</span>
      <span *ngIf="day?.open">{{ formatTime(day.open) }}</span>
      <span *ngIf="day?.open && day?.closed"> - </span>
      <span *ngIf="day?.closed">{{ formatTime(day.closed) }}</span>
    </div>
  </div>

  <div class="opening_days" *ngIf="this.openingFormatted.hours?.length === 0 && this.openingFormatted.nonstop">
    <div class="opening__day">
      <span>{{ 'label.alldays' | translate }}:&nbsp;</span>
      <span>00:00 - 24:00</span>
    </div>
  </div>

  <!-- opening from - to -->
  <div
    class="opening__comment"
    *ngIf="this.opening?.opening_state && openingState?.to && openingState?.from && !isFromJan1ToDec31(openingState.from, openingState.to)"
  >
    {{
      'label.opening-season'
        | translate
          : {
              from: getDateWithoutYear(dateLabel(openingState.from)),
              to: getDateWithoutYear(dateLabel(openingState.to))
            }
    }}
  </div>

  <!-- temp closed -->
  <div class="opening__comment" *ngIf="this.opening?.temporary_closed">
    {{ 'label.temporary-closed' | translate: { date: dateLabel(this.opening.planned_reopening) } }}
  </div>

  <!-- inactive reason -->
  <div class="opening__comment" *ngIf="this.opening?.inactive_reason !== null">
    {{ this.opening.inactive_reason }}
  </div>
</div>
