import { NgModule, ModuleWithProviders } from '@angular/core';
import { TranslateModule, MissingTranslationHandler } from '@ngx-translate/core';
import { TrendencyMissingTranslationHandler, TrendencyMissingTranslationService } from './translate-loaders';
import { Routes } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';

@NgModule({
  imports: [
    TranslateModule.forChild({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: TrendencyMissingTranslationHandler,
        deps: [TrendencyMissingTranslationService]
      },
      useDefaultLang: false,
    }),
    LocalizeRouterModule
  ],
  exports: [
    TranslateModule,
    LocalizeRouterModule
  ],
})
export class TrendencyTranslationsChildModule {
  static forChild(routes: Routes): ModuleWithProviders<TrendencyTranslationsChildModule> {
    return {
      ngModule: TrendencyTranslationsChildModule,
      providers: [
        LocalizeRouterModule.forChild(routes).providers
      ]
    };
  }
}
