import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value) {
      const format = args[0] || 'YYYY. MMMM DD.';
      const timeZone = args[1] || 'Europe/Budapest';
      const formattedValue = moment.utc(value).tz(timeZone).format(format);
      return formattedValue;
    }
    return value;
  }
}
