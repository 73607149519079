import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-image-and-text',
  templateUrl: './image-and-text.component.html',
  styleUrls: ['./image-and-text.component.scss'],
})
export class ImageAndTextComponent extends BaseComponent implements OnInit {
  imageOnLeft: boolean;
  title: string;
  lead: string;
  image;
  buttonUrl: string;
  buttonLabel: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.title = this.componentObject.title ? this.componentObject.title : '';
    this.lead = this.componentObject.lead ? this.componentObject.lead : '';
    this.image = this.componentObject.image ? this.componentObject.image : undefined;
    this.buttonUrl = this.componentObject.buttonUrl ? this.componentObject.buttonUrl : undefined;
    this.buttonLabel = this.componentObject.buttonLabel ? this.componentObject.buttonLabel : undefined;
    this.imageOnLeft = this.componentObject.leftRightPosition;
  }
}
