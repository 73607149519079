import { Component } from '@angular/core';
import { DialogConfig } from '../../custom-marker-injector';

@Component({
  selector: 'app-tourinform-custom-marker',
  templateUrl: './tourinform-custom-marker.component.html',
  styleUrls: ['./tourinform-custom-marker.component.scss'],
})
export class TourinformCustomMarkerComponent {
  constructor(public config: DialogConfig) {}
}
