import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ILatLng } from '../../shared.definitions';
import { CollectionMapService } from '../../services/collection-map.service';
import { CollectionCardComponent } from '../collection-card/collection-card.component';
import { FormControl } from '@angular/forms';
import { CollectionFilterService } from '../../services/collection-filter.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-collection-map',
  templateUrl: './collection-map.component.html',
  styleUrls: ['./collection-map.component.scss'],
})
export class CollectionMapComponent implements OnChanges, OnInit, OnDestroy {
  @Input() cards: any[];
  @Input() searchControl: FormControl;
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  isListOpen = true;
  map: google.maps.Map;
  options: google.maps.MapOptions;
  markers: google.maps.Marker[] = [];
  markers$ = new BehaviorSubject([]);
  centerTo: ILatLng;
  searchControlValueSub: Subscription | null = null;

  constructor(private readonly collectionMapService: CollectionMapService, private readonly filterService: CollectionFilterService) {}

  ngOnChanges(): void {
    this.addMarkers();
  }

  ngOnInit(): void {
    if (!this.searchControl) return;
    this.searchControlValueSub = this.searchControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value === '') {
        this.onSearch();
      }
    });
  }

  get search$(): Observable<string> {
    return this.collectionMapService.search$;
  }

  get allItemsLength$(): Observable<number> {
    return this.collectionMapService.allItemsLength$;
  }

  get isLoaderExist$(): Observable<boolean> {
    return this.collectionMapService.isLoaderExist$;
  }

  get cardsLength$(): Observable<number> {
    return this.collectionMapService.queryLength$;
  }

  get isClearFilterActive$(): Observable<boolean> {
    return this.collectionMapService.isClearFilterActive$;
  }

  setPageCounter(): void {
    this.collectionMapService.setPageCounter();
  }

  addMarkers(): void {
    const markers = [];
    this.removeMarkers();

    if (this.cards?.length) {
      this.cards.forEach((item) => {
        const lat = item.location?.lat ?? null;
        const lng = item.location?.lng ?? null;

        if (lat && lng) {
          markers.push({
            coordinate: { lat: +lat, lng: +lng },
            component: CollectionCardComponent,
            data: {
              card: item,
              isMapView: true,
            },
          });
        }
      });
      this.markers$.next(markers);
    }
  }

  removeMarkers(): void {
    this.markers$.next([]);
  }

  toggleMap(): void {
    this.collectionMapService.toggleMap();
  }

  toggleList(): void {
    this.isListOpen = !this.isListOpen;
  }

  onCenterEventEmitter(val): void {
    this.centerTo = val;
  }

  onAllItemClick(): void {
    this.collectionMapService.getAllItem();
  }

  public onSearch(): void {
    this.filterService.updateFilter('search', this.searchControl.value);
  }

  clearAllFilters(): void {
    this.searchControl.setValue('');
    this.filterService.resetFilters();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
