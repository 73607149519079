<div class="cookie-bar" *ngIf="!cookieState">
  <div class="wrapper">
    <div class="column-a">
      <img src="/assets/images/icon-cookiebar.svg" alt="" corporateImageLazyLoad />
    </div>
    <div class="column-b">
      Ez a weboldal sütiket használ. Az Elfogadom gomb megnyomásával hozzájárul a sütik használatához.
      <a [routerLink]="['/', 'sutik']" class="link">Több információ <i class="icon icon-arrow-right-more-red"></i></a>
    </div>

    <div class="column-d">
      <button class="button button-primary" [appBackgroundColor]="'red'" (click)="accept()">{{ 'COOKIE.accept' | translate }}</button>
      <button class="button button-secondary" (click)="decline()">{{ 'COOKIE.decline' | translate }}</button>
    </div>
  </div>
</div>
