import { TitleTextComponent } from './components/title-text/title-text.component';
import { TextComponent } from './components/text/text.component';
import { PageHeadComponent } from './components/page-head/page-head.component';
import { BigpicCarouselComponent } from './components/bigpic-carousel/bigpic-carousel.component';
import { MoveAroundComponent } from './components/move-around/move-around.component';
import { SeasonCardsComponent } from './components/season-cards/season-cards.component';
import { PlanCardsComponent } from './components/plan-cards/plan-cards.component';
import { TripCardsComponent } from './components/trip-cards/trip-cards.component';
import { MapComponent } from './components/map/map.component';
import { MainCarouselComponent } from './components/main-carousel/main-carousel.component';
import { ArticleLeadComponent } from './components/article-lead/article-lead.component';
import { ShareBarComponent } from './components/share-bar/share-bar.component';
import { InfoCardsComponent } from './components/info-cards/info-cards.component';
import { DetailedSliderComponent } from './components/detailed-slider/detailed-slider.component';
import { ContentImageComponent } from './components/content-image/content-image.component';
import { GalleryCarouselComponent } from './components/gallery-carousel/gallery-carousel.component';
import { TripInfoComponent } from './components/trip-info/trip-info.component';
import { TripDayComponent } from './components/trip-day/trip-day.component';
import { ActivitiesComponent } from './components/activities/activities.component';
import { LookAroundLinkComponent } from './components/look-around-link/look-around-link.component';
import { TripCollectionComponent } from './components/trip-collection/trip-collection.component';
import { ArticleCollectionComponent } from './components/article-collection/article-collection.component';
import { CenteredCarouselComponent } from './components/centered-carousel/centered-carousel.component';
import { EventCollectionComponent } from './components/event-collection/event-collection.component';
import { FindAnExperienceComponent } from './components/find-an-experience/find-an-experience.component';
import { LocationComponent } from './components/location/location.component';
import { ImageAndTextComponent } from './components/image-and-text/image-and-text.component';

import { PracticalInfoComponent } from './components/practical-info/practical-info.component';
import { ApplicationCollectionComponent } from './components/application-collection/application-collection.component';
import { AnchorBlockComponent } from './components/anchor-block/anchor-block.component';
import { InstagramBlockComponent } from './components/instagram-block/instagram-block.component';
import { PageHeadNewComponent } from './components/page-head-new/page-head-new.component';
import { CampaignFormComponent } from './components/campaign-form/campaign-form.component';
import { CampaignTitleComponent } from './components/campaign-title/campaign-title.component';
import { ButtonComponent } from './components/button/button.component';
import { DetailsComponent } from './components/details/details.component';
import { WeatherInfoStaticComponent } from './components/weather-info-static/weather-info-static.component';
import { RefugeeFormComponent } from './components/refugee-form/refugee-form.component';
import { RestaurantCampaignFormComponent } from './components/restaurant-campaign-form/restaurant-campaign-form.component';
import { SummerCampaignFormComponent } from './components/summer-campaign-form/summer-campaign-form.component';
import { VideoPlayerNewComponent } from './components/video-player-new/video-player-new.component';
import { PodcastComponent } from './components/podcast/podcast.component';

export const COMPONENT_MAP = {
  'Body.TitleText': TitleTextComponent,
  'Body.Text': TextComponent,
  'Body.ContentHero': PageHeadComponent,
  'Body.Group.Carousel.Row': BigpicCarouselComponent,
  'Body.Group.MoveAround.Row': MoveAroundComponent,
  'Body.Group.SeasonHighlight.Row': SeasonCardsComponent,
  'Body.Group.PlanYourTrip.Row': PlanCardsComponent,
  'Body.Group.ItineraryTip.Row': TripCardsComponent,
  'Body.DestinationMap': MapComponent,
  'Body.Group.Discovery.Row': MainCarouselComponent,
  'Body.LeadMap': ArticleLeadComponent,
  'Body.Group.CategoryBox.Row': InfoCardsComponent,
  'Body.TakeLookAround': LookAroundLinkComponent,
  'Body.Group.YouMayAlsoLikeSelect.Row': DetailedSliderComponent,
  'Body.ShareBar': ShareBarComponent,
  'Body.Group.Gallery.ImageBox': ContentImageComponent,
  'Body.Gallery': GalleryCarouselComponent,
  'Body.TripInfoHeader': TripInfoComponent,
  'Body.Group.TripDay.Day': TripDayComponent,
  'Body.DuringYourVisit': ActivitiesComponent,
  'Body.Collection.TrippCollection': TripCollectionComponent,
  'Body.Collection.ArticleCollection': ArticleCollectionComponent,
  'Body.Collection.EventCollection': EventCollectionComponent,
  'Body.Group.ThingsToDo.Row': CenteredCarouselComponent,
  'Body.FindAnExperience': FindAnExperienceComponent,
  'Body.ExperienceMap': LocationComponent,
  'Body.TextWithImage': ImageAndTextComponent,
  'Body.PracticalInfo': PracticalInfoComponent,
  'Body.Collection.ApplicationCollection': ApplicationCollectionComponent,
  'Body.Collection.RestaurantCollection': ApplicationCollectionComponent,
  'Body.Collection.SummerCampaignCollection': ApplicationCollectionComponent,
  'Body.Collection.EverySeasonCampaignCollection': ApplicationCollectionComponent,
  'Body.AnchorBlock': AnchorBlockComponent,
  'Body.InstagramFeed': InstagramBlockComponent,
  'Body.HungaryForHero': PageHeadNewComponent,
  'Body.CampaignForm': CampaignFormComponent,
  'Body.CampaignTitle': CampaignTitleComponent,
  'Body.CampaingButton': ButtonComponent,
  'Body.Button': ButtonComponent,
  'Body.Details': DetailsComponent,
  'Body.WeatherInfoEmptyComponent': WeatherInfoStaticComponent,
  'Body.Media': VideoPlayerNewComponent,
  'Body.MediaSimple': VideoPlayerNewComponent,
  'Body.WarzoneRefugeeAccomodationTitle': CampaignTitleComponent,
  'Body.WarzoneRefugeeAccomodationForm': RefugeeFormComponent,
  'Body.WarzoneRefugeeAccomodationButton': ButtonComponent,
  'Body.SummerCampaignTitle': CampaignTitleComponent,
  'Body.SummerCampaignForm': SummerCampaignFormComponent,
  'Body.SummerCampaignButton': ButtonComponent,
  'Body.RestaurantTitle': CampaignTitleComponent,
  'Body.RestaurantForm': RestaurantCampaignFormComponent,
  'Body.RestaurantButton': ButtonComponent,
  'Body.Podcast': PodcastComponent,
  'Body.Group.DestinationMap.Row': MapComponent,

  /*
  'Body.Image1080x667': ImageComponent,
  'Body.Image1080x1080': ImageComponent,
  'Body.ImageCollection': GridImagesComponent,
  'Body.GalleryOne': SlickSlideComponent,
  'Body.GalleryThree': GalleryThreeComponent,
  'Body.PartnerBox': OurPartnersComponent,
  'Body.Text': TextComponent,
  'Body.TitleText': TitleTextComponent,
  'Body.TextboxGray': TextboxGrayComponent,
  'Body.Video': VideoComponent,
  'Body.TaggedEventBox': EventsAndPublicationsComponent,
  'Body.Join': JoinComponent,
  'Body.TextboxBlackBorder': TextboxBlackBorderComponent,

  'Widget.RelatedDocumentsBox': RelatedDocumentsComponent,
  'Widget.GrayBox': SidebarGrayBoxComponent,
  'Widget.EventSignUp': SidebarSignUpComponent,
  'Widget.RelatedSites': RelatedSitesComponent,
  'Widget.FollowUs': SidebarFollowUsComponent,
  'Widget.FollowUsSocial': SidebarFollowUsComponent,
  'Widget.Newsletter': SidebarNewsletterComponent
  */
};
