import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { RouteMapService } from '../../services/route-map.service';
import { ITagObject } from '../../shared.definitions';

@Component({
  selector: 'app-card-w-desc',
  templateUrl: './card-w-desc.component.html',
  styleUrls: ['./card-w-desc.component.scss'],
})
export class CardWDescComponent implements OnInit {
  @Input() data: any;
  @Input() isFullWidth = false;
  @Input() isSlider = false;
  isLoggedIn = false;
  isAlreadyFav = false;
  tag: ITagObject;

  constructor(private readonly sharedService: SharedService, private readonly routeMap: RouteMapService) {}

  ngOnInit(): void {
    this.tag = this.sharedService.getTagColorAndValue(this.data);
    const sc = this.data && this.data.subComponents && this.data.subComponents[0];
    if (sc) {
      this.data = {
        ...this.data,
        customUrl: sc.customUrl,
        routerLink: this.routeMap.getRoute(sc),
        newTab: sc.newTab,
      };
    }
  }

  favButtonAction(): void {
    if (this.isLoggedIn) {
      if (this.isAlreadyFav) {
        this.setActiveDeleteElementPopup(true);
      } else {
        console.log('TODO Add to fav');
      }
    } else {
      this.setActiveLoginPopup(true);
    }
  }

  setActiveLoginPopup(active: boolean): void {
    this.sharedService.setActiveLoginPopup(active);
  }

  setActiveDeleteElementPopup(active: boolean): void {
    this.sharedService.setActiveDeleteElementPopup(active);
  }
}
