import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { ApiService } from '../../services/api.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormService } from '../../services/form.service';

@Component({
  selector: 'app-restaurant-campaign-form',
  templateUrl: './restaurant-campaign-form.component.html',
  styleUrls: ['../campaign-form/campaign-form.component.scss'],
})
export class RestaurantCampaignFormComponent extends BaseComponent implements OnInit {
  dataManagementDoc: string;
  dataManagementDocText: string;
  policyDoc: string;
  policyDocText: string;
  text: string;
  formSent = false;
  form: FormGroup;
  pageUuid: string;
  taxNumberMask: (string | RegExp)[];

  constructor(private readonly apiService: ApiService, private readonly route: ActivatedRoute, private readonly formService: FormService) {
    super();
    this.taxNumberMask = this.formService.taxNumberMask;
  }

  ngOnInit(): void {
    this.pageUuid = this.route.snapshot.data.data.uuid;

    if (this.componentObject) {
      this.text = this.componentObject.text;
      this.dataManagementDocText = this.componentObject.dataManagementDocText;
      this.policyDocText = this.componentObject.policyDocText;

      if (this.componentObject.dataManagementDoc) {
        this.apiService.getFile$(this.componentObject.dataManagementDoc.uuid).subscribe((res) => (this.dataManagementDoc = res.path));
      }

      if (this.componentObject.policyDoc) {
        this.apiService.getFile$(this.componentObject.policyDoc.uuid).subscribe((res) => (this.policyDoc = res.path));
      }
    }

    this.initFormGroup();
  }

  onFormSubmit(): void {
    if (this.form.valid) {
      this.apiService.getRestaurantForm$().subscribe((res) => {
        res.data = res.data.map((field) => {
          field.value = this.form.value[field.key];
          if (field.key === 'contactPhone') {
            field.value = this.form.value['contactCountryCode'] + this.form.value[field.key];
          }
          return field;
        });

        this.apiService
          .postRestaurantForm$(this.pageUuid, res)
          /*eslint-disable rxjs/no-nested-subscribe*/
          .subscribe(() => (this.formSent = true));
      });
    }
  }

  initFormGroup(): void {
    const URL_REGEXP = '(https?://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    const ZIP_REGEX = /^([a-zA-Z0-9 .:\\/_-]+)$/;

    this.form = new FormGroup({
      partnerName: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      zipcode: new FormControl('', [Validators.required, Validators.pattern(ZIP_REGEX)]),
      contactName: new FormControl('', [Validators.required]),
      contactEmail: new FormControl('', [Validators.email, Validators.required]),
      contactCountryCode: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(3)]),
      contactPhoneNumber: new FormControl('', [Validators.required]),
      url: new FormControl('', [Validators.required, Validators.pattern(URL_REGEXP)]),
      dataManagementAccept: new FormControl('', [Validators.requiredTrue]),
      policyAccept: new FormControl('', [Validators.requiredTrue]),
    });
  }
}
