import { Component, OnInit, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { BaseComponent } from '../base.component';
import { TrendencyUtilsService } from 'trendency/utils';
declare let $: any;

@Component({
  selector: 'app-move-around',
  templateUrl: './move-around.component.html',
  styleUrls: ['./move-around.component.scss'],
})
export class MoveAroundComponent extends BaseComponent implements AfterViewInit, OnInit {
  @Input() data: any = require('../../../../assets/mock_jsons/cards-h.json');
  @ViewChild('moveAroundObjElement') moveAroundObjElement: ElementRef;
  view = '';
  thematicIconKey: string;
  mapIconKey: string;
  image: any;

  @Input() mapClass: string;
  constructor(private readonly utilsService: TrendencyUtilsService) {
    super();
  }

  ngOnInit(): void {
    this.thematicIconKey = this.componentObject?.thematicIcon?.key;
    this.mapIconKey = this.componentObject?.mapIcon?.key;
    this.image = this.componentObject?.image;
  }

  ngAfterViewInit(): void {
    if (this.utilsService.isBrowser()) {
      this.initSlick();
    }
  }

  initSlick(): void {
    if (this.moveAroundObjElement && this.moveAroundObjElement.nativeElement) {
      $(this.moveAroundObjElement.nativeElement).slick({
        dots: true,
        autoplay: false,
        lazyLoad: 'ondemand',
        centerMode: true,
        slidesToShow: 1,
        speed: 200,
        autoplaySpeed: 3000,
        variableWidth: true,
        arrows: false,
        infinite: true,
        accessibility: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              dots: false,
              centerMode: false,
            },
          },
        ],
      });
    }
  }
}
