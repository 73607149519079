import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class DateLabelService {
  private readonly timeZone = 'Europe/Budapest';
  private readonly currentLang = this.translateService.currentLang;

  constructor(private readonly translateService: TranslateService) {
    moment.locale(this.currentLang);
  }

  getValidDateFormat(date: Date | string): Date {
    return date instanceof Date ? date : new Date(date.replace(' ', 'T'));
  }

  createLabelSingle(date: string | Date): string {
    const labelDate = this.getValidDateFormat(date);
    const fullFormat = moment.localeData(this.currentLang).longDateFormat('LL');
    return moment.utc(labelDate).tz(this.timeZone).format(fullFormat);
  }

  createLabel(date1: string | Date, date2: string | Date): string {
    const from = this.getValidDateFormat(date1);
    const till = this.getValidDateFormat(date2);
    const fullFormat = moment.localeData(this.currentLang).longDateFormat('LL');
    let noYear = fullFormat;

    if (fullFormat.includes('YYYY.')) {
      noYear = noYear.replace('YYYY.', '');
    } else if (fullFormat.includes('YYYY,')) {
      noYear = noYear.replace('YYYY,', '');
    } else if (fullFormat.includes('YYYY')) {
      noYear = noYear.replace('YYYY', '');
    }

    let labelHtml = '';
    if (from.getFullYear() === till.getFullYear()) {
      if (from.getMonth() === till.getMonth()) {
        if (from.getDate() === till.getDate()) {
          labelHtml = moment.utc(from).tz(this.timeZone).format(fullFormat);
        } else {
          labelHtml = moment.utc(from).tz(this.timeZone).format(fullFormat);
          labelHtml = labelHtml.slice(0, labelHtml.length - 1) + ' - ' + till.getDate() + '.';
        }
      } else {
        labelHtml = moment.utc(from).tz(this.timeZone).format(fullFormat) + ' - ' + moment.utc(till).tz(this.timeZone).format(noYear);
      }
    } else {
      labelHtml = moment.utc(from).tz(this.timeZone).format(fullFormat) + ' - ' + moment.utc(till).tz(this.timeZone).format(fullFormat);
    }
    return labelHtml;
  }

  formatTime(time: string): string {
    return time.slice(0, 5);
  }

  getYearMonth(date: Date): string {
    return moment.utc(this.getValidDateFormat(date)).tz(this.timeZone).format('YYYY-MM');
  }

  fetchTranslatedMonthNameFromMoment(date: Date | string): string {
    return moment.utc(this.getValidDateFormat(date)).tz(this.timeZone).format('MMMM');
  }

  fetchTranslatedDayNameFromMoment(date: Date | string): string {
    return moment.utc(this.getValidDateFormat(date)).tz(this.timeZone).format('dddd');
  }
}
