import { Component, Input, AfterViewInit, OnInit, HostListener, PLATFORM_ID, Inject, ViewChild, ElementRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { SharedService } from '../../services/shared.service';
import { BaseComponent } from '../base.component';

declare let $: any;

@Component({
  selector: 'app-main-carousel',
  templateUrl: './main-carousel.component.html',
  styleUrls: ['./main-carousel.component.scss'],
})
export class MainCarouselComponent extends BaseComponent implements AfterViewInit, OnInit {
  @Input() list: any[];
  @ViewChild('mainWrapper', { static: true }) mainWrapper: ElementRef;
  @ViewChild('mainCarouselObjDesktopElement', { static: true }) mainCarouselObjDesktopElement: ElementRef;
  @ViewChild('mainCarouselObjMobileElement', { static: true }) mainCarouselObjMobileElement: ElementRef;
  slickDesktop: any;
  slickMobile: any;
  isAfterInit = false;

  carouselOptions: any;
  view = '';
  actualElemId = 0;
  animInProgress = false;
  moveAnimInited = false;
  speed = 200;
  scrollPos: number;
  wrapperScrollPos: number;

  isLoggedIn = false;
  isAlreadyFav = false;

  title: string;
  subtitle: string;
  buttonLabel: string;
  buttonUrl: string;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkView();
  }

  constructor(private readonly sharedService: SharedService, @Inject(PLATFORM_ID) private readonly platformId: any) {
    super();
    // this.renderer.listen('window', 'scroll', (evt) => {
    //   this.handleScroll(evt);
    // });
  }

  ngOnInit(): void {
    this.title = this.componentObject.title;
    this.subtitle = this.componentObject.subtitle;
    this.buttonLabel = this.componentObject.buttonLabel;
    this.buttonUrl = this.componentObject.buttonUrl;
    this.list = this.componentObject.subComponents.map((subComponent) => ({
      width: this.getWidth(subComponent),
      items: this.addHeight(subComponent.subComponents, subComponent.discoverBoxLayout.key),
    }));
    this.checkView();
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isAfterInit = true;
      this.initSlick();
      const natElem = this.mainWrapper.nativeElement;
      this.wrapperScrollPos = natElem.getBoundingClientRect().y || natElem.getBoundingClientRect().top;
    }
  }

  checkView(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth < 1024) {
        this.view = 'mobile';
      } else {
        this.view = 'desktop';
      }

      if (this.isAfterInit) {
        this.initSlick();
      }
    }
  }

  initSlick(): void {
    if (this.view === 'desktop' && typeof this.slickDesktop === 'undefined') {
      // console.log('init slick desktop');
      this.slickDesktop = $(this.mainCarouselObjDesktopElement.nativeElement).not('.slick-initialized').slick({
        dots: true,
        autoplay: false,
        lazyLoad: 'ondemand',
        centerMode: true,
        slidesToShow: 2,
        speed: this.speed,
        autoplaySpeed: 3000,
        variableWidth: true,
        arrows: false,
        infinite: true,
        pauseOnHover: false,
        pauseOnDotsHover: false,
      });
    } else if (this.view === 'mobile' && typeof this.slickMobile === 'undefined') {
      // console.log('init slick mobile');
      this.slickMobile = $(this.mainCarouselObjMobileElement.nativeElement).not('.slick-initialized').slick({
        dots: false,
        autoplay: false,
        lazyLoad: 'ondemand',
        centerMode: false,
        slidesToShow: 2,
        speed: this.speed,
        autoplaySpeed: 3000,
        variableWidth: true,
        arrows: false,
        infinite: true,
        pauseOnHover: false,
        pauseOnDotsHover: false,
      });
    }
  }

  stopAutoplay(): void {
    if (this.view === 'desktop') {
      $(this.mainCarouselObjDesktopElement.nativeElement).slick('slickPause');
    } else {
      $(this.mainCarouselObjMobileElement.nativeElement).slick('slickPause');
    }
  }

  next(): void {
    if (!this.animInProgress) {
      this.actualElemId++;
      if (this.actualElemId === this.list.length) {
        this.actualElemId = 0;
      }
      // this.goto();
      this.slickDesktop.slick('next');
    }
    this.stopAutoplay();
  }

  prev(): void {
    if (!this.animInProgress) {
      this.actualElemId--;
      if (this.actualElemId < 0) {
        this.actualElemId = this.list.length - 1;
      }
      // this.goto();
      this.slickDesktop.slick('prev');
    }
    this.stopAutoplay();
  }

  goto(): void {
    this.animInProgress = true;

    if (typeof this.slickDesktop !== 'undefined') {
      this.slickDesktop.slick('slickGoTo', this.actualElemId);
      setTimeout(() => {
        this.animInProgress = false;
      }, this.speed);
    }

    if (typeof this.slickMobile !== 'undefined') {
      this.slickMobile.slick('slickGoTo', this.actualElemId);
      setTimeout(() => {
        this.animInProgress = false;
      }, this.speed);
    }
    this.stopAutoplay();
  }

  handleScroll(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.scrollPos = window.scrollY || window.pageYOffset;
      if (this.scrollPos > this.wrapperScrollPos - window.innerHeight / 2) {
        this.moveAnimInited = true;
      }
    }
  }

  carouselClick(evt: any): void {
    if (evt.target.getAttribute('data-id')) {
      console.log('fav button pressed. id:' + evt.target.getAttribute('data-id'));
      this.favButtonAction();
    }

    if (evt.target && evt.target.attributes && evt.target.attributes['data-slick-index']) {
      const childNodes = evt.target.parentNode.childNodes;
      const clickedIndex = evt.target.attributes['data-slick-index'].value;

      for (const node of childNodes) {
        const slickCurrent = node.classList.contains('slick-current');
        const slickCurrentIndex = node.attributes['data-slick-index'].value;
        if (slickCurrent && slickCurrentIndex) {
          if (slickCurrentIndex > clickedIndex) {
            this.prev();
          } else {
            this.next();
          }
        }
      }
    }
  }

  favButtonAction(): void {
    if (this.isLoggedIn) {
      if (this.isAlreadyFav) {
        this.setActiveDeleteElementPopup(true);
      } else {
        console.log('TODO Add to fav');
      }
    } else {
      this.setActiveLoginPopup(true);
    }
    this.stopAutoplay();
  }

  setActiveLoginPopup(active: boolean): void {
    this.sharedService.setActiveLoginPopup(active);
  }

  setActiveDeleteElementPopup(active: boolean): void {
    this.sharedService.setActiveDeleteElementPopup(active);
  }

  private getWidth(subComponent): string {
    if (['pyramid', 'trench'].indexOf(subComponent.discoverBoxLayout.key) !== -1) {
      return 'double';
    }
    return 'simple';
  }

  private addHeight(items: any[], layoutType: 'pyramid' | 'smallTri' | 'bigHead' | 'fatty' | 'trench'): any {
    return items.map((item, index) => {
      switch (layoutType) {
        case 'smallTri': {
          return {
            ...item,
            height: 'b',
          };
        }
        case 'bigHead': {
          return {
            ...item,
            height: index === 1 ? 'b' : 'a',
          };
        }
        case 'fatty': {
          return {
            ...item,
            height: index === 0 ? 'b' : 'a',
          };
        }
        case 'pyramid': {
          return {
            ...item,
            height: index === 0 ? 'a' : 'b',
          };
        }
        case 'trench': {
          return {
            ...item,
            height: index === 2 ? 'a' : 'b',
          };
        }
      }
    });
  }
}
