import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'corporate-form-control-error',
  templateUrl: './form-control-error.component.html',
  styleUrls: ['./form-control-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TrendencyFormControlErrorComponent implements OnInit {

  public hide: boolean;
  public errorMessage: string;

  @Input() set error(value: string) {
    if (value !== this.errorMessage) {
      this.errorMessage = value;
      this.hide = !value;
      this.cd.detectChanges();
    }
  }

  constructor(
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void { }
}
