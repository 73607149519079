<section class="image-and-text">
  <div class="wrapper" [ngClass]="{ 'image-left': imageOnLeft, 'image-right': !imageOnLeft }">
    <div class="col-image">
      <span
        class="image"
        [ngStyle]="{ 'background-image': image ? 'url(' + image?.url + ')' : 'none' } | ssrEmptyString"
        [attr.aria-label]="image?.image?.title"
        role="img"
      ></span>
    </div>
    <div class="col-content">
      <div class="poser">
        <h4 class="title">{{ title }}</h4>
        <div class="text" [innerHTML]="lead | safe: 'html'"></div>
        <div class="button-container">
          <a [href]="buttonUrl" *ngIf="buttonLabel && buttonUrl" target="_blank"
            ><button class="button button-secondary" [appBackgroundColor]="'white'">{{ buttonLabel }}</button></a
          >
        </div>
      </div>
    </div>
  </div>
</section>
