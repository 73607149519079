import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MainCarouselComponent } from './components/main-carousel/main-carousel.component';
import { LinkCardComponent } from './components/link-card/link-card.component';
import { CommonModule } from '@angular/common';
import { CardGroupComponent } from './components/main-carousel/components/card-group/card-group.component';
import { CenteredCarouselComponent } from './components/centered-carousel/centered-carousel.component';
import { MapComponent } from './components/map/map.component';
import { MoveAroundComponent } from './components/move-around/move-around.component';
import { SeasonCardsComponent } from './components/season-cards/season-cards.component';
import { PlanCardsComponent } from './components/plan-cards/plan-cards.component';
import { TripCardsComponent } from './components/trip-cards/trip-cards.component';
import { InstagramBlockComponent } from './components/instagram-block/instagram-block.component';
import { BigpicCarouselComponent } from './components/bigpic-carousel/bigpic-carousel.component';
import { PageHeadComponent } from './components/page-head/page-head.component';
import { PageLeadComponent } from './components/page-lead/page-lead.component';
import { InfoCardsComponent } from './components/info-cards/info-cards.component';
import { FindAnExperienceComponent } from './components/find-an-experience/find-an-experience.component';
import { RecommendationComponent } from './components/recommendation/recommendation.component';
import { FilterCollectionComponent } from './components/filter-collection/filter-collection.component';
import { FilterEventComponent } from './components/filter-event/filter-event.component';
import { TiledCardsComponent } from './components/tiled-cards/tiled-cards.component';
import { FilterItineraryComponent } from './components/filter-itinerary/filter-itinerary.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FullWidthMapComponent } from './components/full-width-map/full-width-map.component';
import { TrendencyUtilsModule } from 'trendency/utils';
import { DetailedCardComponent } from './components/detailed-card/detailed-card.component';
import { ArticleLeadComponent } from './components/article-lead/article-lead.component';
import { GalleryCarouselComponent } from './components/gallery-carousel/gallery-carousel.component';
import { ContentImageComponent } from './components/content-image/content-image.component';
import { PlaceDetailsComponent } from './components/place-details/place-details.component';
import { DetailedSliderComponent } from './components/detailed-slider/detailed-slider.component';
import { CardWDescComponent } from './components/card-w-desc/card-w-desc.component';
import { MapButtonWidgetComponent } from './components/map-button-widget/map-button-widget.component';
import { PopupComponent } from './components/popup/popup.component';
import { PopupLoginComponent } from './components/popup/components/popup-login/popup-login.component';
import { TypeCardsComponent } from './components/type-cards/type-cards.component';
import { MapFullWidthComponent } from './components/map-full-width/map-full-width.component';
import { ColoredbgElemlistComponent } from './components/coloredbg-elemlist/coloredbg-elemlist.component';
import { ActivitiesComponent } from './components/activities/activities.component';
import { ShareBarComponent } from './components/share-bar/share-bar.component';
import { TitleTextComponent } from './components/title-text/title-text.component';
import { TextComponent } from './components/text/text.component';
import { RouterModule } from '@angular/router';
import { CookieBarComponent } from './cookie-bar/cookie-bar.component';
import { LookAroundLinkComponent } from './components/look-around-link/look-around-link.component';
import { TripInfoComponent } from './components/trip-info/trip-info.component';
import { TripDayComponent } from './components/trip-day/trip-day.component';
import { TripProgramComponent } from './components/trip-program/trip-program.component';
import { DocumentGroupCardComponent } from './components/document-group-card/document-group-card.component';
import { TripCollectionComponent } from './components/trip-collection/trip-collection.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArticleCollectionComponent } from './components/article-collection/article-collection.component';
import { EventCollectionComponent } from './components/event-collection/event-collection.component';
import { EventCollectionFilterComponent } from './components/event-collection-filter/event-collection-filter.component';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { ArticleContentComponent } from './components/article-content/article-content.component';
import { ImageAndTextComponent } from './components/image-and-text/image-and-text.component';
import { LocationComponent } from './components/location/location.component';
import { ExperienceCustomMarkerComponent } from './components/full-width-map/custom-markers/experience-custom-marker/experience-custom-marker.component';
import { ListingComponent } from './components/listing/listing.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { PracticalInfoComponent } from './components/practical-info/practical-info.component';
import { SafePipe } from './pipes/safe.pipe';
import { ApplicationCollectionComponent } from './components/application-collection/application-collection.component';
import { AnchorBlockComponent } from './components/anchor-block/anchor-block.component';
import { PageHeadNewComponent } from './components/page-head-new/page-head-new.component';
import { PinCheckboxComponent } from './components/find-an-experience/components/pin-checkbox/pin-checkbox.component';
import { ExperienceCardComponent } from './components/experiences/experience-card/experience-card.component';
import { ExperienceFilterComponent } from './components/experiences/experience-filter/experience-filter.component';
import { ExperiencesLoadMoreComponent } from './components/experiences/experiences-load-more/experiences-load-more.component';
import { ExperiencesHeadlineComponent } from './components/experiences/experiences-headline/experiences-headline.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { SsrEmptyStringPipe } from './pipes/ssr-empty-string.pipe';
import { TourinformCustomMarkerComponent } from './components/full-width-map/custom-markers/tourinform-custom-marker/tourinform-custom-marker.component';
import { CampaignFormComponent } from './components/campaign-form/campaign-form.component';
import { TrendencyFormModule } from 'trendency/form';
import { MatInputModule } from '@angular/material/input';
import { CampaignTitleComponent } from './components/campaign-title/campaign-title.component';
import { ButtonComponent } from './components/button/button.component';
import { PageHeadSimpleComponent } from './components/page-head-simple/page-head-simple.component';
import { BreadcrumbDynamicComponent } from './components/breadcrumb-dynamic/breadcrumb-dynamic.component';
import { DetailsComponent } from './components/details/details.component';
import { WeatherInfoStaticComponent } from './components/weather-info-static/weather-info-static.component';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TextMaskModule } from 'angular2-text-mask';
import { DropdownButtonComponent } from './components/dropdown-button/dropdown-button.component';
import { AttractionCustomMarkerComponent } from './components/full-width-map/custom-markers/attraction-custom-marker/attraction-custom-marker.component';
import { LoadMoreButtonComponent } from './components/load-more-button/load-more-button.component';
import { LoadMoreInfoComponent } from './components/load-more-info/load-more-info.component';
import { SearchFormComponent } from '../search/components/search-form/search-form.component';
import { FlagComponent } from './components/flag/flag.component';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { VideoPlayerNewComponent } from './components/video-player-new/video-player-new.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { YoutubePlayerComponent } from './components/youtube-player/youtube-player.component';
import { RefugeeFormComponent } from './components/refugee-form/refugee-form.component';
import { SummerCampaignFormComponent } from './components/summer-campaign-form/summer-campaign-form.component';
import { RestaurantCampaignFormComponent } from './components/restaurant-campaign-form/restaurant-campaign-form.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { BackgroundColorDirective } from './directives/background-color.directive';
import { PodcastComponent } from './components/podcast/podcast.component';
import { Sz2020LogoComponent } from './components/sz-2020-logo/sz-2020-logo.component';
import { DestinationIconComponent } from './components/destination-icon/destination-icon.component';
import { OpeningHoursComponent } from './components/opening-hours/opening-hours.component';
import { TitleToIdPipe } from './pipes/title-to-id.pipe';
import { WeatherWidgetComponent } from './components/weather-widget/weather-widget.component';
import { CollectionCardComponent } from './components/collection-card/collection-card.component';
import { CollectionMapComponent } from './components/collection-map/collection-map.component';
import { CollectionFiltersComponent } from './components/collection-filters/collection-filters.component';
import { CardPopupComponent } from './components/popup/components/card-popup/card-popup.component';
import { RedirectComponent } from './components/redirect/redirect.component';

const BODY_COMPONENTS = [
  TitleTextComponent,
  TextComponent,
  PageHeadComponent,
  BigpicCarouselComponent,
  MoveAroundComponent,
  SeasonCardsComponent,
  PlanCardsComponent,
  TripCardsComponent,
  MapComponent,
  ArticleLeadComponent,
  MainCarouselComponent,
  ShareBarComponent,
  InfoCardsComponent,
  DetailedSliderComponent,
  ContentImageComponent,
  GalleryCarouselComponent,
  TripInfoComponent,
  TripDayComponent,
  ActivitiesComponent,
  LookAroundLinkComponent,
  DocumentGroupCardComponent,
  TripCollectionComponent,
  ArticleCollectionComponent,
  CenteredCarouselComponent,
  EventCollectionComponent,
  EventCollectionFilterComponent,
  FindAnExperienceComponent,
  ExperienceCustomMarkerComponent,
  TourinformCustomMarkerComponent,
  AttractionCustomMarkerComponent,
  LocationComponent,
  ImageAndTextComponent,
  PracticalInfoComponent,
  ApplicationCollectionComponent,
  AnchorBlockComponent,
  InstagramBlockComponent,
  PageHeadNewComponent,
  PageHeadSimpleComponent,
  VideoPlayerComponent,
  CampaignFormComponent,
  CampaignTitleComponent,
  ButtonComponent,
  DetailsComponent,
  WeatherInfoStaticComponent,
  DropdownButtonComponent,
  LoadMoreButtonComponent,
  LoadMoreInfoComponent,
  SearchFormComponent,
  RefugeeFormComponent,
  SummerCampaignFormComponent,
  RestaurantCampaignFormComponent,
  ThankYouComponent,
  VideoPlayerNewComponent,
  YoutubePlayerComponent,
  PodcastComponent,
  Sz2020LogoComponent,
  DestinationIconComponent,
  OpeningHoursComponent,
  WeatherWidgetComponent,
  CollectionCardComponent,
  CollectionMapComponent,
];

@NgModule({
  declarations: [
    DateFormatPipe,
    LinkCardComponent,
    CardGroupComponent,
    PageLeadComponent,
    RecommendationComponent,
    FilterCollectionComponent,
    FilterEventComponent,
    TiledCardsComponent,
    FilterItineraryComponent,
    BreadcrumbComponent,
    BreadcrumbDynamicComponent,
    FullWidthMapComponent,
    DetailedCardComponent,
    ArticleContentComponent,
    GalleryCarouselComponent,
    ContentImageComponent,
    PlaceDetailsComponent,
    CardWDescComponent,
    MapButtonWidgetComponent,
    PopupComponent,
    PinCheckboxComponent,
    PopupLoginComponent,
    TypeCardsComponent,
    MapFullWidthComponent,
    ColoredbgElemlistComponent,
    CookieBarComponent,
    LookAroundLinkComponent,
    TripProgramComponent,
    ...BODY_COMPONENTS,
    ExperienceCardComponent,
    ExperienceFilterComponent,
    ExperiencesLoadMoreComponent,
    ExperiencesHeadlineComponent,
    ListingComponent,
    SafePipe,
    SsrEmptyStringPipe,
    FlagComponent,
    LanguageSwitcherComponent,
    BackgroundColorDirective,
    TitleToIdPipe,
    CollectionFiltersComponent,
    CardPopupComponent,
    RedirectComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    LocalizeRouterModule,
    RouterModule,
    TrendencyUtilsModule,
    ReactiveFormsModule,
    NzCalendarModule,
    FormsModule,
    MatDatepickerModule,
    MatIconModule,
    MatInputModule,
    TrendencyFormModule,
    TextMaskModule,
    MatOptionModule,
    MatSelectModule,
    YouTubePlayerModule,
  ],
  exports: [
    TrendencyUtilsModule,
    TranslateModule,
    LocalizeRouterModule,
    PageLeadComponent,
    RecommendationComponent,
    FilterCollectionComponent,
    FilterEventComponent,
    TiledCardsComponent,
    LinkCardComponent,
    FilterItineraryComponent,
    BreadcrumbComponent,
    BreadcrumbDynamicComponent,
    FullWidthMapComponent,
    DetailedCardComponent,
    ArticleContentComponent,
    GalleryCarouselComponent,
    ContentImageComponent,
    PlaceDetailsComponent,
    CardWDescComponent,
    MapButtonWidgetComponent,
    PopupComponent,
    PopupLoginComponent,
    TypeCardsComponent,
    MapFullWidthComponent,
    ColoredbgElemlistComponent,
    ActivitiesComponent,
    CookieBarComponent,
    LookAroundLinkComponent,
    RouterModule,
    DateFormatPipe,
    LocationComponent,
    ExperienceCardComponent,
    ExperienceFilterComponent,
    ExperiencesLoadMoreComponent,
    ExperiencesHeadlineComponent,
    ListingComponent,
    ...BODY_COMPONENTS,
    SafePipe,
    SsrEmptyStringPipe,
    FlagComponent,
    LanguageSwitcherComponent,
    BackgroundColorDirective,
    YouTubePlayerModule,
    BackgroundColorDirective,
    TitleToIdPipe,
    WeatherWidgetComponent,
    CardPopupComponent,
  ],
  providers: [TitleToIdPipe],
})
export class SharedModule {}
