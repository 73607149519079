<section>
  <div class="grey-block">
    <div class="sharebar-wrapper">
      <div class="wishlist col-12 col-md-6">
        <div class="category-list" *ngIf="experienceTags">
          <span class="share-bar-title">Kategóriák</span>
          <button class="button button-primary" *ngFor="let tag of experienceTags" [appBackgroundColor]="'red'">{{ tag.label }}</button>
        </div>
      </div>
      <div class="social col-12 col-md-6">
        <span class="share-iconlist">
          <span class="share-bar-title">Megosztás</span>
          <a (click)="facebookShare()"><i class="icon icon-facebook-red"></i></a>
          <a (click)="twitterShare()"><i class="icon icon-twitter-red"></i></a>
          <a class="icon icon-mail-red" href="mailto:?subject={{ email }}&body={{ location }}"></a>
        </span>
      </div>
    </div>
  </div>
  <div class="map-wrapper">
    <app-full-width-map [markers]="marker" [openFirst]="true"></app-full-width-map>
    <a [href]="buttonUrl" class="map-link" target="_blank" *ngIf="buttonUrl">
      <button class="button">
        <span class="inner-button"> Útvonal tervezése ide </span>
      </button>
    </a>
  </div>
  <div class="wrapper">
    <h3 *ngIf="title" class="title">{{ title }}</h3>
    <p *ngIf="lead" class="desc">{{ lead }}</p>
    <div *ngIf="thematicIcon && thematicIcon.key" class="specicon {{ thematicIcon.key }}"></div>
  </div>
</section>
