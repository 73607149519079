import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appBackgroundColor]',
})
export class BackgroundColorDirective implements OnInit {
  @Input('appBackgroundColor') color: string;

  constructor(private readonly el: ElementRef) {}

  ngOnInit(): void {
    switch (this.color) {
      case '6499D1' || '70A3D7':
        this.color = 'blue-light';
        break;
      case '7C51A1':
        this.color = 'purple';
        break;
      case '38BA9B':
        this.color = 'green';
        break;
      case 'EF4E69':
        this.color = 'red';
        break;
      case 'F2994A':
        this.color = 'orange';
        break;
    }
    // prettier-ignore
    this.el.nativeElement.style.backgroundImage = 'url(\'assets/images/colors/' + this.color + '.svg\')';
  }
}
