<div class="map-button-wrapper" *ngIf="hasMapData()">
  <div #gmap class="map-container"></div>

  <a [href]="buttonUrl ? buttonUrl : 'https://maps.google.com/?q=' + lat + ',' + lng" class="map-link" target="_blank">
    <button class="button">
      <span class="inner-button" [appBackgroundColor]="'red'"
        ><i class="icon icon-pin-white"></i>{{ buttonLabel ? buttonLabel : ('label.on-map' | translate) }}</span
      >
    </button>
  </a>
</div>
