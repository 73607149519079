import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IContentLang } from '../../shared.definitions';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { environment } from '../../../../environments/environment';
import { BaseService } from '../../../base/base.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
})
export class LanguageSwitcherComponent implements OnInit, OnDestroy {
  @Input() isFooter: boolean;
  languages: IContentLang[];
  filteredLanguages: IContentLang[];
  currentLanguage: IContentLang;
  currentLocale: string;
  isDropdownOpen = false;
  private readonly destroy$ = new Subject();

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translate: TranslateService,
    private readonly localizeService: LocalizeRouterService,
    private readonly baseService: BaseService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.currentLocale = this.translate.currentLang;
      this.languages = data?.data[1]?.languages;

      if (this.languages?.length) {
        this.currentLanguage = this.languages.find((lang) => lang.locale === this.currentLocale);
        this.filteredLanguages = this.languages.filter((lang) => {
          return (
            lang.locale !== this.currentLocale && environment.translation.locales.find((locale) => lang.locale === locale) && lang.isPublic
          );
        });
      }
    });
  }

  toggleDropdown(val?: boolean): void {
    this.isDropdownOpen = val ?? !this.isDropdownOpen;
  }

  changeLanguage(language: IContentLang): void {
    this.toggleDropdown();
    if (this.baseService.isMobileMenuOpen()) {
      this.baseService.toggleMobileMenu(false);
    }
    const navigateTo = this.currentLanguage.locale === 'en' ? ['/'] : ['/', `${this.currentLanguage.locale}`];
    this.router.navigate(navigateTo).then(() => {
      this.localizeService.changeLanguage(language.locale);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
