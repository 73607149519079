import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { TrendencyUtilsService } from 'trendency/utils';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent extends BaseComponent implements OnInit {
  email: Array<string>;
  phone: Array<string>;
  facebook: string;
  instagram: string;
  opening: any[];
  openingState: {
    from: string;
    to: string;
  };
  paymentMethods: Array<string>;
  vouchers: Array<string>;
  tourinform: any;
  isActive = false;
  decodeURIComponent = decodeURIComponent;

  constructor(private readonly utilsService: TrendencyUtilsService) {
    super();
  }

  ngOnInit(): void {
    this.paymentMethods = this.getPaymentOptions();
    this.vouchers = this.getVouchers();
    this.facebook = this.getSocialItem('facebook');
    this.instagram = this.getSocialItem('instagram');
    this.email = this.getContactInfo('email', 'emailAddress');
    this.phone = this.getContactInfo('phone', 'phoneNumber');
  }

  getPaymentOptions(): Array<string> {
    if (!this.componentObject?.paymentMethodItem?.length) {
      return undefined;
    }

    return this.componentObject.paymentMethodItem.map((item) => {
      return this.utilsService.sliceStringAfterCharacter(item.paymentMethodType, '#');
    });
  }

  getVouchers(): Array<string> {
    if (!this.componentObject?.acceptedVoucher?.length) {
      return undefined;
    }
    const index = this.componentObject.acceptedVoucher.findIndex((voucher) => voucher.miscLabel === 'MKB SZÉP Kártya');

    if (index !== -1) {
      this.componentObject.acceptedVoucher[index].miscLabel = 'MBH SZÉP Kártya';
    }

    return this.componentObject.acceptedVoucher.map((item) => {
      return item.miscLabel;
    });
  }

  getSocialItem(name: string): string {
    if (!name || !this.componentObject?.socialAddress?.length) {
      return undefined;
    }

    const socialItem = this.componentObject.socialAddress.find((i) =>
      this.utilsService.sliceStringAfterCharacter(i.social_net, '#') === name ? i : undefined
    );

    return socialItem?.social_reference ?? null;
  }

  getContactInfo(contact: string, field: string): Array<string> {
    const org = this.componentObject['attractionOrganization'];
    if (!org || !org[contact]?.length) {
      return undefined;
    }

    return org[contact].reduce((acc, item) => {
      acc.push(item[field]);
      return acc;
    }, []);
  }

  onDropdownClick(event): void {
    this.isActive = event;
  }
}
