<div class="link-card {{ respStyle }}">
  <a *ngIf="internalLink" [routerLink]="internalLink" routerLinkActive="router-link-active"> </a>
  <a *ngIf="externalLink" [href]="externalLink" routerLinkActive="router-link-active" [target]="newTab ? '_blank' : '_self'"> </a>
  <a *ngIf="!externalLink && !internalLink" routerLinkActive="router-link-active"> </a>

  <span
    *ngIf="data?.image?.url"
    [ngStyle]="{ 'background-image': 'url(' + data.image.url + ')' } | ssrEmptyString"
    [attr.aria-label]="data?.image?.image?.title"
    role="img"
    class="bg"
  ></span>
  <div *ngIf="!data?.image?.url" class="bg no-image-found"></div>
  <div class="bottom" *ngIf="tag">
    <div *ngIf="!data.tag" class="tag" [appBackgroundColor]="tag?.color">{{ tag.tag | translate }}</div>
    <div *ngIf="data.tag" class="tag" [appBackgroundColor]="tag?.color">{{ tag.tag ? tag.tag : 'missing tag' }}</div>
    <span class="title" [appBackgroundColor]="tag?.color">{{ data?.title ? data.title : 'missing title' }}</span>
  </div>
  <!--
  <button class="fav-button" (click)="favButtonAction()" [attr.data-id]="data.id">
    <i class="icon icon-heart-empty-white"></i>
  </button>
  -->
</div>
