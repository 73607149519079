<section class="filter" [appBackgroundColor]="'green'">
  <div class="wrapper row">
    <div class="col-12 title hidden-mobile">
      {{ 'label.filter-trips-title' | translate }}
    </div>

    <div class="col-lg-3 hidden-mobile">
      <div class="search-input">
        <i class="icon icon-search"></i>
        <label class="cdk-visually-hidden" for="fi-search">{{ 'label.filter-search-placeholder' | translate }}</label>
        <input
          type="search"
          placeholder="{{ 'label.filter-search-placeholder' | translate }}"
          (keydown.enter)="onSearch()"
          [formControl]="searchControl"
          id="fi-search"
        />
        <i class="icon icon-arrow-right-white" (click)="onSearch()"></i>
      </div>
    </div>

    <div class="col-12 col-lg-3">
      <button
        class="selectlike-button white type-b"
        (click)="toggleFilter('destinations')"
        [ngClass]="{ active: filterOpened['destinations'] }"
      >
        <i class="icon icon-pin-white hidden-desktop"></i>
        {{ 'label.filter-destination-placeholder' | translate }}
      </button>
      <div
        class="selectlike-content-type-a wide"
        (mouseleave)="toggleFilter('destinations')"
        [ngClass]="{ opened: filterOpened['destinations'] }"
      >
        <div *ngIf="filterOpened['destinations']" class="row dropdown-content-row destination">
          <div class="col-12 col-lg-6">
            <ng-container *ngFor="let filterOption of activeFilters.destinations; let i = index">
              <div
                *ngIf="i < activeFilters.destinations.length / 2"
                class="option link"
                (keydown.enter)="onOptionClick(filterOption)"
                tabindex="0"
                (click)="onOptionClick(filterOption)"
              >
                <div class="checker" [ngClass]="{ checked: filterOption.checked }">
                  <div class="check"></div>
                </div>
                <span class="text">{{ filterOption.text }}</span>
              </div>
            </ng-container>
          </div>
          <div class="col-12 col-lg-6">
            <ng-container *ngFor="let filterOption of activeFilters.destinations; let i = index">
              <div
                *ngIf="i >= activeFilters.destinations.length / 2"
                class="option link"
                (keydown.enter)="onOptionClick(filterOption)"
                tabindex="0"
                (click)="onOptionClick(filterOption)"
              >
                <div class="checker" [ngClass]="{ checked: filterOption.checked }">
                  <div class="check"></div>
                </div>
                <span class="text">{{ filterOption.text }}</span>
              </div>
            </ng-container>
          </div>
          <div class="col-12">
            <button class="clear-filter-button green" (click)="onFilterClear('destinations')">
              <i class="icon icon-close-white"></i>
              <i class="icon icon-close-green"></i>
              {{ 'label.clear-filter' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-3">
      <button
        class="selectlike-button white type-b"
        (click)="toggleFilter('tripLengths')"
        [ngClass]="{ active: filterOpened['tripLengths'] }"
      >
        <i class="icon icon-calendar-white hidden-desktop"></i>
        {{ 'label.filter-trip-length-placeholder' | translate }}
      </button>
      <div class="selectlike-content-type-a" (mouseleave)="toggleFilter('tripLengths')" [ngClass]="{ opened: filterOpened['tripLengths'] }">
        <div *ngIf="filterOpened['tripLengths']" class="row dropdown-content-row duration">
          <div class="col-12">
            <div
              class="option link"
              *ngFor="let filterOption of activeFilters.tripLengths"
              (keydown.enter)="onOptionClick(filterOption)"
              tabindex="0"
              (click)="onOptionClick(filterOption)"
            >
              <div class="checker" [ngClass]="{ checked: filterOption.checked }">
                <div class="check"></div>
              </div>
              <span class="text" *ngIf="filterOption.text === '1'">{{ filterOption.text }} {{ 'label.trip-length-day' | translate }}</span>
              <span class="text" *ngIf="filterOption.text !== '1'">{{ filterOption.text }} {{ 'label.trip-length-days' | translate }}</span>
            </div>
            <button class="clear-filter-button green" (click)="onFilterClear('tripLengths')">
              <i class="icon icon-close-white"></i>
              <i class="icon icon-close-green"></i>
              {{ 'label.clear-filter' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-3">
      <button
        class="selectlike-button white type-b"
        (click)="toggleFilter('targetAudiences')"
        [ngClass]="{ active: filterOpened['targetAudiences'] }"
      >
        <i class="icon icon-filter hidden-desktop"></i>
        {{ 'label.filter-target-audience-placeholder' | translate }}
      </button>
      <div
        class="selectlike-content-type-a"
        (mouseleave)="toggleFilter('targetAudiences')"
        [ngClass]="{ opened: filterOpened['targetAudiences'] }"
      >
        <div *ngIf="filterOpened['targetAudiences']" class="row dropdown-content-row for-whom">
          <div class="col-12">
            <div
              class="option link"
              *ngFor="let filterOption of activeFilters.targetAudiences"
              (keydown.enter)="onOptionClick(filterOption)"
              tabindex="0"
              (click)="onOptionClick(filterOption)"
            >
              <div class="checker" [ngClass]="{ checked: filterOption.checked }">
                <div class="check"></div>
              </div>
              <span class="text">{{ filterOption.text }}</span>
            </div>
            <button class="clear-filter-button green" (click)="onFilterClear('targetAudiences')">
              <i class="icon icon-close-white"></i>
              <i class="icon icon-close-green"></i>
              {{ 'label.clear-filter' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
