<section class="listing" #mainWrapper>
  <div class="section-head">
    <h2 class="head-title" *ngIf="this.componentObject.title">{{ this.componentObject.title }}</h2>
    <p class="desc" *ngIf="this.componentObject.lead">{{ this.componentObject.lead }}</p>
  </div>
  <div class="wrapper">
    <div class="left">
      <!-- Mobile view buttons for filters and map -->
      <div *ngIf="isMobile && !showFilters" class="mobile-buttons">
        <button class="filter-toggle" (click)="showFilters = true">
          <i class="icon icon-filter-current-color"></i>
          <span class="text">Szűrők</span>
        </button>
        <div class="divider"></div>
        <button class="map-toggle" (click)="toggleMap()">
          <i class="icon icon-map"></i>
          <span class="text">Térkép</span>
        </button>
      </div>

      <!-- Filters section -->
      <div class="filters" [ngClass]="{ hidden: isMobile && !showFilters }" (touchstart)="onTouchStart($event)">
        <div class="search-box">
          <!-- <div class="filter-box-title">{{ 'label.filter' | translate }}</div> -->
          <button *ngIf="filterService.hasActiveFilters()" class="clear-all-filter" (click)="clearAllFilters()">
            {{ 'label.clear-filter' | translate }}
            <i class="icon icon-close-red"></i>
          </button>
          <div class="search-inner-box">
            <i class="icon icon-search-red"></i>
            <label class="cdk-visually-hidden" for="ac-search">{{ 'label.filter-search-placeholder' | translate }}</label>
            <input
              class="text-input"
              type="search"
              placeholder="{{ 'label.filter-search-placeholder' | translate }}"
              (keydown.enter)="onSearch()"
              [formControl]="(isMapOpen$ | async) ? null : searchControl"
              id="ac-search"
            />
            <button class="icon icon-arrow-right-red submit-search-btn" (click)="onSearch()"></button>
          </div>
        </div>
        <div class="filter-box">
          <app-collection-filters></app-collection-filters>
        </div>
        <div class="button" *ngIf="isMobile" (click)="scrollToTop(); showFilters = false">
          <a href="#">Találatok</a>
        </div>
      </div>
    </div>
    <div class="right" *ngIf="!isMobile || (isMobile && !showFilters)">
      <div class="campaign-head" *ngIf="this.title">
        <h3>{{ this.title }}</h3>
      </div>
      <ng-container *ngIf="applications.length === 0">
        {{ 'CAMPAIGN.noresult' | translate }}
      </ng-container>
      <div class="btn-container">
        <button
          *ngIf="!isMobile && type === 'every-season'"
          class="button button-primary map"
          [appBackgroundColor]="'red'"
          (click)="toggleMap()"
        >
          <i class="icon icon-pin-white"></i>
          {{ 'ATTRACTION.map-view' | translate }}
        </button>
      </div>
      <ng-container *ngFor="let result of applications">
        <a *ngIf="result.url" [href]="result.url" class="result-link-elem" target="_blank">
          <div class="tag">
            <ng-container *ngFor="let serviceType of result.serviceTypes; let last = last">
              <span>{{ serviceType.title }}<span *ngIf="!last || result.destinationTag">, </span></span>
            </ng-container>
            <ng-container *ngIf="result.destinationTag">
              <span>{{ result.destinationTag.slug | translate }}</span>
            </ng-container>
          </div>
          <div class="name">{{ result.partnerName }}</div>
        </a>
        <a *ngIf="type === 'summer-accomodation'" [href]="result.accomodationWebPageHu" class="result-link-elem" target="_blank">
          <div class="tag">
            <ng-container *ngIf="result.typeAccommodation">{{ 'CAMPAIGN.type.accommodation' | translate }}, </ng-container>
            <ng-container *ngIf="result.typeCateringUnit">{{ 'CAMPAIGN.type.cateringunit' | translate }}, </ng-container>
            <ng-container *ngIf="result.typeAttraction">{{ 'CAMPAIGN.type.attraction' | translate }}, </ng-container>
            <ng-container *ngIf="result.destinationTag">{{
              'CAMPAIGN.destination.' + result.destinationTag.slug | translate
            }}</ng-container>
            <ng-container *ngIf="!result.destinationTag">{{ 'CAMPAIGN.destination.other' | translate }}</ng-container>
          </div>
          <div class="name">{{ result.accomodationName }}</div>
          <img *ngIf="result.isKpp" class="kpp-image" src="/assets/images/kpp.webp" alt="KPP" />
        </a>
        <app-collection-card *ngIf="type === 'every-season'" [card]="result"></app-collection-card>
      </ng-container>
      <div class="bottom-button-box">
        <button
          class="button load-button button-primary"
          [ngClass]="{ hidden: applicationMaxLength <= applicationLength }"
          (click)="loadMore()"
        >
          {{ 'label.load-more' | translate }} ({{ applicationMaxLength - applicationLength }})
        </button>
      </div>
    </div>
    <app-collection-map
      *ngIf="isMapOpen$ | async"
      [cards]="applications"
      [searchControl]="searchControl"
      (close)="toggleMap()"
    ></app-collection-map>
  </div>
</section>
