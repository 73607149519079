<footer [appBackgroundColor]="'blue'">
  <div class="wrapper desktop">
    <!-- TODO: remove ngIf="!isProduction" if it goes production -->
    <app-search-form [style]="'footer'" [isForNavigation]="true" *ngIf="!isProduction"></app-search-form>

    <div class="top-part">
      <div class="left">
        <a [routerLink]="['/'] | localize" class="brand-logo" [attr.aria-label]="'404.back-to-main-page' | translate">
          <i class="icon icon-logo-white"></i>
        </a>
      </div>
      <div class="right">
        <app-language-switcher [isFooter]="true"></app-language-switcher>
        <a
          class="social"
          [href]="footerData?.youtubeUrl | safe : 'url'"
          target="_blank"
          aria-label="Youtube"
          *ngIf="footerData?.youtubeUrl"
        >
          <i class="icon icon-youtube-white"></i>
        </a>
        <a
          class="social"
          [href]="footerData?.facebookUrl | safe : 'url'"
          target="_blank"
          aria-label="Facebook"
          *ngIf="footerData?.facebookUrl"
        >
          <i class="icon icon-facebook-white"></i>
        </a>
        <a
          class="social"
          [href]="footerData?.twitterUrl | safe : 'url'"
          target="_blank"
          aria-label="Twitter"
          *ngIf="footerData?.twitterUrl"
        >
          <i class="icon icon-twitter-white"></i>
        </a>
        <a
          class="social"
          [href]="footerData?.instagramUrl | safe : 'url'"
          target="_blank"
          aria-label="Instagram"
          *ngIf="footerData?.instagramUrl"
        >
          <i class="icon icon-instagram-white"></i>
        </a>
      </div>
    </div>

    <div class="inner-wrapper">
      <div class="inner-left">
        <div class="column col-md-77_4" *ngIf="menuWhatToDo">
          <span class="menu-headline">{{ 'label.what-to-do' | translate }}</span>
          <ul *ngFor="let item of menuWhatToDo.tree">
            <ng-container [ngTemplateOutlet]="menuTreeItem" [ngTemplateOutletContext]="{ item: item }"></ng-container>
          </ul>
        </div>
        <div class="column col-md-77_4" *ngIf="menuWhereToGo">
          <span class="menu-headline">{{ 'label.where-to-go' | translate }}</span>
          <ul *ngFor="let item of menuWhereToGo.tree">
            <ng-container [ngTemplateOutlet]="menuTreeItem" [ngTemplateOutletContext]="{ item: item }"></ng-container>
          </ul>
        </div>
        <div class="column col-md-77_4" *ngIf="menuHungaryFor">
          <span class="menu-headline">{{ 'label.plan-your-trip' | translate }}</span>
          <ul *ngFor="let item of menuHungaryFor.tree">
            <ng-container [ngTemplateOutlet]="menuTreeItem" [ngTemplateOutletContext]="{ item: item }"></ng-container>
          </ul>
        </div>
        <div class="column col-md-77_4" *ngIf="menuPlanYourTrip">
          <span class="menu-headline">{{ 'label.places-to-go' | translate }}</span>
          <ul *ngFor="let item of menuPlanYourTrip.tree">
            <ng-container [ngTemplateOutlet]="menuTreeItem" [ngTemplateOutletContext]="{ item: item }"></ng-container>
          </ul>
          <div class="extra-logos"></div>
        </div>
        <div class="column col-md-23 centered">
          <span class="menu-headline" *ngIf="footerData?.footerContactTitle">{{ footerData.footerContactTitle }}</span>
          <p *ngIf="footerData?.footerContactLead" class="text-linklike no-text-transform" [innerHTML]="footerData.footerContactLead"></p>

          <div class="row logos-box">
            <a class="link" href="https://mtu.gov.hu" target="_blank" [attr.aria-label]="'label.hungarian-tourism-agency' | translate">
              <i class="icon icon-mtu"></i>
            </a>
            <a class="link" href="https://spiceofeurope.com" target="_blank" aria-label="Spice of Europe">
              <i class="icon icon-budapest"></i>
            </a>
          </div>

          <div class="awards">
            <img alt="Év honlapja díj 2022" src="/assets/images/awards/website-of-year-2022.webp" corporateImageLazyLoad />
            <img alt="Év honlapja Minőségi díj 2022" src="/assets/images/awards/website-of-year-quality-2022.webp" corporateImageLazyLoad />
          </div>

          <!--
          <a class="storelink"><i class="icon icon-store-google"></i></a>
          <a class="storelink"><i class="icon icon-store-ios"></i></a>
          -->
        </div>
      </div>
    </div>
  </div>

  <div class="wrapper mobile">
    <div class="mobile-footer">
      <div class="mobile-footer-top">
        <a [routerLink]="['/'] | localize" class="brand-logo" [attr.aria-label]="'404.back-to-main-page' | translate">
          <i class="icon icon-logo-white"></i>
        </a>
      </div>

      <div class="main-links">
        <button class="main-link purple" [appBackgroundColor]="'purple'" (click)="openHeaderNavContent(1)">
          {{ 'label.what-to-do' | translate }}
          <i class="icon icon-arrow-right-button-white"></i>
        </button>
        <button class="main-link red" [appBackgroundColor]="'red'" (click)="openHeaderNavContent(2)">
          {{ 'label.where-to-go' | translate }}
          <i class="icon icon-arrow-right-button-white"></i>
        </button>
        <button class="main-link blue-light" [appBackgroundColor]="'blue-light'" (click)="openHeaderNavContent(3)">
          {{ 'label.plan-your-trip' | translate }}
          <i class="icon icon-arrow-right-button-white"></i>
        </button>
        <button class="main-link green" [appBackgroundColor]="'green'" (click)="openHeaderNavContent(4)">
          {{ 'label.places-to-go' | translate }}
          <i class="icon icon-arrow-right-button-white"></i>
        </button>
      </div>

      <div class="button-links row">
        <div class="col-12">
          <!-- TODO: remove ngIf="!isProduction" if it goes production -->
          <a [routerLink]="['/', 'search'] | localize" class="link" *ngIf="!isProduction">
            <i class="icon icon-search"></i>
            {{ 'SEARCH.search' | translate }}
          </a>
        </div>
        <!--        <div class="col-6">-->
        <!--          <a class="link"><i class="icon icon-heart-empty-white"></i>Your list</a>-->
        <!--        </div>-->
      </div>

      <div class="mobile-footer-content">
        <span class="menu-headline" *ngIf="footerData?.footerContactTitle">{{ footerData.footerContactTitle }}</span>
        <p *ngIf="footerData?.footerContactLead" class="text-linklike no-text-transform" [innerHTML]="footerData.footerContactLead"></p>
      </div>

      <div class="row logos-row">
        <div class="column col-6">
          <a class="link" href="https://mtu.gov.hu" [attr.aria-label]="'label.hungarian-tourism-agency' | translate" target="_blank">
            <i class="icon icon-mtu"></i>
          </a>
        </div>
        <div class="column col-6">
          <a class="link" href="https://spiceofeurope.com" aria-label="Spice of Europe" target="_blank">
            <i class="icon icon-budapest"></i>
          </a>
        </div>
      </div>

      <div class="row awards">
        <div class="column col-6">
          <img alt="Év honlapja díj 2022" src="/assets/images/awards/website-of-year-2022.webp" corporateImageLazyLoad />
        </div>
        <div class="column col-6">
          <img alt="Év honlapja Minőségi díj 2022" src="/assets/images/awards/website-of-year-quality-2022.webp" corporateImageLazyLoad />
        </div>
      </div>

      <!-- <div class="column col-6">
        <a class="link" href="https://mtu.gov.hu" target="_blank" [attr.aria-label]="'label.hungarian-tourism-agency' | translate"><i class="icon icon-mtu"></i></a>
      </div>
      <div class="column col-6">
        <a class="link" href="https://spiceofeurope.com" target="_blank" aria-label="Spice of Europe"><i class="icon icon-budapest"></i></a>
      </div> -->
    </div>
  </div>

  <div class="bottom-footer" [appBackgroundColor]="'conf-blue-b'">
    <div class="mobile-only">
      <a class="social" [href]="footerData?.youtubeUrl | safe : 'url'" target="_blank" aria-label="Youtube" *ngIf="footerData?.youtubeUrl">
        <i class="icon icon-youtube-white"></i>
      </a>
      <a
        class="social"
        [href]="footerData.facebookUrl | safe : 'url'"
        target="_blank"
        aria-label="Facebook"
        *ngIf="footerData?.facebookUrl"
      >
        <i class="icon icon-facebook-white"></i>
      </a>
      <a class="social" [href]="footerData.twitterUrl | safe : 'url'" target="_blank" aria-label="Twitter" *ngIf="footerData?.twitterUrl">
        <i class="icon icon-twitter-white"></i>
      </a>
      <a
        class="social"
        [href]="footerData.instagramUrl | safe : 'url'"
        target="_blank"
        aria-label="Instagram"
        *ngIf="footerData?.instagramUrl"
      >
        <i class="icon icon-instagram-white"></i>
      </a>
    </div>
    <div class="wrapper">
      <div class="left">© 2012-{{ currentYear }} {{ 'label.hungarian-tourism-agency' | translate }}</div>
      <div class="right">
        <a class="link" routerLink="/impresszum">{{ 'label.imprint' | translate }}</a>
        <a class="link" routerLink="/adatkezelesi-tajekoztato">{{ 'label.privacy-notice' | translate }}</a>
        <a class="link" routerLink="/sutik">{{ 'label.declaration-of-consent' | translate }}</a>
      </div>
    </div>
  </div>
</footer>
<!--
<div *ngIf="showPopup" class="overlay" (click)="closePopup()">
  <div class="popup" (click)="clickStop($event)">
    <div class="close" (click)="closePopup()" [title]="'popup.close' | translate"></div>
    <h2>{{ 'popup.title' | translate }}</h2>
    <p>{{ 'popup.description' | translate }}</p>
    <div class="button-container first">
      <a [href]="'popup.buttonurl' | translate">
        <button class="button button-primary">{{ 'popup.buttonlabel' | translate }}</button>
      </a>
    </div>

    <div class="button-container second">
      <a [href]="'#'">
        <button class="button button-primary">button2</button>
      </a>
    </div>
    <a class="more" [href]="'#'">
      Read more
      <i class="icon icon-arrow-right-more-red"></i>
    </a>

  </div>
</div>
   -->

<ng-template #menuTreeItem let-item="item">
  <li>
    <a *ngIf="item?.customUrl" class="link" [href]="item.customUrl" [target]="item.targetBlank ? '_blank' : '_self'">{{ item.label }}</a>
    <a *ngIf="item?.routerLink" class="link" [routerLink]="item.routerLink">{{ item.label }}</a>
  </li>
  <li *ngFor="let child of item.children">
    <a *ngIf="child?.customUrl" class="link small" [href]="child.customUrl" [target]="child.targetBlank ? '_blank' : '_self'">{{
      child.label
    }}</a>
    <a *ngIf="child?.routerLink" class="link small" [routerLink]="child.routerLink">{{ child.label }}</a>
  </li>
</ng-template>
