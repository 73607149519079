import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { Observable, Observer, BehaviorSubject, Subject } from 'rxjs';
import { Inject, PLATFORM_ID, Injectable, OnDestroy } from '@angular/core';
import { StateKey, makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../src/environments/environment';
import { debounceTime, takeUntil } from 'rxjs/operators';
const fs = require('fs');

export class TranslateUniversalLoader implements TranslateLoader {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private transferState: TransferState,
    private http: HttpClient
  ) {}

  public getTranslation(lang: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const key: StateKey<any> = makeStateKey<any>(`transfer-translate-json-${lang}`);
      let localesFolder = '';
      if (isPlatformBrowser(this.platformId)) {
        localesFolder = environment.translation && environment.translation.localesFolder.client;
      } else {
        localesFolder = __dirname + (environment.translation && environment.translation.localesFolder.server);
      }

      // Node oldalon olvassa be a fordítókulcsokat a JSON-ből és mentse el TransferState-ben
      if (isPlatformServer(this.platformId)) {
        const path = `${localesFolder}/${lang}.json`;
        const translation = fs.existsSync(path) ? JSON.parse(fs.readFileSync(path, 'utf8')) : {};
        this.transferState.set(key, translation);
        observer.next(translation);
        observer.complete();
      } else {
        const translationFromServer = this.transferState.get(key, null);
        // Ha lett elmentve fordítás a TransferState-ben, akkor böngésző oldalon szedje ki onnan a fordításokat
        if (translationFromServer && Object.keys(translationFromServer).length) {
          observer.next(translationFromServer);
          observer.complete();
        // Ha nem, akkor kérje le őket a JSON fájlból HTTP hívással
        } else {
          new TranslateHttpLoader(this.http, `${localesFolder}/`, '.json').getTranslation(lang).subscribe(
            translation => {
              observer.next(translation);
              observer.complete();
            },
            () => {
              observer.next({});
              observer.complete();
            });
        }
      }
    });
  }
}

export function translateLoaderFactory(platformId: any, transferState: TransferState, http: HttpClient) {
  return new TranslateUniversalLoader(platformId, transferState, http);
}

export class TrendencyMissingTranslationHandler implements MissingTranslationHandler {
  constructor(
    private missingTranslationService: TrendencyMissingTranslationService
  ) {}

  handle(params: MissingTranslationHandlerParams) {
    if (typeof window !== 'undefined') {
      this.missingTranslationService.addMissingTranslation(params.key);
    }
  }
}

@Injectable({ providedIn: 'root' })
export class TrendencyMissingTranslationService implements OnDestroy {
  private missingTranslationsSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public missingTranslations$: Observable<string[]> = this.missingTranslationsSubject.asObservable();

  private destroy$ = new Subject();

  constructor() {
    this.missingTranslations$.pipe(
      debounceTime(1000),
      takeUntil(this.destroy$),
    ).subscribe(missingTranslations => {
      if (missingTranslations.length) {
        console.warn('Hiányzó fordítások: ', missingTranslations);
      }
    });
  }

  public addMissingTranslation(value: string) {
    const missingTranslations = this.missingTranslationsSubject.getValue();
    if (missingTranslations.indexOf(value) === -1) {
      missingTranslations.push(value);
    }
    this.missingTranslationsSubject.next(missingTranslations);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
