<app-event-collection-filter [filters]="activeFilters" (filterChange)="onFilterChange($event)"></app-event-collection-filter>

<div class="active-filter-list">
  <div class="wrapper" *ngIf="anyFilterActive()">
    <div class="filter-type">
      <ng-container *ngFor="let filterOption of activeFilters.destinations">
        <div class="filter-tag red" *ngIf="filterOption.checked">
          <button class="filter-off" (click)="removeFilterElement(filterOption)">x</button>
          {{ filterOption.text }}
        </div>
      </ng-container>
      <ng-container *ngFor="let filterOption of activeFilters.thematics">
        <div class="filter-tag purple" *ngIf="filterOption.checked">
          <button class="filter-off" (click)="removeFilterElement(filterOption)">x</button>
          <ng-container *ngIf="filterOption.text === '1'">{{ filterOption.text }} {{ 'label.trip-length-day' | translate }}</ng-container>
          <ng-container *ngIf="filterOption.text !== '1'">{{ filterOption.text }} {{ 'label.trip-length-days' | translate }}</ng-container>
        </div>
      </ng-container>
      <button *ngIf="anyFilterActive()" class="filter-clear filter-tag clear" (click)="clearFilters()">
        <i class="icon icon-close-red"></i>
        {{ 'label.clear-all-filters' | translate }}
      </button>
    </div>
    <h2 *ngIf="activeFilters?.dateRange?.length" [innerHTML]="dateLabel(activeFilters.dateRange[0], activeFilters.dateRange[1])"></h2>
  </div>
</div>

<div class="cards">
  <div class="container wrapper" #ecFilterResults>
    <span *ngIf="events?.length !== 0" class="cdk-visually-hidden" role="alert">{{
      ('label.filter-results' | translate) + ' ' + events.length
    }}</span>
    <span *ngIf="events?.length === 0" class="cdk-visually-hidden" role="alert">{{ 'label.filter-no-results' | translate }}</span>
    <ng-container *ngIf="datedEvents?.length > 0">
      <ng-container *ngFor="let month of datedEvents">
        <div class="month">
          <h2 class="month-name" *ngIf="month?.monthLabel">{{ month.monthLabel }}</h2>
          <ng-container *ngTemplateOutlet="cardtemplate; context: { events: month.events }"></ng-container>
        </div>
      </ng-container>
    </ng-container>

    <ng-container class="month" *ngIf="datedEvents?.length < 1">
      <ng-container *ngTemplateOutlet="cardtemplate; context: { events: events }"></ng-container>
    </ng-container>

    <div class="bottom-button-box">
      <button
        [ngClass]="{ hidden: maxEventCount <= eventsPerPage * limitMultiplier }"
        class="button load-button button-secondary"
        (click)="loadMore()"
      >
        {{ 'label.load-more' | translate }}
      </button>
    </div>
  </div>
</div>

<ng-template #cardtemplate let-events="events">
  <h2 *ngIf="events.length === 0" class="no-results">{{ 'label.filter-no-results' | translate }}</h2>
  <div class="row desktop">
    <div class="col-12 col-lg-4 card" *ngFor="let event of events">
      <div class="slide">
        <div class="poser">
          <div class="card-header">
            <b>
              <i class="icon icon-calendar-purple"></i>
              <span
                [title]="dateLabel(event.eventDateFrom, event.eventDateTill)"
                [innerHTML]="dateLabel(event.eventDateFrom, event.eventDateTill)"
              ></span>
            </b>
            <div class="location-name"><i class="icon icon-pin-purple"></i>{{ event.destination }}</div>
          </div>
          <div class="card-wrapper">
            <app-link-card [data]="event"></app-link-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
