import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DateLabelService } from '../../services/date-label.service';
import { SharedService } from '../../services/shared.service';
import { BaseComponent } from '../base.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-page-head-new',
  templateUrl: './page-head-new.component.html',
  styleUrls: ['./page-head-new.component.scss'],
})
export class PageHeadNewComponent extends BaseComponent implements OnInit, OnDestroy {
  eventDateFrom: any;
  eventDateTill: any;
  imageUrl: string;
  imageAlt: string;
  figureImage: number;
  c: string;
  gradient: boolean;
  title: string;
  text: string;
  shortenedLead: string;
  showReadMore: boolean;
  showReadLess: boolean;
  private readonly destroy$ = new Subject();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly dateLabelService: DateLabelService,
    private readonly sharedService: SharedService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.componentObject?.title) {
      this.title = this.componentObject.title;
    }

    if (this.componentObject?.text) {
      this.text = this.componentObject.text;
      this.shortenedLead = this.shortenText();
    }

    if (this.componentObject?.theme?.key) {
      const figure = this.componentObject.theme.key;
      this.figureImage = +figure.substr(figure.length - 1) + 1;
    }

    this.imageUrl =
      this.sharedService.isMobile() && this.componentObject?.mobileThumbnailImage?.url
        ? this.componentObject?.mobileThumbnailImage?.url
        : this.componentObject?.image?.url;
    this.imageAlt =
      this.sharedService.isMobile() && this.componentObject?.mobileThumbnailImage?.image?.title
        ? this.componentObject?.mobileThumbnailImage?.image?.title
        : this.componentObject?.image?.image?.title;

    this.activatedRoute.data.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      const data = res?.data?.data;
      const type = res?.data?.data?.type;

      if (type === 'Event' && data?.meta?.length) {
        const eventStatus = data?.meta.find((item) => item.type === 'Meta.Status.Event');
        if (eventStatus) {
          for (const field of eventStatus.fields) {
            if (field.key === 'eventDateFrom') {
              this.eventDateFrom = field.value ?? null;
            }
            if (field.key === 'eventDateTill') {
              this.eventDateTill = field.value ?? null;
            }
          }
        }
      }
    });
  }

  dateLabel(date1: string, date2: string): string {
    return this.dateLabelService.createLabel(date1, date2);
  }

  onReadMore(): void {
    this.shortenedLead = this.text;
    this.showReadMore = false;
    this.showReadLess = true;
  }

  onReadLess(): void {
    this.shortenedLead = this.shortenText();
  }

  private shortenText(): string {
    const words = this.text.split(' ');
    if (words.length > 80) {
      this.showReadMore = true;
      this.showReadLess = false;
      return words.slice(0, 80).join(' ') + ' ... </p>';
    }
    return this.text;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
