import {
  Directive, Self, OnInit, OnDestroy, Optional, Host, ViewContainerRef, ComponentRef, ComponentFactoryResolver, Input
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription, Observable, EMPTY, merge } from 'rxjs';
import { FormSubmitDirective } from './form-submit.directive';
import { TrendencyFormControlErrorComponent } from '../components/form-control-error/form-control-error.component';
import { TrendencyFormControlErrorContainerDirective } from './form-control-error-container.directive';
import { IFormErrors } from '../form.definitions';

export const DEFAULT_FORM_ERRORS: IFormErrors = {
  required: 'ERROR.required',
  requiredTrue: 'ERROR:required',
  minlength: 'ERROR.minlength',
  maxlength: 'ERROR.maxlength',
  min: 'ERROR.min',
  max: 'ERROR.max',
  pattern: 'ERROR.pattern',
};

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[formControl], [formControlName]'
})
export class FormControlErrorDirective implements OnInit, OnDestroy {

  private subscription: Subscription;
  private submit: Observable<Event>;
  private formControlErrorComponent: ComponentRef<TrendencyFormControlErrorComponent>;
  private container: ViewContainerRef;
  private _errors: IFormErrors;

  @Input()
  set errors(value) {
    this._errors = value;
  }

  constructor(
    @Self() private formControl: NgControl,
    @Optional() @Host() private form: FormSubmitDirective,
    @Optional() private formControlErrorContainer: TrendencyFormControlErrorContainerDirective,
    private viewContainer: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) { }

  ngOnInit(): void {
    this.submit = this.form ? this.form.submit : EMPTY;
    this.container = this.formControlErrorContainer ? this.formControlErrorContainer.viewContainer : this.viewContainer;
    if (this._errors !== null) {
      this._errors = Object.assign({}, DEFAULT_FORM_ERRORS, this._errors);
    }

    this.subscription =
      merge(
        this.submit,
        this.formControl.valueChanges
      ).subscribe(() => {
        // Ha az errors input nem null, generálja ki az errorokat
        if (this._errors !== null) {
          this.generateErrors();
        }
      });
  }

  private generateErrors() {
    const formControlErrors = this.formControl.errors;
    if (formControlErrors) {
      const firstErrorKey = Object.keys(formControlErrors)[0];
      const errorMessage = this._errors[firstErrorKey];
      this.setErrorComponent(errorMessage);
    } else if (this.formControlErrorComponent) {
      this.setErrorComponent(null);
    }
  }

  private setErrorComponent(errorMessage: string) {
    if (!this.formControlErrorComponent) {
      const factory = this.componentFactoryResolver.resolveComponentFactory(TrendencyFormControlErrorComponent);
      this.formControlErrorComponent = this.container.createComponent(factory);
    }
    this.formControlErrorComponent.instance.error = errorMessage;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
