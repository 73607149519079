import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { ApiService } from '../../services/api.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormService } from '../../services/form.service';

@Component({
  selector: 'app-refugee-form',
  templateUrl: './refugee-form.component.html',
  styleUrls: ['../campaign-form/campaign-form.component.scss'],
})
export class RefugeeFormComponent extends BaseComponent implements OnInit {
  dataManagementDoc: string;
  dataManagementDocText: string;
  policyDoc: string;
  policyDocText: string;
  text: string;
  formSent = false;
  form: FormGroup;
  pageUuid: string;
  taxNumberMask: (string | RegExp)[];
  counties = [
    { id: 1, name: 'Bács-Kiskun megye' },
    { id: 2, name: 'Baranya megye' },
    { id: 3, name: 'Békés megye' },
    { id: 4, name: 'Borsod-Abaúj-Zemplén megye' },
    { id: 5, name: 'Csongrád-Csanád megye' },
    { id: 6, name: 'Fejér megye' },
    { id: 7, name: 'Győr-Moson-Sopron megye' },
    { id: 8, name: 'Hajdú-Bihar megye' },
    { id: 9, name: 'Heves megye' },
    { id: 10, name: 'Jász-Nagykun-Szolnok megye' },
    { id: 11, name: 'Komárom-Esztergom megye' },
    { id: 12, name: 'Nógrád megye' },
    { id: 13, name: 'Pest megye' },
    { id: 14, name: 'Somogy megye' },
    { id: 15, name: 'Szabolcs-Szatmár-Bereg megye' },
    { id: 16, name: 'Tolna megye' },
    { id: 17, name: 'Vas megye' },
    { id: 18, name: 'Veszprém megye' },
    { id: 19, name: 'Zala megye' },
  ];

  constructor(private readonly apiService: ApiService, private readonly route: ActivatedRoute, private readonly formService: FormService) {
    super();
    this.taxNumberMask = this.formService.taxNumberMask;
  }

  ngOnInit(): void {
    this.pageUuid = this.route.snapshot.data.data.uuid;

    if (this.componentObject) {
      this.text = this.componentObject.text;
      this.dataManagementDocText = this.componentObject.dataManagementDocText;
      this.policyDocText = this.componentObject.policyDocText;

      if (this.componentObject.dataManagementDoc) {
        this.apiService.getFile$(this.componentObject.dataManagementDoc.uuid).subscribe((res) => (this.dataManagementDoc = res.path));
      }

      if (this.componentObject.policyDoc) {
        this.apiService.getFile$(this.componentObject.policyDoc.uuid).subscribe((res) => (this.policyDoc = res.path));
      }
    }

    this.initFormGroup();
  }

  onFormSubmit(): void {
    if (this.form.valid) {
      this.apiService.getRefugeeForm$().subscribe((res) => {
        res.data = res.data.map((field) => {
          field.value = this.form.value[field.key];
          if (field.key === 'contactPhone') {
            field.value = this.form.value['contactCountryCode'] + this.form.value[field.key];
          }
          return field;
        });

        this.apiService
          .postRefugeeForm$(this.pageUuid, res)
          /*eslint-disable rxjs/no-nested-subscribe*/
          .subscribe(() => (this.formSent = true));
      });
    }
  }

  initFormGroup(): void {
    const TAX_REGEXP = /^\d{8}-\d-\d{2}$/;
    const URL_REGEXP = '(https?://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    const ZIP_REGEX = /^([a-zA-Z0-9 .:\\/_-]+)$/;

    console.log(3222);

    this.form = new FormGroup({
      accomodationName: new FormControl('', [Validators.required, Validators.maxLength(250)]),
      ntakId: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      county: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      zipcode: new FormControl('', [Validators.required, Validators.pattern(ZIP_REGEX)]),
      address: new FormControl('', [Validators.required]),
      postalCounty: new FormControl(''),
      postalCity: new FormControl(''),
      postalZipcode: new FormControl('', [Validators.pattern(ZIP_REGEX)]),
      postalAddress: new FormControl(''),
      companyTaxId: new FormControl('', [Validators.required, Validators.pattern(TAX_REGEXP)]),
      companyId: new FormControl('', [Validators.required]),
      representativeName: new FormControl('', [Validators.required]),
      representativeTitle: new FormControl('', [Validators.required]),
      contactPersonName: new FormControl('', [Validators.required]),
      contactEmail: new FormControl('', [Validators.email, Validators.required]),
      contactCountryCode: new FormControl('+', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]),
      contactPhone: new FormControl('', [Validators.required]),
      accomodationWebPage: new FormControl('', [Validators.required, Validators.pattern(URL_REGEXP)]),
      dataManagementAccept: new FormControl('', [Validators.requiredTrue]),
      policyAccept: new FormControl('', [Validators.requiredTrue]),
    });
  }

  updateCountryCode(): void {
    if (this.form.controls['contactCountryCode'].value.charAt(0) !== '+') {
      this.form.controls['contactCountryCode'].patchValue('+' + this.form.controls['contactCountryCode'].value);
    }
  }
}
