<div
  class="lang-switcher"
  [ngClass]="{ opened: isDropdownOpen, 'is-footer': isFooter }"
  (mouseleave)="toggleDropdown(false)"
  *ngIf="currentLanguage && filteredLanguages?.length"
>
  <button class="lang-switcher__trigger" (click)="toggleDropdown()">
    <app-flag [language]="currentLanguage"></app-flag>
    {{ currentLanguage.label }}
  </button>
  <div class="lang-switcher__dropdown">
    <button class="back-button" (click)="toggleDropdown()">{{ 'label.back' | translate }}</button>
    <ng-container *ngFor="let language of filteredLanguages">
      <a
        *ngIf="language.locale !== 'hu' && language.locale !== 'en'"
        [tabindex]="isDropdownOpen ? 0 : -1"
        [href]="'https://visithungary.com/' + language.locale"
        class="lang-switcher__element"
      >
        <app-flag [language]="language"></app-flag>
        {{ language.label }}
      </a>
      <a
        *ngIf="language.locale === 'en'"
        [tabindex]="isDropdownOpen ? 0 : -1"
        [href]="'https://visithungary.com/'"
        class="lang-switcher__element"
      >
        <app-flag [language]="language"></app-flag>
        {{ language.label }}
      </a>
    </ng-container>
  </div>
</div>
