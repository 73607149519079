import { NgModule, ApplicationRef } from '@angular/core';
import { Router, NavigationStart, NavigationError, NavigationEnd, NavigationCancel, ActivationEnd } from '@angular/router';
import { TrendencyProgressBarService } from './services/progress-bar.service';
import { TrendencyProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { CommonModule } from '@angular/common';
import { TrendFullscreenLoaderComponent } from './components/fullscreen-loader/fullscreen-loader.component';
import { corporateImageLazyLoadDirective } from './directives/image-lazy-load.directive';
import { TrendencyButtonLoadDirective } from './directives/button-load.directive';
import { TrendencyIframeLazyLoadDirective } from './directives/iframe-lazy-load.directive';
import { TrendencyFullscreenLoaderService } from './services/fullscreen-loader.service';
import { TrendencyUtilsService, TrendencyUtilsModule } from 'trendency/utils';

@NgModule({
    imports: [
        CommonModule,
        TrendencyUtilsModule
    ],
    declarations: [
        TrendencyProgressBarComponent,
        TrendFullscreenLoaderComponent,
        corporateImageLazyLoadDirective,
        TrendencyButtonLoadDirective,
        TrendencyIframeLazyLoadDirective
    ],
    providers: [
        TrendencyProgressBarService,
        TrendencyFullscreenLoaderService,
    ],
    exports: [
        TrendencyProgressBarComponent,
        TrendFullscreenLoaderComponent,
        corporateImageLazyLoadDirective,
        TrendencyButtonLoadDirective,
        TrendencyIframeLazyLoadDirective
    ]
})
export class TrendencyLoadingModule {

  private navigationProgressBarInitied: boolean;

  constructor(
    private router: Router,
    private progressBarService: TrendencyProgressBarService,
    private appRef: ApplicationRef,
    private utilsService: TrendencyUtilsService
  ) {
    this.setNavigationProgressBar();
  }

  private getCurrentNavigationState() {
      // `getCurrentNavigation` only available in angular `7.2`
      const currentNavigation = (<any>this.router).getCurrentNavigation && (<any>this.router).getCurrentNavigation();
      if (currentNavigation && currentNavigation.extras) {
        return currentNavigation.extras.state;
      }

      return {};
  }

  private setNavigationProgressBar() {
    if (this.utilsService.isBrowser() && !this.navigationProgressBarInitied) {
      const body = document.getElementsByTagName('body')[0];
      if (body) {
        this.utilsService.createDynamicComponent(TrendencyProgressBarComponent, this.appRef, body);
        this.navigationProgressBarInitied = true;
      }

      this.router.events.subscribe(event => {
        const state = this.getCurrentNavigationState();
        if (state && state.ignoreLoadingBar) {
          return;
        }

        if (event instanceof NavigationStart) {
          this.progressBarService.start();
        }

        if (event instanceof NavigationError
          || event instanceof NavigationEnd
          || event instanceof NavigationCancel
          || event instanceof ActivationEnd
        ) {
          this.progressBarService.complete();
        }
      });
    }
  }
 }
