import { NgModule } from '@angular/core';
import { FormControlErrorDirective } from './directives/form-control-error.directive';
import { FormSubmitDirective } from './directives/form-submit.directive';
import { TrendencyFormControlErrorContainerDirective } from './directives/form-control-error-container.directive';
import { TrendencyFormControlErrorComponent } from './components/form-control-error/form-control-error.component';
import { TrendencyFormService } from './services/form.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        TranslateModule,
    ],
    declarations: [
        FormControlErrorDirective,
        FormSubmitDirective,
        TrendencyFormControlErrorContainerDirective,
        TrendencyFormControlErrorComponent
    ],
    exports: [
        FormControlErrorDirective,
        TrendencyFormControlErrorContainerDirective,
        FormSubmitDirective,
        TrendencyFormControlErrorComponent,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [
        TrendencyFormService
    ]
})
export class TrendencyFormModule { }
