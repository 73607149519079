<section *ngIf="image?.url" class="content-image" id="{{ componentObject | titleToId }}">
  <div class="wrapper">
    <div
      class="picture"
      [ngStyle]="{ 'background-image': 'url(' + image?.url + ')' | ssrEmptyString }"
      [attr.aria-label]="image?.image?.title"
      corporateImageLazyLoad
    >
      <div class="left-content"></div>
      <div *ngIf="title" class="right-content">{{ title }} <i class="icon icon-photo"></i></div>
    </div>
  </div>
</section>
