<section class="trip-cards">
  <div #tripCaruselElement class="trip-carusel">
    <div class="card" *ngFor="let card of cards">
      <div class="card__content">
        <div class="card__anime">
          <span
            *ngIf="card.image"
            [ngStyle]="{ 'background-image': 'url(' + card.image.url + ')' } | ssrEmptyString"
            [attr.aria-label]="card?.image?.image?.title"
            aria-hidden="true"
            role="img"
            class="card__bg"
          ></span>
          <div class="card__top">
            <ng-container [ngTemplateOutlet]="tripContent" [ngTemplateOutletContext]="{ card: card }"></ng-container>
          </div>
          <div class="card__hover">
            <div class="card__hover-top">
              <ng-container [ngTemplateOutlet]="tripContent" [ngTemplateOutletContext]="{ card: card }"></ng-container>
              <span *ngIf="card.lead" [innerHtml]="card.lead" class="card__lead"></span>
            </div>
            <div class="card__hover-bottom" [appBackgroundColor]="'blue-a'">
              <div class="card__subcontent" *ngIf="card?.linkMailto || card?.linkCall">
                <a [href]="'mailto: ' + card.linkMailto" class="card__email" *ngIf="card.linkMailto">{{ card.linkMailto }}</a>
                <a [href]="'tel: ' + card.linkCall" class="card__phone" *ngIf="card.linkCall">{{ card.linkCall }}</a>
              </div>
              <a
                class="button button-primary hover-bottom"
                [appBackgroundColor]="'red'"
                *ngIf="card.customUrl"
                [href]="card.customUrl"
                [target]="card.newTab ? '_blank' : '_self'"
                >{{ card.buttonLabel ? card.buttonLabel : 'details' }}
              </a>
              <a
                class="button button-primary hover-bottom"
                [appBackgroundColor]="'red'"
                *ngIf="card.routerLink && !card.customUrl"
                [routerLink]="card.routerLink"
                >{{ card.buttonLabel ? card.buttonLabel : 'details' }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #tripContent let-card="card">
  <span class="card__title" *ngIf="card.title">{{ card.title }}</span>
  <span class="card__subtitle" *ngIf="card.subtitle">{{ card.subtitle }}</span>
</ng-template>
