import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-content-image',
  templateUrl: './content-image.component.html',
  styleUrls: ['./content-image.component.scss'],
})
export class ContentImageComponent extends BaseComponent implements OnInit {
  title: string;
  image: any;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.title = this.componentObject?.title ?? '';
    this.image = this.componentObject?.image ?? '';
  }
}
