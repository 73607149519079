<section class="info-cards" [ngClass]="{ green: isGreen }">
  <div class="section-head margin-b" *ngIf="title || subtitle">
    <h2 *ngIf="title" class="title" id="{{ componentObject | titleToId }}">{{ title }}</h2>
    <p *ngIf="subtitle" class="desc subtitle">{{ subtitle }}</p>
  </div>
  <div class="row wrapper">
    <ng-container *ngFor="let card of filteredCards">
      <a [routerLink]="card.routerLink" class="col-6 col-lg-4 card card--info" *ngIf="card.customUrl === null">
        <ng-container *ngTemplateOutlet="innerCard; context: { card: card }"></ng-container>
      </a>
      <a
        [href]="card.customUrl"
        class="col-6 col-lg-4 card card--info"
        *ngIf="card.customUrl !== null"
        [target]="card.subComponents[0].newTab === true ? '_blank' : '_self'"
      >
        <ng-container *ngTemplateOutlet="innerCard; context: { card: card }"></ng-container>
      </a>
    </ng-container>
  </div>
  <div class="bottom-button-box" *ngIf="showLoadMore">
    <button class="button load-button button-secondary" [appBackgroundColor]="'white'" (click)="loadMore()">
      {{ 'label.explore' | translate }}
    </button>
  </div>
</section>

<ng-template #innerCard let-card="card">
  <div class="card__inner">
    <app-destination-icon *ngIf="card?.destinationIcon?.key" [key]="card.destinationIcon.key"></app-destination-icon>
    <span
      *ngIf="card.image"
      class="card__bg"
      [ngStyle]="{ 'background-image': 'url(' + card.image.url + ')' } | ssrEmptyString"
      [attr.aria-label]="card?.image?.image?.title"
      aria-hidden="true"
      role="img"
      corporateImageLazyLoad
    ></span>
    <div class="card__content">
      <div class="card__locale" *ngIf="card.locale && card.locale.locale">
        <i class="icon icon-flag-{{ card.locale.locale }}"></i>
      </div>
      <div class="card__header">
        <p *ngIf="card.subtitle" class="card__subtitle">{{ card.subtitle }}</p>
        <h4 *ngIf="card?.title" class="card__title inner-card-title">{{ card.title }}</h4>
      </div>
      <div class="card__text" *ngIf="card?.lead" [innerHTML]="card.lead"></div>
    </div>
  </div>
</ng-template>
