<a class="link-card {{ respStyle }}" [routerLink]="[type, data.slug]" routerLinkActive="router-link-active">
  <div
    *ngIf="data.thumbnail && data.thumbnail.url"
    class="bg"
    [ngStyle]="{ 'background-image': 'url(' + data.thumbnail.url + ')' } | ssrEmptyString"
    corporateImageLazyLoad
  ></div>
  <div *ngIf="!data.thumbnail || !data.thumbnail.url" class="bg no-image-found"></div>
  <div class="bottom">
    <div class="tag" *ngIf="data.documentCount !== null && data.documentCount < 2" [style]="'background-color: #' + color">
      {{ data.documentCount }} {{ 'DOCUMENTS.document' | translate }}
    </div>
    <div class="tag" *ngIf="data.documentCount !== null && data.documentCount > 1" [style]="'background-color: #' + color">
      {{ data.documentCount }} {{ 'DOCUMENTS.documents' | translate }}
    </div>
    <h4 class="title">{{ data.label }}</h4>
  </div>
</a>
