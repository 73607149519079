import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { ApiService } from '../../services/api.service';
import { IFile } from '../../shared.definitions';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent extends BaseComponent implements OnInit {
  buttonText: string;
  link: string;
  document: IFile;
  fileUrl: IFile;

  constructor(private readonly apiService: ApiService) {
    super();
  }

  ngOnInit(): void {
    if (this.componentObject) {
      this.buttonText = this.componentObject?.buttonText;
      this.link = this.componentObject?.link;

      if (this.componentObject?.document) {
        this.document = this.componentObject?.document;
      }

      if (this.componentObject?.campaignDocument) {
        this.document = this.componentObject?.campaignDocument;
      }

      if (this.document) {
        this.apiService.getFile$(this.document.uuid).subscribe((res) => {
          this.fileUrl = res.path;
        });
      }
    }
  }
}
