import { Component, HostListener, isDevMode, OnDestroy, OnInit } from '@angular/core';
import { NzI18nService, en_GB } from 'ng-zorro-antd/i18n';
import { TrendencyUtilsService } from 'trendency/utils';
import { Router, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter, pairwise, takeUntil } from 'rxjs/operators';
import { pipe, Subject } from 'rxjs';
import { CustomUrlService } from './shared/services/custom-url.service';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <corporate-fullscreen-loader></corporate-fullscreen-loader>
  `,
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();

  constructor(
    private readonly i18n: NzI18nService,
    private readonly utilsService: TrendencyUtilsService,
    private readonly viewportScroller: ViewportScroller,
    private readonly router: Router,
    private readonly customUrlService: CustomUrlService
  ) {}

  ngOnInit(): void {
    this.i18n.setLocale(en_GB);
    this.router.events
      .pipe(
        filter((e): e is Scroll => e instanceof Scroll),
        pairwise(),
        pipe(takeUntil(this.destroy$))
      )
      .subscribe((eventPair) => {
        const previousEvent = eventPair[0];
        const event = eventPair[1];
        if (event.position) {
          // backward navigation
          this.viewportScroller.scrollToPosition(event.position);
        } else if (event.anchor) {
          // anchor navigation
          this.viewportScroller.scrollToAnchor(event.anchor);
        } else {
          // forward navigation
          if (previousEvent.routerEvent.urlAfterRedirects.split('?')[0] !== event.routerEvent.urlAfterRedirects.split('?')[0]) {
            // Routes don't match, this is actual forward navigation
            // Default behavior: scroll to top
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }
      });

    this.utilsService.removeInitLoader();
  }

  @HostListener('window:click', ['$event'])
  checkCustomUrl(e: any): void {
    const path = e.composedPath() as Array<any>;
    const firstAnchor = path?.find((p) => p?.tagName?.toLowerCase() === 'a');
    const href = firstAnchor?.getAttribute('href');

    if (href?.indexOf('http') === 0 && !href?.includes('/documents')) {
      if (this.customUrlService.isReallyCustomUrl(href)) {
        window.open(href);
      } else {
        if (isDevMode()) {
          console.log('checkCustomUrl() has ran & url has changed!');
        }
        const path = this.customUrlService.getUrlPath(href);
        const params = this.customUrlService.getUrlParams(href);
        this.router.navigate(<any>path, { queryParams: params });
      }

      e.preventDefault();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
