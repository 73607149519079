import { Component } from '@angular/core';
import { DialogConfig } from '../../custom-marker-injector';

@Component({
  selector: 'app-custom-marker',
  templateUrl: './experience-custom-marker.component.html',
  styleUrls: ['./experience-custom-marker.component.scss'],
})
export class ExperienceCustomMarkerComponent {
  constructor(public config: DialogConfig) {}
}
