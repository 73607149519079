<div class="grey-block">
  <div class="wrapper">
    <div class="social col-12">
      <span class="share-iconlist">
        <span class="share-bar-title">Megosztás</span>
        <a tabindex="0" (click)="facebookShare()" [attr.aria-label]="'label.share-this-article-facebook' | translate">
          <i class="icon icon-facebook-red"></i>
        </a>
        <a tabindex="0" (click)="twitterShare()" [attr.aria-label]="'label.share-this-article-twitter' | translate">
          <i class="icon icon-twitter-red"></i>
        </a>
        <a
          class="icon icon-mail-red"
          href="mailto:?subject={{ title }}&body={{ location }}"
          [attr.aria-label]="'label.send-email' | translate"
        >
        </a>
      </span>
    </div>
  </div>
</div>
