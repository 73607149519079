<section class="details" *ngIf="componentObject">
  <div class="container">
    <app-dropdown-button [label]="'label.practical-infos' | translate" (eventEmitter)="onDropdownClick($event)"></app-dropdown-button>

    <div class="details__wrapper" [class.active]="isActive">
      <div class="details__pre-info">{{ 'label.details.preinfo' | translate }}</div>

      <div class="details__inner">
        <h4 class="details__headline" *ngIf="componentObject?.label">{{ componentObject.label }}</h4>

        <div class="row">
          <div class="col-md-6 col-lg-4">
            <ng-container
              *ngIf="
                componentObject?.address?.settlement_name || componentObject?.address?.public_place || componentObject?.address?.postal_code
              "
            >
              <div class="details__title">{{ 'label.address' | translate }}:</div>
              <span class="details__content">
                <ng-container *ngIf="componentObject?.address?.postal_code">{{ componentObject.address.postal_code }}</ng-container>
                <ng-container *ngIf="componentObject?.address?.settlement_name"
                  >, {{ componentObject.address.settlement_name }}</ng-container
                >
                <ng-container *ngIf="componentObject?.address?.public_place">, {{ componentObject.address.public_place }}</ng-container>
                <ng-container *ngIf="componentObject?.address?.street_number"> {{ componentObject.address.street_number }}</ng-container>
              </span>
              <a
                [href]="'https://maps.google.com/?q=' + componentObject.location.latitude + ',' + componentObject.location.longitude"
                *ngIf="componentObject?.location?.latitude && componentObject?.location?.longitude"
                target="_blank"
                class="details__link"
                >{{ 'label.how-to-get-there' | translate }}</a
              >
            </ng-container>

            <ng-container *ngIf="email?.length || phone?.length">
              <div class="details__title">{{ 'label.contact' | translate }}:</div>
              <ng-container *ngIf="email?.length">
                <a [href]="'mailto: ' + item | safe: 'url'" *ngFor="let item of email" class="details__content">{{ item }}</a>
              </ng-container>
              <ng-container *ngIf="phone?.length">
                <a [href]="'callto: ' + phone[0] | safe: 'url'" class="details__content">{{ phone[0] }}</a>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="this.componentObject?.web?.web || facebook || instagram">
              <div class="details__title">{{ 'label.web-and-social' | translate }}:</div>
              <a
                [href]="decodeURIComponent(this.componentObject.web.web)"
                *ngIf="this.componentObject?.web?.web"
                target="_blank"
                class="details__link"
                >{{ decodeURIComponent(this.componentObject.web.web) }}</a
              >
              <a [href]="decodeURIComponent(facebook)" *ngIf="facebook" target="_blank" class="details__social">
                <i class="icon icon-facebook-red"></i>
              </a>
              <a [href]="decodeURIComponent(instagram)" *ngIf="instagram" target="_blank" class="details__social">
                <i class="icon icon-instagram-red"></i>
              </a>
            </ng-container>
          </div>

          <div class="col-md-6 col-lg-4" *ngIf="this.componentObject?.opening">
            <app-opening-hours [opening]="this.componentObject?.opening" *ngIf="this.componentObject?.opening"></app-opening-hours>
          </div>

          <div class="col-md-6 col-lg-4">
            <div class="details__accessibility">
              <div class="details__title">{{ 'label.accessibility' | translate }}:</div>
              <i class="icon icon-accessibility" *ngIf="componentObject?.accessibility?.is_accessible_physically"></i>
              <i class="icon icon-accessibility-hearing" *ngIf="componentObject?.accessibility?.is_accessible_for_the_deaf"></i>
              <i class="icon icon-accessibility-vision" *ngIf="componentObject?.accessibility?.is_accessible_for_the_blind"></i>
              <span
                *ngIf="
                  !componentObject?.accessibility?.is_accessible_physically &&
                  !componentObject?.accessibility?.is_accessible_for_the_deaf &&
                  !componentObject?.accessibility?.is_accessible_for_the_blind
                "
              >
                {{ 'label.attraction-not-accessible' | translate }}
              </span>
            </div>

            <div class="details__options">
              <div class="details__title">{{ 'label.spoken-languages' | translate }}:</div>
              <span *ngIf="this.componentObject['mtu-neta:service_in_foreign_languages']?.length" class="details__options-item">
                {{ this.componentObject['mtu-neta:service_in_foreign_languages'][0]['mtu-neta:languages'] }}
              </span>
              <span *ngIf="!this.componentObject['mtu-neta:service_in_foreign_languages']?.length">
                {{ 'label.attraction.no-spoken-languages' | translate }}
              </span>
            </div>

            <div class="details__options">
              <div class="details__title">{{ 'label.payment-options' | translate }}:</div>
              <ng-container *ngIf="paymentMethods?.length">
                <i class="icon icon-ccard"></i>
                <span *ngFor="let opt of paymentMethods" class="details__options-item">{{ 'label.' + opt | translate }}</span>
                <span *ngIf="vouchers?.length" class="details__options-item">{{ 'label.coupon' | translate }}</span>
              </ng-container>
              <span *ngIf="!paymentMethods?.length">{{ 'label.attraction-is-free' | translate }}</span>
            </div>

            <div class="details__options">
              <div class="details__title">{{ 'label.coupons' | translate }}:</div>
              <ng-container *ngIf="vouchers?.length">
                <i class="icon icon-ccard"></i>
                <span *ngFor="let opt of vouchers" class="details__options-item">{{ opt }}</span>
              </ng-container>
              <span *ngIf="!vouchers?.length">{{ 'label.attraction.no-coupons' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
