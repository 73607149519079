<section class="filter" [appBackgroundColor]="'red'">
  <div class="wrapper row">
    <h3 class="col-12 title hidden-mobile">
      {{ 'label.filter-articles-title' | translate }}
    </h3>

    <div class="col-lg-4">
      <div class="search-input">
        <i class="icon icon-search"></i>
        <label class="cdk-visually-hidden" for="fc-search">{{ 'label.filter-search-placeholder' | translate }}</label>
        <input
          type="search"
          placeholder="{{ 'label.filter-search-placeholder' | translate }}"
          (keydown.enter)="onSearch()"
          [formControl]="searchControl"
          id="fc-search"
        />
        <i class="icon icon-arrow-right-white" (click)="onSearch()"></i>
      </div>
    </div>

    <div class="col-12 col-lg-4">
      <button
        class="selectlike-button white type-b"
        (click)="toggleFilter('destinations')"
        [ngClass]="{ active: filterOpened['destinations'] }"
      >
        <i class="icon icon-pin-white hidden-desktop"></i>
        {{ 'label.filter-destination-placeholder' | translate }}
      </button>
      <div
        class="selectlike-content-type-a wide"
        (mouseleave)="toggleFilter('destinations')"
        [ngClass]="{ opened: filterOpened['destinations'] }"
      >
        <div *ngIf="filterOpened['destinations']" class="row dropdown-content-row destination">
          <div class="col-12 col-lg-6">
            <ng-container *ngFor="let filterOption of activeFilters.destinations; let i = index">
              <div
                *ngIf="i < activeFilters.destinations.length / 2"
                class="option"
                (click)="onOptionClick(filterOption)"
                (keydown.enter)="onOptionClick(filterOption)"
                tabindex="0"
              >
                <div class="checker" [ngClass]="{ checked: filterOption.checked }">
                  <div class="check"></div>
                </div>
                <span class="text">{{ filterOption.text }}</span>
              </div>
            </ng-container>
          </div>
          <div class="col-12 col-lg-6">
            <ng-container *ngFor="let filterOption of activeFilters.destinations; let i = index">
              <div
                *ngIf="i >= activeFilters.destinations.length / 2"
                class="option"
                (click)="onOptionClick(filterOption)"
                (keydown.enter)="onOptionClick(filterOption)"
                tabindex="0"
              >
                <div class="checker" [ngClass]="{ checked: filterOption.checked }">
                  <div class="check"></div>
                </div>
                <span class="text">{{ filterOption.text }}</span>
              </div>
            </ng-container>
          </div>
          <div class="col-12">
            <button class="clear-filter-button red" (click)="onFilterClear('destinations')">
              <i class="icon icon-close-white"></i>
              <i class="icon icon-close-red"></i>
              {{ 'label.clear-filter' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-4">
      <button class="selectlike-button white type-b" (click)="toggleFilter('thematics')" [ngClass]="{ active: filterOpened['thematics'] }">
        <i class="icon icon-filter hidden-desktop"></i>
        {{ 'label.filter-thematic-placeholder' | translate }}
      </button>
      <div
        class="selectlike-content-type-a xxl"
        (mouseleave)="toggleFilter('destinations')"
        [ngClass]="{ opened: filterOpened['thematics'] }"
      >
        <div *ngIf="filterOpened['thematics']" class="row dropdown-content-row thematics">
          <div class="col-12 col-lg-4">
            <ng-container *ngFor="let filterOption of ThematicFirst">
              <div class="option" (click)="onOptionClick(filterOption)" (keydown.enter)="onOptionClick(filterOption)" tabindex="0">
                <div class="checker" [ngClass]="{ checked: filterOption.checked }">
                  <div class="check"></div>
                </div>
                <span class="text">{{ filterOption.text }}</span>
              </div>
            </ng-container>
          </div>
          <div class="col-12 col-lg-4">
            <ng-container *ngFor="let filterOption of thematicSecond">
              <div class="option" (click)="onOptionClick(filterOption)" (keydown.enter)="onOptionClick(filterOption)" tabindex="0">
                <div class="checker" [ngClass]="{ checked: filterOption.checked }">
                  <div class="check"></div>
                </div>
                <span class="text">{{ filterOption.text }}</span>
              </div>
            </ng-container>
          </div>
          <div class="col-12 col-lg-4">
            <ng-container *ngFor="let filterOption of thematicThird">
              <div class="option" (click)="onOptionClick(filterOption)" (keydown.enter)="onOptionClick(filterOption)" tabindex="0">
                <div class="checker" [ngClass]="{ checked: filterOption.checked }">
                  <div class="check"></div>
                </div>
                <span class="text">{{ filterOption.text }}</span>
              </div>
            </ng-container>
          </div>

          <div class="col-12">
            <button class="clear-filter-button red" (click)="onFilterClear('thematics')">
              <i class="icon icon-close-white"></i>
              <i class="icon icon-close-red"></i>
              {{ 'label.clear-filter' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
