<div class="popup" [routerLink]="['/' | localize, 'experiences' | localize, config.data.slug]">
  <div
    class="image"
    *ngIf="config?.data?.thumbnail?.url"
    [ngStyle]="{ 'background-image': 'url(' + config.data.thumbnail.url + ')' } | ssrEmptyString"
  ></div>
  <div
    class="influencer-img"
    *ngIf="config?.data?.relation?.influencer?.picture?.url"
    [ngStyle]="{ 'background-image': 'url(' + config.data.relation.influencer.picture.url + ')' } | ssrEmptyString"
  ></div>

  <div class="bottom-container">
    <div class="influencer-name">
      <span *ngIf="config?.data?.relation?.influencer?.name">{{ config.data.relation.influencer.name }}</span>
    </div>
    <div class="event-name" *ngIf="config?.data?.title">{{ config.data.title }}</div>
  </div>
</div>
