import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { TrendencyUtilsService } from 'trendency/utils';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

type CookieState = 'accepted' | 'declined';

@Component({
  selector: 'app-cookie-bar',
  templateUrl: './cookie-bar.component.html',
  styleUrls: ['./cookie-bar.component.scss'],
})
export class CookieBarComponent implements OnInit, OnDestroy {
  cookieUrl: string;
  private readonly destroy$ = new Subject();

  constructor(
    private readonly utilsService: TrendencyUtilsService,
    private readonly translate: TranslateService,
    @Inject(PLATFORM_ID) private readonly platformId: any
  ) {}

  ngOnInit(): void {
    if (this.utilsService.isBrowser()) {
      switch (this.cookieState) {
        case 'accepted':
          this.accept();
          break;
        case 'declined':
          this.decline();
          break;
      }
    }

    this.translate
      .get('label.cookie-policy')
      .pipe(takeUntil(this.destroy$))
      .subscribe((text) => {
        this.cookieUrl = this.utilsService.generateSlug(text);
      });
  }

  public get cookieState(): CookieState {
    if (isPlatformBrowser(this.platformId)) {
      return <CookieState>localStorage.getItem('cookieState');
    }
    return 'declined';
  }

  public set cookieState(state: CookieState) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('cookieState', state);
    }
  }

  accept(): void {
    this.cookieState = 'accepted';
    // this.initGoogleAnalytics();
  }

  decline(): void {
    // if (isPlatformBrowser(this.platformId)) {
    //   window[`ga-disable-${environment.googleAnalyticsCode}`] = true;
    // }
    this.cookieState = 'declined';
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
