import { Component, OnInit, Input } from '@angular/core';
import { TrendencyProgressBarService } from '../../services/progress-bar.service';
import { IProgressBarOptions } from '../../loading.definitions';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'corporate-progressbar',
  templateUrl: 'progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})

export class TrendencyProgressBarComponent implements OnInit {
  constructor(
    public progressBarService: TrendencyProgressBarService,
  ) { }

  private defaultOptions: IProgressBarOptions;

  @Input() public options: IProgressBarOptions;

  ngOnInit(): void {
    this.defaultOptions = {
      value: null
    };
    this.options = Object.assign(this.defaultOptions, this.options || {});
  }
}
