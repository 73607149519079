import { Component } from '@angular/core';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-title-text',
  templateUrl: './title-text.component.html',
  styleUrls: ['./title-text.component.scss'],
})
export class TitleTextComponent extends BaseComponent {
  constructor() {
    super();
  }
}
