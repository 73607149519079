<div class="popup">
  <div class="header">
    <img alt="" src="assets/images/tourinform-w.svg" corporateImageLazyLoad />
  </div>
  <a
    [routerLink]="config.data.slug ? ['/' | localize, 'tourinform-office' | localize, config.data.slug] : []"
    [style.cursor]="config.data.slug ? 'pointer' : 'auto'"
    *ngIf="!config?.data?.customLink && config?.data?.slug"
    target="_blank"
  >
  </a>
  <a
    [href]="config?.data?.slug"
    [style.cursor]="config.data.slug ? 'pointer' : 'auto'"
    *ngIf="config?.data?.customLink && config?.data?.slug"
    target="_blank"
  ></a>
  <div
    *ngIf="config?.data?.image"
    [ngStyle]="{ 'background-image': 'url(' + config.data.image + ')' } | ssrEmptyString"
    class="image"
  ></div>
  <div
    *ngIf="!config?.data?.image && config?.data?.imageIcon"
    [ngStyle]="{ 'background-image': 'url(' + config.data.imageIcon + ')' } | ssrEmptyString"
    class="image icon"
  ></div>
  <div class="popup__bottom" *ngIf="config?.data?.category || config?.data?.title || config?.data?.opening">
    <span class="popup__category" *ngIf="config?.data?.category">{{ config.data.category }}</span>
    <span class="popup__title" *ngIf="config?.data?.title">{{ config.data.title }}</span>
    <app-opening-hours [opening]="config?.data?.opening" *ngIf="config?.data?.opening"></app-opening-hours>
  </div>
</div>
