import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss'],
})
export class DropdownButtonComponent {
  @Input() label: string;
  @Output() eventEmitter = new EventEmitter<boolean>();
  isActive = false;

  onDropdownClick(): void {
    this.eventEmitter.emit((this.isActive = !this.isActive));
  }
}
