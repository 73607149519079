import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-experience-headline',
  templateUrl: './experiences-headline.component.html',
  styleUrls: ['./experiences-headline.component.scss'],
})
export class ExperiencesHeadlineComponent {
  @Input() title: string;
  @Input() subtitle: string;
}
