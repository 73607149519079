<section class="filter">
  <div class="wrapper row">
    <div class="col-12 title hidden-mobile">
      {{ 'label.filter-event-title' | translate }}
    </div>

    <div class="col-lg-3 custom-lg-3">
      <div class="search-input">
        <i class="icon icon-search"></i>
        <label class="cdk-visually-hidden" for="ec-search">{{ 'label.filter-search-placeholder' | translate }}</label>
        <input
          type="search"
          placeholder="{{ 'label.filter-search-placeholder' | translate }}"
          (keydown.enter)="onSearch()"
          [formControl]="searchControl"
          id="ec-search"
        />
        <i class="icon icon-arrow-right-white" (click)="onSearch()"></i>
      </div>
    </div>

    <div class="col-12 col-lg-3">
      <button
        class="selectlike-button white type-b"
        (click)="toggleFilter('destinations')"
        [ngClass]="{ active: filterOpened['destinations'] }"
      >
        <i class="icon icon-pin-white hidden-desktop"></i>
        {{ 'label.filter-destination-placeholder' | translate }}
      </button>
      <div class="selectlike-content-type-a wide" (mouseleave)="closeAllFilter()" [ngClass]="{ opened: filterOpened['destinations'] }">
        <div *ngIf="filterOpened['destinations']" class="row dropdown-content-row destination">
          <div class="col-12 col-lg-6">
            <ng-container *ngFor="let filterOption of activeFilters.destinations; let i = index">
              <div
                *ngIf="i < activeFilters.destinations.length / 2"
                class="option"
                (click)="onOptionClick(filterOption)"
                (keydown.enter)="onOptionClick(filterOption)"
                tabindex="0"
              >
                <div class="checker" [ngClass]="{ checked: filterOption.checked }">
                  <div class="check"></div>
                </div>
                <span class="text">{{ filterOption.text }}</span>
              </div>
            </ng-container>
          </div>
          <div class="col-12 col-lg-6">
            <ng-container *ngFor="let filterOption of activeFilters.destinations; let i = index">
              <div
                *ngIf="i >= activeFilters.destinations.length / 2"
                class="option"
                (click)="onOptionClick(filterOption)"
                (keydown.enter)="onOptionClick(filterOption)"
                tabindex="0"
              >
                <div class="checker" [ngClass]="{ checked: filterOption.checked }">
                  <div class="check"></div>
                </div>
                <span class="text">{{ filterOption.text }}</span>
              </div>
            </ng-container>
          </div>
          <div class="col-12">
            <button class="clear-filter-button purple" (click)="onFilterClear('destinations')">
              <i class="icon icon-close-white"></i>
              <i class="icon icon-close-purple"></i>
              {{ 'label.clear-filter' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-3">
      <button class="selectlike-button white type-b" (click)="toggleFilter('dateRange')" [ngClass]="{ active: filterOpened['dateRange'] }">
        <i class="icon icon-calendar-white hidden-desktop"></i>
        {{ 'label.filter-date-placeholder' | translate }}
      </button>
      <div
        class="selectlike-content-type-a wide extra-wide"
        [ngClass]="{ opened: filterOpened['dateRange'] }"
        (mouseleave)="closeAllFilter()"
      >
        <div *ngIf="filterOpened['dateRange']" class="row dropdown-content-row date">
          <div class="col-12">
            <div class="calendar-wrapper">
              <div class="calendar">
                <mat-calendar
                  class="date-range-calendar"
                  #calendar
                  [startAt]="fromDate"
                  [(selected)]="fromDate"
                  (selectedChange)="onFromSelect($event)"
                ></mat-calendar>
              </div>
              <div class="calendar">
                <mat-calendar
                  class="date-range-calendar"
                  #calendar2
                  [startAt]="toDate"
                  [(selected)]="toDate"
                  (selectedChange)="onToSelect($event)"
                ></mat-calendar>
              </div>
            </div>

            <div class="checkfilter-bottom-buttons two-sided">
              <div class="left">
                <div *ngIf="toIsGreater && activeFilters.dateRange && activeFilters.dateRange.length === 2" class="left-wrapper">
                  <span class="date-string">{{ dateLabelSingle(activeFilters.dateRange[0]) }}</span>
                  <span class="date-string">{{ dateLabelSingle(activeFilters.dateRange[1]) }}</span>
                </div>
                <div *ngIf="!toIsGreater" style="text-transform: initial">
                  {{ 'label.date-range-error' | translate }}
                </div>
              </div>
              <div class="right">
                <button class="clear-filter-button purple" (click)="onDateFilterClear()">
                  <i class="icon icon-close-white"></i>
                  <i class="icon icon-close-purple"></i>
                  {{ 'label.clear-filter' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-3">
      <button class="selectlike-button white type-b" (click)="toggleFilter('thematics')" [ngClass]="{ active: filterOpened['thematics'] }">
        <i class="icon icon-filter hidden-desktop"></i>
        {{ 'label.filter-thematic-placeholder' | translate }}
      </button>
      <div class="selectlike-content-type-a wide" (mouseleave)="closeAllFilter()" [ngClass]="{ opened: filterOpened['thematics'] }">
        <div *ngIf="filterOpened['thematics']" class="row dropdown-content-row thematics">
          <div class="col-12">
            <div
              class="option"
              *ngFor="let filterOption of activeFilters.thematics"
              (click)="onOptionClick(filterOption)"
              (keydown.enter)="onOptionClick(filterOption)"
              tabindex="0"
            >
              <div class="checker" [ngClass]="{ checked: filterOption.checked }">
                <div class="check"></div>
              </div>
              <span class="text">{{ filterOption.text }}</span>
            </div>
            <button class="clear-filter-button purple" (click)="onFilterClear('thematics')">
              <i class="icon icon-close-white"></i>
              <i class="icon icon-close-purple"></i>
              {{ 'label.clear-filter' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="container button-wrapper">
  <button class="button button-archive" [class.active]="activeFilters.isArchive" (click)="toggleArchive()">
    {{ 'label.archive' | translate }}
  </button>
</div>
