<section class="centered-carousel" #mainWrapper [ngClass]="{ 'type-b': typeB }" id="{{ componentObject | titleToId }}">
  <div class="centered-carousel-head desktop">
    <ng-container [ngTemplateOutlet]="centerHeaderContent"></ng-container>
  </div>

  <div class="green-bg">
    <div class="centered-carousel-head mobile">
      <ng-container [ngTemplateOutlet]="centerHeaderContent"></ng-container>
    </div>

    <div
      #centeredCarouselObjElement
      config="[carouselOptions]"
      class="wrapper centered-carousel-obj"
      [ngClass]="{ 'move-anim-init': moveAnimInited }"
      (click)="goToPos($event)"
    >
      <div *ngFor="let card of componentObject.subComponents; let i = index">
        <div>
          <div class="slide">
            <div class="card-header">
              <b *ngIf="card.event && card.event.eventDateFrom && card.event.eventDateTill">
                <i class="icon icon-calendar-white"></i
                ><span [innerHTML]="dateLabel(card.event.eventDateFrom, card.event.eventDateTill)"></span>
              </b>
              <div class="event-location-label" *ngIf="card.event && card.event.eventLocation">
                <i class="icon icon-pin-white"></i><span>{{ card.event.eventLocation }}</span>
              </div>
            </div>

            <div class="card-wrapper">
              <app-link-card [data]="card"></app-link-card>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a
      *ngIf="componentObject.buttonLabel"
      [href]="componentObject.buttonUrl"
      [appBackgroundColor]="'red'"
      class="button button-primary bottom-button"
      >{{ componentObject.buttonLabel }}
    </a>

    <div class="arrows-container">
      <button class="arrow arrow-prev" (click)="prev()" [attr.aria-label]="'label.prev' | translate">{{ 'label.prev' | translate }}</button>
      <button class="arrow arrow-next" (click)="next()" [attr.aria-label]="'label.next' | translate">{{ 'label.next' | translate }}</button>
    </div>

    <ul class="slick-dots custom-slick-dots">
      <ng-container *ngFor="let card of componentObject.subComponents; let i = index">
        <li *ngIf="i % 3 === 0" [ngClass]="{ 'slick-active': isCustomDotActive(i) }">
          <button
            type="button"
            (click)="customDot(i)"
            [attr.aria-label]="
              ('label.jump-to' | translate) +
              ' ' +
              (i + 1) +
              '/' +
              componentObject.subComponents.length +
              ' ' +
              ((isCustomDotActive(i) ? 'label.active' : 'label.inactive') | translate)
            "
          ></button>
        </li>
      </ng-container>
    </ul>
  </div>
</section>

<ng-template #centerHeaderContent>
  <h2 *ngIf="componentObject && componentObject.title">{{ componentObject.title }}</h2>
  <p *ngIf="componentObject && componentObject.subtitle" class="desc">{{ componentObject.subtitle }}</p>
  <div *ngIf="thematicIconKey" [ngClass]="'spec-icon ' + thematicIconKey"></div>
</ng-template>
