import { Injectable, Inject, Optional } from '@angular/core';
import { Meta, Title, MetaDefinition } from '@angular/platform-browser';
import { IMetaData } from '../utils.definitions';
import { TrendencyUtilsService } from './utils.service';
import { REQUEST } from '@nguniversal/express-engine/tokens';

@Injectable()
export class TrendencySeoService {

  constructor(
    private meta: Meta,
    private titleService: Title,
    private utilsService: TrendencyUtilsService,
    @Optional() @Inject(REQUEST) private request: any
  ) { }

  get hostUrl() {
    return this.utilsService.isBrowser()
      ? document.location.origin
      : `${this.request.protocol}://${this.request.get('host')}`;
  }

  get currentUrl() {
    return this.utilsService.isBrowser()
      ? document.location.href
      : `${this.hostUrl}${this.request.url}`;
  }



  public setMetaData(metaData: IMetaData) {
    if (metaData.title) {
      this.setTitle(metaData.title);
    }
    this.updateTag({
      name: 'description',
      content: metaData.description || ''
    });
    this.updateTag({
      name: 'keywords',
      content: metaData.keywords || ''
    });
    this.updateTag({
      name: 'og:title',
      property: 'og:title',
      content: metaData.ogTitle || ''
    });
    this.updateTag({
      name: 'og:url',
      property: 'og:url',
      content: metaData.ogUrl || this.currentUrl || ''
    });
    this.updateTag({
      name: 'og:image',
      property: 'og:image',
      content: metaData.ogImage || `${this.hostUrl}/assets/images/og_image.webp` || ''
    });
    this.updateTag({
      name: 'og:image:secure_url',
      property: 'og:image:secure_url',
      content: metaData.ogImage || `${this.hostUrl}/assets/images/og_image.webp` || ''
    });
    this.updateTag({
      name: 'og:image:alt',
      property: 'og:image:alt',
      content: metaData.ogImageAlt || ''
    });
    this.updateTag({
      name: 'og:type',
      property: 'og:type',
      content: metaData.ogType || 'website'
    });
    this.updateTag({
      name: 'og:description',
      property: 'og:description',
      content: metaData.ogDescription || metaData.description || ''
    });
    this.updateTag({
      name: 'og:locale',
      content: metaData.ogLocale || 'en_GB'
    });
    this.updateTag({
      name: 'og:site_name',
      property: 'og:site_name',
      content: metaData.ogSiteName || metaData.ogTitle || metaData.title || ''
    });
    this.updateTag({
      name: 'article:published_time',
      property: 'article:published_time',
      content: metaData.articlePublishedTime || ''
    });
    this.updateTag({
      name: 'article:author',
      property: 'article:author',
      content: metaData.articleAuthor || ''
    });
    this.updateTag({
      name: 'twitter:card',
      content: 'summary_large_image'
    });
    this.updateTag({
      name: 'twitter:site',
      content: metaData.twitterSiteName || metaData.ogSiteName || ''
    });
    this.updateTag({
      name: 'twitter:creator',
      content: metaData.articleAuthor || ''
    });
    this.updateTag({
      name: 'twitter:title',
      content: metaData.twitterTitle || metaData.ogTitle || metaData.title || ''
    });
    this.updateTag({
      name: 'twitter:description',
      content: metaData.twitterDescription || metaData.ogDescription || metaData.description || ''
    });
    this.updateTag({
      name: 'twitter:image',
      content: metaData.twitterImage || metaData.ogImage || `${this.hostUrl}/assets/images/og_image.webp`,
    });
    this.updateTag({
      name: 'twitter:image:alt',
      content: metaData.ogImageAlt || '',
    });
  }

  private updateTag(tag: MetaDefinition) {
    this.meta.updateTag(tag);
  }

  public setTitle(title: string) {
    this.titleService.setTitle(title);
  }

  public getTitle() {
    return this.titleService.getTitle();
  }
}
