import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IApplicationFilters, IFilterOption } from '../shared.definitions';

@Injectable({
  providedIn: 'root',
})
export class CollectionFilterService {
  private readonly defaultFilters: IApplicationFilters = {
    search: '',
    destinations: [],
    settlements: [],
    serviceTypes: [],
    summerServices: [],
    acceptsCardType: [],
    accomodationType: [],
    accomodationClass: [],
    suburb: [],
    city: [],
  };

  private readonly filtersSubject$ = new BehaviorSubject<IApplicationFilters>(this.defaultFilters);

  filters$ = this.filtersSubject$.asObservable(); // Observable for components to subscribe to

  getFilters(): IApplicationFilters {
    return this.filtersSubject$.value;
  }

  updateFilters(filters: IApplicationFilters): void {
    this.filtersSubject$.next(filters);
  }

  updateFilter<T>(key: keyof IApplicationFilters, value: T): void {
    const currentFilters = this.getFilters();

    const updatedFilters: IApplicationFilters = {
      ...currentFilters,
      [key]: value,
    };

    this.filtersSubject$.next(updatedFilters);
  }

  getCurrentFilters(): IApplicationFilters {
    return this.filtersSubject$.value;
  }

  hasActiveFilters(): boolean {
    const currentFilters = this.getFilters();

    for (const key in currentFilters) {
      const filterOptions = currentFilters[key];

      if (Array.isArray(filterOptions)) {
        if ((filterOptions as IFilterOption[]).some((filterOption) => filterOption.checked)) {
          return true;
        }
      } else if (typeof filterOptions === 'string' && filterOptions.trim() !== '') {
        return true;
      }
    }

    return false;
  }

  resetFilters(): void {
    const currentFilters = this.getFilters();
    const updatedFilters: IApplicationFilters = { ...currentFilters };

    for (const key in this.defaultFilters) {
      if (Array.isArray(currentFilters[key])) {
        updatedFilters[key] = (currentFilters[key] as IFilterOption[]).map((filterOption: IFilterOption) => ({
          ...filterOption,
          checked: false,
        }));
      } else {
        updatedFilters[key as keyof IApplicationFilters] = this.defaultFilters[key];
      }
    }

    this.filtersSubject$.next(updatedFilters);
  }

  resetFilter(key: keyof IApplicationFilters): void {
    const currentFilters = this.getFilters();
    const updatedFilters: IApplicationFilters = {
      ...currentFilters,
      [key]: (currentFilters[key] as IFilterOption[]).map((filterOption: IFilterOption) => ({
        ...filterOption,
        checked: false,
      })),
    };

    this.filtersSubject$.next(updatedFilters);
  }
}
