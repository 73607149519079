/// <reference types="google.maps" />
import { Component, OnInit } from '@angular/core';
import { TrendencySeoService } from 'trendency/utils';
import { BaseComponent } from '../base.component';
import { ExperienceCustomMarkerComponent } from '../full-width-map/custom-markers/experience-custom-marker/experience-custom-marker.component';
import { IMarker } from '../full-width-map/full-width-map.component';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent extends BaseComponent implements OnInit {
  title;
  lead;
  thematicIcon;
  location;
  email;
  buttonUrl;
  marker: IMarker[] = [];
  experienceTags;

  constructor(private readonly seo: TrendencySeoService) {
    super();
  }

  ngOnInit(): void {
    if (this.componentObject) {
      this.title = this.componentObject.title ? this.componentObject.title : '';
      this.lead = this.componentObject.lead ? this.componentObject.lead : '';
      this.thematicIcon = this.componentObject.thematicIcon ? this.componentObject.thematicIcon : undefined;
    }
    if (this.extraData) {
      if (this.extraData.lat && this.extraData.lng) {
        this.marker.push({
          coordinate: { lat: +this.extraData.lat, lng: +this.extraData.lng },
          component: ExperienceCustomMarkerComponent,
          data: this.extraData,
        });
        this.buttonUrl = `https://www.google.com/maps/search/?api=1&query=${this.extraData.lat},${this.extraData.lng}`;
      }
      if (this.extraData.relation.experienceTags && this.extraData.relation.experienceTags.length) {
        this.experienceTags = this.extraData.relation.experienceTags;
      }
    }
    this.location = this.seo.currentUrl;
    this.email = this.seo.getTitle();
  }

  public facebookShare(): void {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.location}`, 'pop', 'width=600, height=400, scrollbars=no');
  }

  public twitterShare(): void {
    window.open(`https://twitter.com/intent/tweet?url=${this.location}`, 'pop', 'width=600, height=400, scrollbars=no');
  }
}
