// TODO: Update whenever app routing changes, or new subcomponent type is created
export const ROUTES_MAP = {
  Article: 'articles',
  Collection: 'collection',
  Destination: 'destination',
  Trip: 'trips',
  // StaticPage: '',
  CategoryDescription: 'category',
  Event: 'events',
  DocumentsByGroup: ['downloads', 'group'],
  DocumentsByDestination: ['downloads', 'destination'],
  Documents: 'downloads',
};
