import { Injectable, OnDestroy } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { ExperienceCustomMarkerComponent } from '../shared/components/full-width-map/custom-markers/experience-custom-marker/experience-custom-marker.component';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { IMarker } from '../shared/components/full-width-map/full-width-map.component';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class ExperienceCollectionService implements OnDestroy {
  allEvent: string;
  loadMaxExperience: number;
  experiencesFilterMap: Map<any, any>;
  private readonly destroy$ = new Subject();

  private readonly randomExperiencesAllSource$ = new BehaviorSubject<Array<any>>([]);
  randomExperiencesAll$ = this.randomExperiencesAllSource$.asObservable();

  private readonly randomExperiencesSource$ = new BehaviorSubject<Array<any>>([]);
  randomExperiences$ = this.randomExperiencesSource$.asObservable();

  private readonly markersSource$ = new BehaviorSubject<Array<IMarker>>([]);
  markers$ = this.markersSource$.asObservable();

  private readonly filtersSource$ = new BehaviorSubject<Array<any>>([]);
  filters$ = this.filtersSource$.asObservable();

  private readonly filtersOpenedSource$ = new BehaviorSubject<boolean>(false);
  filtersOpened$ = this.filtersOpenedSource$.asObservable();

  private readonly selectedFilterTitleSource$ = new BehaviorSubject<string>('');
  selectedFilterTitle$ = this.selectedFilterTitleSource$.asObservable();

  private readonly loaderCounterSource$ = new BehaviorSubject<number>(1);
  loaderCounter$ = this.loaderCounterSource$.asObservable();

  private readonly experienceCounterSource$ = new BehaviorSubject<number>(0);
  experienceCounter$ = this.experienceCounterSource$.asObservable();

  private readonly influencersSource$ = new BehaviorSubject<Array<any>>([]);
  influencers$ = this.influencersSource$.asObservable();

  constructor(private readonly apiService: ApiService, private readonly translate: TranslateService) {
    this.translate
      .get('label.all-event')
      .pipe(takeUntil(this.destroy$))
      .subscribe((translateVal) => {
        this.allEvent = translateVal;
        this.selectedFilterTitleSource$.next(translateVal);
      });
  }

  getExperiences$(): Observable<any> {
    return this.apiService.getFilteredExperiences$(0, 700);
  }

  transformExperiencesData(res): void {
    if (res.data) {
      this.fetchRandomExperiences(res.data);
      this.fetchExperiencesFilterMap(res.meta.filterOptions.experienceTags_filter);
      this.fetchFilters(res.data);
      this.fetchMarkers(res.data);
      this.fetchInfluencers(res.data);
    }
  }

  fetchMarkers(experiences: any[]): void {
    const markers = [];
    experiences.forEach((experience) => {
      if (experience.lat && experience.lng) {
        markers.push({
          coordinate: { lat: +experience.lat, lng: +experience.lng },
          component: ExperienceCustomMarkerComponent,
          data: experience,
        });
      }
    });
    this.markersSource$.next(markers);
  }

  fetchFilters(experiences: any[]): void {
    const filters = new Map();
    experiences.forEach((experience) => {
      experience.relation.experienceTags.forEach((tag) => {
        filters.get(tag.label) ? filters.set(tag.label, filters.get(tag.label) + 1) : filters.set(tag.label, 1);
      });
    });
    this.filtersSource$.next(Array.from(filters));
  }

  fetchRandomExperiences(experiences: any[]): void {
    const random: any[] = this.shuffle(experiences);
    this.randomExperiencesAllSource$.next(random);
    this.randomExperiencesSource$.next(random.slice(0, this.loadMaxExperience));
    this.loaderCounterSource$.next(1);
    this.experienceCounterSource$.next(experiences.length);
  }

  shuffle(array: any[]): any[] {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  fetchExperiencesFilterMap(experiences: any[]): void {
    this.experiencesFilterMap = new Map();
    Object.keys(experiences).forEach((key) => {
      if (!this.experiencesFilterMap.get(experiences[key])) {
        this.experiencesFilterMap.set(experiences[key], +key);
      }
    });
  }

  toggleFilters(): void {
    this.filtersOpenedSource$.next(!this.filtersOpenedSource$.getValue());
  }

  loadMore(): void {
    this.loaderCounterSource$.next(this.loaderCounterSource$.getValue() + 1);
    this.randomExperiencesSource$.next(
      this.randomExperiencesAllSource$.getValue().slice(0, this.loaderCounterSource$.getValue() * this.loadMaxExperience)
    );
  }

  selectFilterElem(filter: string): void {
    this.toggleFilters();

    if (filter === 'all') {
      this.selectedFilterTitleSource$.next(this.allEvent);
      this.apiService.getFilteredExperiences$(0, 700).subscribe((res) => {
        if (res.data) {
          this.fetchRandomExperiences(res.data);
          this.fetchMarkers(res.data);
        }
      });
    } else {
      this.selectedFilterTitleSource$.next(filter);
      const serviceTypeTag = this.experiencesFilterMap.get(filter);
      this.apiService.getFilteredExperiences$(0, 700, serviceTypeTag).subscribe((res) => {
        if (res.data) {
          this.fetchRandomExperiences(res.data);
          this.fetchMarkers(res.data);
        }
      });
    }
  }

  fetchInfluencers(experiences: any[]): void {
    const influencers = [];
    experiences.forEach((experience) => {
      if (experience.relation.influencer) {
        if (influencers.length > 0) {
          const influancerNames = influencers.map((e) => e.name);
          if (!influancerNames.includes(experience.relation.influencer.name)) {
            influencers.push(experience.relation.influencer);
          }
        } else {
          influencers.push(experience.relation.influencer);
        }
      }
    });
    this.influencersSource$.next(influencers);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
