import { Injectable } from '@angular/core';
import { TrendencyUtilsService } from './utils.service';

@Injectable()
export class TrendencyStorageService {
  constructor(
    private utilsService: TrendencyUtilsService
  ) { }

  public setCookie(key: string, data: any, expirationInSecs?: number) {
    if (this.utilsService.isBrowser()) {
      const expiration = typeof expirationInSecs !== 'undefined'
        ? new Date(new Date().getTime() + expirationInSecs * 1000)
        : null;
      document.cookie = `${key}=${JSON.stringify(data)}; path=/; ${ expiration ? `expires=${expiration.toUTCString()}` : '' }`;
    }
  }

  public getCookie(key: string, cookies: string, defaultValue: any = null): string {
    const value = `; ${cookies}`;
    const parts = value.split(`; ${key}=`);
    return parts.length === 2 ? parts.pop().split(';').shift() : defaultValue;
  }

  public setSessionStorageData(key: string, data: any) {
    if (this.utilsService.isBrowser()) {
      sessionStorage.setItem(key, JSON.stringify(data));
    }
  }

  public getSessionStorageData(key: string, defaultValue: any = null) {
    if (this.utilsService.isBrowser()) {
      const savedData = JSON.parse(sessionStorage.getItem(key));
      return savedData || defaultValue;
    }
    return defaultValue;
  }

  public setLocalStorageData(key: string, data: any) {
    if (this.utilsService.isBrowser()) {
      localStorage.setItem(key, JSON.stringify(data));
    }
  }

  public getLocalStorageData(key: string, defaultValue: any = null) {
    if (this.utilsService.isBrowser()) {
      const savedData = JSON.parse(localStorage.getItem(key));
      return savedData || defaultValue;
    }
    return defaultValue;
  }
}
