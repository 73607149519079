import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TrendencyUtilsService } from '../../utils/services/utils.service';

@Injectable()
export class TrendencyFullscreenLoaderService {

  private showLoaderSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public showLoader: Observable<boolean> = this.showLoaderSubject.asObservable();

  constructor(
    private utilsService: TrendencyUtilsService,
  ) { }

  public show() {
    if (this.utilsService.isBrowser()) {
      this.showLoaderSubject.next(true);
    }
  }

  public hide() {
    if (this.utilsService.isBrowser()) {
      this.showLoaderSubject.next(false);
    }
  }
}
