<div class="card-popup">
  <div class="card-content">
    <div class="card-left">
      <h2>Most 20% kedvezménnyel foglalhatsz SZÉP-kártyával januárra és februárra!</h2>
      <p>A kedvezményes szálláshelyeket itt találod:</p>
      <button (click)="navigateTo()" class="btn btn-desktop">MEGNÉZEM</button>
    </div>
    <div class="card-right">
      <button (click)="navigateTo()" class="btn btn-mobile">MEGNÉZEM</button>
    </div>
  </div>
</div>
