<div class="experience-filter">
  <div class="left">
    <div class="selectlike-container">
      <button class="selectlike-button" (click)="toggleFilters()" [appBackgroundColor]="'white'">{{ selectedFilterTitle }}</button>
      <div class="selectlike-content-type-a" [ngClass]="{ opened: filtersOpened }">
        <button (click)="selectFilter('all')" class="element">{{ 'label.all-event' | translate }}</button>
        <button *ngFor="let filter of filters" (click)="selectFilter(filter[0])" class="element">
          <span class="filter-name">{{ filter[0] }}</span>
          <span class="filter-count">({{ filter[1] }})</span>
        </button>
      </div>
    </div>
  </div>
  <div class="right">
    <app-pin-checkbox
      *ngIf="checkedInfluencer !== undefined"
      [label]="'HÍRESSÉGEK'"
      [pinColor]="'red'"
      [checked]="checkedInfluencer"
      [type]="filterType"
      (check)="toggleInfluencer()"
    >
    </app-pin-checkbox>
    <app-pin-checkbox
      *ngIf="checkedUser !== undefined"
      [label]="'FELHASZNÁLÓK'"
      [pinColor]="'purple'"
      [checked]="checkedUser"
      [type]="filterType"
      (check)="toggleUser()"
    >
    </app-pin-checkbox>
    <button
      class="button"
      *ngIf="mapView !== undefined"
      (click)="toggleView()"
      [ngClass]="{ 'button-primary': !mapView, 'button-secondary': mapView }"
      [appBackgroundColor]="mapView ? 'white' : 'red'"
    >
      <i class="icon icon-list-view" *ngIf="mapView"></i>
      <i class="icon icon-list-view-white" *ngIf="!mapView"></i>
      {{ 'label.list-view' | translate }}
    </button>
    <button
      class="button"
      *ngIf="mapView !== undefined"
      (click)="toggleView()"
      [ngClass]="{ 'button-primary': mapView, 'button-secondary': !mapView }"
      [appBackgroundColor]="mapView ? 'red' : 'white'"
    >
      <i class="icon icon-pin-white" *ngIf="mapView"></i>
      <i class="icon icon-pin-red" *ngIf="!mapView"></i>
      {{ 'label.map-view' | translate }}
    </button>
  </div>
</div>
