import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-experience-filter',
  templateUrl: './experience-filter.component.html',
  styleUrls: ['./experience-filter.component.scss'],
})
export class ExperienceFilterComponent {
  @Input() filters: [];
  @Input() selectedFilterTitle: string;
  @Input() filtersOpened: boolean;
  @Input() mapView: boolean;
  @Input() filterType: string;
  @Input() checkedUser: boolean;
  @Input() checkedInfluencer: boolean;

  @Output() toggleFiltersEvent = new EventEmitter<void>();
  @Output() selectFilterEvent = new EventEmitter<string>();
  @Output() toggleViewEvent = new EventEmitter<void>();
  @Output() toggleUserEvent = new EventEmitter<void>();
  @Output() toggleInfluencerEvent = new EventEmitter<void>();

  toggleFilters(): void {
    this.toggleFiltersEvent.emit();
  }

  selectFilter(filter: string): void {
    this.selectFilterEvent.emit(filter);
  }

  toggleView(): void {
    this.toggleViewEvent.emit();
  }

  toggleUser(): void {
    this.toggleUserEvent.emit();
  }

  toggleInfluencer(): void {
    this.toggleInfluencerEvent.emit();
  }
}
