import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TextComponent extends BaseComponent implements OnInit {
  text: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.text = this.componentObject.text;
  }
}
