import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-experience-load-more',
  templateUrl: './experiences-load-more.component.html',
  styleUrls: ['./experiences-load-more.component.scss'],
})
export class ExperiencesLoadMoreComponent {
  @Input() experienceCounter: number;
  @Input() loadMaxExperience: number;
  @Input() loaderCounter: number;
  @Input() buttonText: string;
  @Output() loadMoreClickEvent = new EventEmitter<void>();

  onLoadMoreClick(): void {
    this.loadMoreClickEvent.emit();
  }
}
