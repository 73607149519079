import { Component } from '@angular/core';

@Component({
  selector: 'app-card-popup',
  templateUrl: './card-popup.component.html',
  styleUrls: ['./card-popup.component.scss'],
})
export class CardPopupComponent {
  navigateTo(): void {
    window.open('https://csodasmagyarorszag.hu/szep', '_self');
  }
}
