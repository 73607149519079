import { NgModule } from '@angular/core';

import { BaseComponent } from './base.component';
import { BaseResolver } from './base.resolver';
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../footer/footer.module';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, HeaderModule, FooterModule, SharedModule],
  exports: [],
  declarations: [BaseComponent],
  providers: [BaseResolver],
})
export class BaseModule {}
