<section class="trip-day">
  <div class="day-header">
    <h2 class="day-label">{{ componentObject.title }}</h2>
    <div class="day-description">
      {{ componentObject.lead }}
    </div>
    <div [ngClass]="'specicon ' + componentObject.thematicIcon.key" class="specicon" *ngIf="componentObject.thematicIcon"></div>
  </div>
  <div class="wrapper day-content">
    <app-trip-program
      [data]="program"
      [first]="i === 0"
      [contentRight]="i % 2 === 1 && i > 0"
      *ngFor="let program of componentObject.subComponents; let i = index"
    ></app-trip-program>
  </div>
</section>
