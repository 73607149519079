import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-head-simple',
  templateUrl: './page-head-simple.component.html',
  styleUrls: ['./page-head-simple.component.scss'],
})
export class PageHeadSimpleComponent {
  @Input() imageUrl: string;
  @Input() thematicIconKey: string;
  @Input() title: string;
}
