import { Component } from '@angular/core';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-trip-day',
  templateUrl: './trip-day.component.html',
  styleUrls: ['./trip-day.component.scss'],
})
export class TripDayComponent extends BaseComponent {
  constructor() {
    super();
  }
}
