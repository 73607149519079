import { Injectable } from '@angular/core';
import { environment } from '../../../src/environments/environment';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class TrendencyGoogleMapService {
  public googleAPIisReady = new BehaviorSubject<boolean>(false);

  public setGoogleAPItoReady() {
    this.googleAPIisReady.next(true);
  }

  initGoogle() {
    let googleMapsScript = document.getElementById('googleMapsScript');

    if (googleMapsScript == null) {
      googleMapsScript = document.createElement('script');
      googleMapsScript.id = 'googleMapsScript';
      window['initMap'] = () => this.setGoogleAPItoReady();
      googleMapsScript.setAttribute(
        'src',
        'https://maps.google.com/maps/api/js?key=' + environment.googleApiKey + '&libraries=places&callback=initMap'
      );
      googleMapsScript.setAttribute('async', '');
      googleMapsScript.setAttribute('defer', '');
      document.head.appendChild(googleMapsScript);
    }
  }
}
