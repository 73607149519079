import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { BaseComponent } from '../base.component';
import { isPlatformBrowser } from '@angular/common';

const SPOTIFY = 'spotify';
const SOUNDCLOUD = 'soundcloud';
type Sources = typeof SPOTIFY | typeof SOUNDCLOUD;

@Component({
  selector: 'app-podcast',
  templateUrl: './podcast.component.html',
  styleUrls: ['./podcast.component.scss'],
})
export class PodcastComponent extends BaseComponent implements OnInit {
  isEmbed: Sources;
  image: object;
  rawLink: string;
  link: string;

  constructor(@Inject(PLATFORM_ID) private readonly platformId: any) {
    super();
  }

  ngOnInit(): void {
    this.rawLink = this.componentObject?.podcastLink;

    if (this.rawLink?.includes('iframe')) {
      if (this.rawLink?.includes(SPOTIFY)) {
        this.isEmbed = SPOTIFY;
      }

      if (this.rawLink?.includes(SOUNDCLOUD)) {
        this.isEmbed = SOUNDCLOUD;
      }

      this.link = this.getSrcValueFromIframe();
    } else {
      this.link = this.rawLink;
    }
  }

  getSrcValueFromIframe(): string {
    if (isPlatformBrowser(this.platformId) && this.rawLink) {
      const tmp = document.createElement('div');
      tmp.innerHTML = this.rawLink;

      return tmp.querySelector('iframe').getAttribute('src');
    }
    return null;
  }
}
