import { Directive, ElementRef } from '@angular/core';
import { fromEvent } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'form'
})
export class FormSubmitDirective {

  public submit = fromEvent(this.formElement.nativeElement, 'submit').pipe(
    tap(() => {
      if (!this.formElement.nativeElement.classList.contains('submitted')) {
        this.formElement.nativeElement.classList.add('submitted');
      }
    }),
    shareReplay(1)
  );

  constructor(
    private formElement: ElementRef<HTMLFormElement>
  ) { }

}
